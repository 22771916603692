import { Footer } from "react-daisyui";
import { useTranslation } from "react-i18next";

export default function PageFooter(props) {
  const { t } = useTranslation();
  return (
    <Footer className="bg-gray-800 p-5 text-neutral-content">
      <div className="items-center grid-flow-col">
        <img
          style={{ maxWidth: 50, maxHeight: 50 }}
          size="xs"
          src={props.orgIcon}
        />
        <span className="align-middle">
          {props.orgName}
          <br />
          Copyright © 2023
        </span>
      </div>
      <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
        <a className="link link-hover" href="/MentionsLegales">
          {t("legal notice")}
        </a>
      </div>
    </Footer>
  );
}
