import { LoadingOutlined } from "@ant-design/icons";
import {
  Badge,
  Col,
  Empty,
  Form,
  List,
  Result,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Card as AntdCard,
} from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Collapse,
  Divider,
  Input,
  InputGroup,
  Mask,
  Table,
} from "react-daisyui";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest } from "../auth";
import { Avatar as AntdAvatar } from "antd";
import message from "../components/Alert";
import Typewriter from "typewriter-effect";

function getBadge(status) {
  switch (status) {
    case "ready":
      return <Badge status="success" text={t("HIBP Ready")} />;
    case "queued":
      return <Badge status="processing" text={t("HIBP Queued")} />;
    default:
      return <Badge status="error" text={t("HIBP Error")} />;
  }
}

function getIconsOverview(results, max) {
  if (results.length == 0) {
    return <AntdAvatar size={50}>0</AntdAvatar>;
  }
  return (
    <AntdAvatar.Group
      maxCount={max}
      size={50}
      maxStyle={{
        color: "#f56a00",
        backgroundColor: "#fde3cf",
      }}
      maxPopoverTrigger="none"
    >
      {results.map((item) => (
        <AntdAvatar
          style={{
            backgroundColor: "hsl(var(--sc))",
            border: "solid hsl(var(--s))",
            borderWidth: "4px",
            borderRadius: "100%",
          }}
          src={item.LogoPath}
        />
      ))}
    </AntdAvatar.Group>
  );
}

export function Recommendations(props) {
  const { t } = useTranslation();

  return <img src="/images/recommendations.png" />;
}

export function HIBPSummary(props) {
  const data = props.data;
  const { t } = useTranslation();

  function getResultColor(breached) {
    if (breached) return "linear-gradient(to left, #ed213a, #93291e)";
    return "linear-gradient(to left, #44a08d, #093637)";
  }

  if (data !== undefined) {
    return (
      <Card style={{ background: getResultColor(data?.result?.length > 0) }}>
        <Card.Body>
          <Card.Title>
            <Row className="w-full">
              <Col span={20}>
                <div className="flex items-center space-x-32">
                  <div>
                    <div>
                      <Typography.Text
                        style={{ color: "white" }}
                        strong
                        className="text-xl font-medium"
                      >
                        {data.email}
                      </Typography.Text>
                    </div>
                    <div>
                      <Tooltip title={data.time}>
                        <Typography.Text
                          style={{ color: "white", opacity: "80%" }}
                        >
                          {moment(data.time).fromNow()}
                        </Typography.Text>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="text-center">
                    <Typography.Text style={{ color: "white" }}>
                      {t("HIBP Breached n times", {
                        count: data.result.length,
                      })}{" "}
                      {props.full && t("HIBP Breached At Dates")}
                    </Typography.Text>
                  </div>
                </div>
              </Col>
              <Col span={4} className="text-center">
                {getIconsOverview(data.result, 3)}
              </Col>
            </Row>
          </Card.Title>
        </Card.Body>
      </Card>
    );
  }
  return <></>;
}

export function HIBPDescription(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const result = await sendGetRequest(`/hibp/${props.id}`);
        setData(result.data.data);
        if (result.data.data.status != "ready") {
          setTimeout(loadData, 5000);
        } else {
          console.log("DATA IS HERE :", result.data.data.result[0]);
        }
      } catch (error) {}
    }
    setLoading(true);
    loadData();
    setLoading(false);
  }, []);

  if (data && data.status != "ready") {
    return (
      <Spin spinning={loading}>
        {data && (
          <Card>
            <Card.Body>
              <Card.Title>
                <Row className="w-full">
                  <Col span={4} className="text-left">
                    {getBadge(data.status)}
                  </Col>
                  <Col span={16} className="text-center">
                    <Typography.Text strong className="text-xl font-medium">
                      {data.email}
                    </Typography.Text>
                  </Col>
                  <Col span={4} className="text-right">
                    <Tooltip title={data.time}>
                      {moment(data.time).calendar()}
                    </Tooltip>
                  </Col>
                </Row>
              </Card.Title>
              <div className="text-center p-32">
                <Typography.Title>
                  <Typewriter
                    options={{
                      delay: 100,
                      deleteSpeed: 20,
                      strings: [
                        t("HIBP Loading Str 1"),
                        t("HIBP Loading Str 2"),
                        t("HIBP Loading Str 3"),
                        t("HIBP Loading Str 4"),
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </Typography.Title>
                <Spin spinning indicator={<LoadingOutlined />} size="large" />
              </div>
            </Card.Body>
          </Card>
        )}
      </Spin>
    );
  }
  return (
    <Spin spinning={loading}>
      {data && (
        <Card>
          <HIBPSummary data={data} full />
          <Card.Body>
            {data && data.result.length == 0 && (
              <Result status="success" title={t("HIBP No Breaches")} />
            )}
            <List
              grid={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
                gutter: [16, 16],
              }}
              itemLayout="horizontal"
              loading={loading}
              dataSource={data.result}
              renderItem={(item) => (
                <AntdCard style={{ margin: "0px 16px" }}>
                  <div className="flex items-center space-x-8">
                    <Avatar
                      style={{
                        backgroundColor: "hsl(var(--sc))",
                        border: "solid hsl(var(--s))",
                        borderWidth: "4px",
                        borderRadius: "100%",
                      }}
                      size={50}
                      shape="circle"
                      src={item.LogoPath}
                    />
                    <div>
                      <div>
                        <Typography.Title level={3}>
                          {item.Title}
                        </Typography.Title>
                      </div>
                      <div>
                        <Typography.Title type="secondary" level={4}>
                          {item?.BreachDate?.split("-").reverse().join("/")}
                        </Typography.Title>
                      </div>
                      {/* <Typography.Text strong>Compromised data: </Typography.Text><Typography.Text>{item.DataClasses.map((cls) => (<Tag>{cls}</Tag>))}</Typography.Text> */}
                    </div>
                  </div>
                </AntdCard>
              )}
            />
            <Divider />
            <Recommendations />
          </Card.Body>
        </Card>
      )}
    </Spin>
  );
}

export function HIBPHistory(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function hasPending(data) {
    if (!data) return true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].status == "queued") return true;
    }
    return false;
  }

  useEffect(() => {
    async function getHistory() {
      try {
        const response = await sendGetRequest("/hibp");
        setData(response.data.data);
        const pending = hasPending(response.data.data);
        if (pending) setTimeout(getHistory, 5000);
      } catch (error) {}
    }
    setLoading(true);
    getHistory();
    setLoading(false);
  }, [props]);
  return (
    <>
      {data && data[0] && data[0].status == "ready" && (
        <HIBPSummary data={data[0]} />
      )}
      <br />
      <Typography.Title level={3}>{t("HIBP History")} :</Typography.Title>
      <Spin spinning={loading}>
        <Table className="w-full" compact>
          <Table.Head>
            <Spin indicator={<LoadingOutlined />} spinning={hasPending(data)}>
              <span />
            </Spin>
            <span>Status</span>
            <span>Email</span>
            <span>Time</span>
          </Table.Head>
          <Table.Body>
            {data && data.length != 0 ? (
              data.map((item, index) => (
                <Table.Row
                  hover
                  onClick={() => {
                    navigate(`/breaches/${item.id}`);
                  }}
                  className="cursor-pointer"
                >
                  <span>{index + 1}</span>
                  {getBadge(item.status)}
                  <Typography.Text strong className="text-xl font-medium">
                    {item.email}
                  </Typography.Text>
                  <Tooltip title={item.time}>
                    {moment(item.time).calendar()}
                  </Tooltip>
                </Table.Row>
              ))
            ) : (
              <Empty description={t("No Data")} />
            )}
          </Table.Body>
        </Table>
      </Spin>
    </>
  );
}

export function HIBPSearch(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  async function submit(data) {
    if (!data.email) return;
    try {
      const response = await sendPostRequest("/hibp", { email: data.email });
      message.success(t("HIBP Submit Success"));
      form.resetFields(["email"]);
      props.refresh();
      navigate(`/breaches/${response.data.data.id}`);
    } catch (error) {
      message.error(t("HIBP Submit Error"));
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Typography.Title>{t("HIBP Title")}</Typography.Title>
      </Col>
      <Col span={24}>
        <Form onFinish={submit} form={form}>
          <InputGroup>
            <Form.Item
              className="w-full"
              name="email"
              rules={[{ type: "email", required: true }]}
            >
              <Input
                className="w-full"
                bordered
                placeholder={t("HIBP Email")}
              />
            </Form.Item>
            <Form.Item>
              <Button type="submit">{t("HIBP Search")}</Button>
            </Form.Item>
          </InputGroup>
        </Form>
      </Col>
    </Row>
  );
}

export default function HIBPPage(props) {
  const [_refresh, setRefresh] = useState(0);
  const { queryId } = useParams();

  function refresh() {
    setRefresh((old) => old + 1);
  }

  if (!queryId) {
    return (
      <>
        <HIBPSearch refresh={refresh} />
        <Divider />
        <HIBPHistory _refresh={_refresh} />
      </>
    );
  } else {
    return (
      <>
        <HIBPDescription id={queryId} />
      </>
    );
  }
}
