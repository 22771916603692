import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import BackOfficeQuiz from "./BackOfficeQuiz";
import BackOfficeQuizMode from "./BackOfficeQuizMode";

export default function BackOfficePhishingMenu({}) {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tabs.TabPane tab={"All"} key="0">
        <BackOfficeQuiz type="phishing" />
      </Tabs.TabPane>
      <Tabs.TabPane tab={"Jeu Phishing 01"} key="1">
        <BackOfficeQuizMode quiz_id={3} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={"Jeu Phishing 02"} key="2">
        <BackOfficeQuizMode quiz_id={5} />
      </Tabs.TabPane>
    </Tabs>
  );
}
