import {
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeFilled,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { PlusCircleIcon } from "@heroicons/react/solid";
import {
  Button,
  Card,
  Col,
  List,
  Popconfirm,
  Rate,
  Result,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import { Button as DaisyButton } from "react-daisyui";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendDeleteRequest, sendGetRequest, sendPostRequest } from "../auth";
import GameComponent from "../components/GameComponent";
import { AppContext } from "../context";
import message from "../components/Alert";
import { useTranslation } from "react-i18next";

export function GamePlayPage(props) {
  const { quizId } = useParams();
  return <GameComponent id={quizId} />;
}

export default function GamePage(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [_refresh, setRefresh] = useState(true);
  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function refresh() {
    setRefresh((old) => old + 1);
  }

  function getColorFromScore(score) {
    if (score === undefined) return "inherit";
    if (score >= 70) return "#52c41a";
    if (score >= 50) return "#faad14";
    return "#ff4d4f";
  }

  async function deleteQuiz(id) {
    setLoading(true);
    try {
      const response = await sendDeleteRequest(`/quiz/quiz/${id}`);
      refresh();
      message.success(t("notif.PhisingGameDeleted"));
    } catch (error) {
      message.error(t("notif.ErrorDeletingPhisingGame"));
    }
    setLoading(false);
  }

  async function createNewQuiz() {
    setLoading(true);
    const defaultData = {
      name: "New Phishing Game",
    };
    try {
      const response = await sendPostRequest("/quiz/game", defaultData);
      navigate(`/phishingCreate/${response.data.data.id}`);
    } catch (error) {
      message.error(t("notif.ErrorCreatingPhisingGame"));
    }
    setLoading(false);
  }

  useEffect(() => {
    async function listQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest("/quiz/game");
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    listQuiz();
  }, [_refresh]);

  if (!loading && !data) {
    return (
      <Result status="info" title="No phishing game for now, check back soon" />
    );
  } else {
    return (
      <>
        <div className="flex flex-row place-content-end">
          {userProfile?.role === "staff" && (
            <Row gutter={[16, 16]}>
              <DaisyButton
                animation={true}
                size="sm"
                color="primary"
                className="text-right"
                pill={false}
                onClick={createNewQuiz}
              >
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Create New Phishing Game
              </DaisyButton>
            </Row>
          )}
        </div>
        <br />
        <List
          dataSource={data}
          loading={loading}
          renderItem={(item) =>
            userProfile.role === "staff"
              ? staffCard(item, t)
              : userCard(item, t)
          }
        />
      </>
    );
  }

  function userCard(item, t) {
    return (
      <List.Item>
        <Card
          style={{ width: "100%" }}
          hoverable
          onClick={() => {
            if (userProfile?.role !== "staff") navigate(`/phishing/${item.id}`);
          }}
        >
          <Row align="middle" gutter={16}>
            <Col span={10}>
              <Typography.Title level={3} strong>
                {item.name}
              </Typography.Title>
            </Col>
            <Col span={3}>
              <Statistic
                style={{ textAlign: "center" }}
                title={t("Difficulty")}
                valueRender={() => (
                  <Rate value={item.difficulty} count={3} allowHalf disabled />
                )}
              />
            </Col>
            <Col span={3}>
              <Statistic
                style={{ textAlign: "center" }}
                title={t("Questions")}
                value={item.questions}
              />
            </Col>
            <Col span={4}>
              <Statistic
                style={{ textAlign: "center" }}
                valueStyle={{ color: getColorFromScore(item.score) }}
                title={t("Score")}
                value={
                  item.score !== undefined ? item.score : t("Quiz.Nottaken")
                }
                suffix={item.score !== undefined ? "/ 100" : ""}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" block icon={<PlayCircleOutlined />}>
                {t("QuizStart")}
              </Button>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  }

  function staffCard(item, t) {
    return (
      <List.Item>
        <Card
          style={{ width: "100%" }}
          title={
            <Typography.Title level={3} strong>
              {item.name}
            </Typography.Title>
          }
          extra={
            <Space size={20}>
              <Typography.Text type="secondary">Difficulty :</Typography.Text>
              <Rate value={item.difficulty} count={3} allowHalf disabled />
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(`/phishingCreate/${item.id}`);
                }}
              >
                Edit
              </Button>
              <Button
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(`/phishing/${item.id}`);
                }}
              >
                View
              </Button>
              <Popconfirm
                title="Delete this Phishing Game ?"
                onConfirm={() => {
                  deleteQuiz(item.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Row align="middle">
            <Col span={12}>
              <Statistic
                style={{ textAlign: "center" }}
                title="Questions"
                value={item.questions}
              />
            </Col>
            <Col span={12}>
              <Statistic
                style={{ textAlign: "center" }}
                valueStyle={{ color: getColorFromScore(item.score) }}
                title="Score"
                value={
                  item.score !== undefined ? item.score : t("Quiz.Nottaken")
                }
                suffix={item.score !== undefined ? "/ 100" : ""}
              />
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  }
}
