import LoginForm from "../components/LoginForm";
import { sendPostRequest } from "../auth";
import "antd/lib/message/style/css";
import message from "../components/Alert";
import { setToken } from "../auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LoginPage(props) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  async function handleLogin(data) {
    try {
      const response = await sendPostRequest("/auth/login", data);
      if (response.data.access_token !== undefined) {
        setToken(response.data.access_token);
        props.setToken(response.data.access_token);
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        message.warning("Bad Username or password");
      } else {
        message.error("A server error occured !");
      }
    }
  }

  return <LoginForm formSubmit={handleLogin} />;
}
