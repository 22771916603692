import { FilterOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button as AntdButton,
  Avatar,
  Col,
  Form,
  Pagination,
  Row,
  Tooltip,
  Radio,
  Tag,
} from "antd";
import Search from "antd/lib/transfer/search";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Dropdown, Table } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { getColorFromString } from "../pages/DashBoard";

const filter_functions = {
  sso: (item) => item?.user_infos?.sso,
  native: (item) => !item?.user_infos?.sso,
};

function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  let result = array;
  if (array?.length > page_size)
    result = array.slice(
      (page_number - 1) * page_size,
      page_number * page_size
    );
  return result;
}

function applyFilters(data, filters) {
  let filtered = data;

  filters.forEach((filter) => {
    filtered = filtered.filter(filter_functions[filter]);
  });
  return filtered;
}

function processData(data, search, page, filters) {
  if (data) {
    return applyFilters(
      data?.filter((element) => {
        if (search === undefined) return true;
        else
          return `${element.user_infos.firstname} ${element.user_infos.lastname} ${element.user_infos.email} ${element.quiz_infos.name}`
            .toLowerCase()
            .match(search.toLowerCase());
      }),
      filters
    );
  }
}

export default function BackOfficeQuizStats({ data, refresh }) {
  const [form] = Form.useForm();
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();

  const [sortColumn, setSortColumn] = useState(null);
  const [sortCycle, setSortCycle] = useState(0); // 0 for default, 1 for ascending, 2 for descending

  function handleSort(column) {
    if (sortColumn === column) {
      setSortCycle((sortCycle + 1) % 3);
    } else {
      setSortColumn(column);
      setSortCycle(1); // default order is ascending when a new column is selected
    }
  }

  useEffect(() => {
    console.log(data);
    let processedData = processData(data, search, currentPage, filters);

    if (sortColumn) {
      processedData.sort((a, b) => {
        let aVal, bVal;
        switch (sortColumn) {
          case "username":
            aVal = (
              a.user_infos.firstname +
              " " +
              a.user_infos.lastname
            ).toLowerCase();
            bVal = (
              b.user_infos.firstname +
              " " +
              b.user_infos.lastname
            ).toLowerCase();
            break;
          case "title":
            aVal = a.quiz_infos.name.toLowerCase();
            bVal = b.quiz_infos.name.toLowerCase();
            break;
          case "created_at":
            aVal = new Date(a.created_at);
            bVal = new Date(b.created_at);
            break;
          case "score":
            aVal = a.score;
            bVal = b.score;
            break;
          default:
            aVal = a;
            bVal = b;
        }

        if (sortCycle === 1) {
          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
        } else if (sortCycle === 2) {
          if (aVal < bVal) {
            return 1;
          }
          if (aVal > bVal) {
            return -1;
          }
        }
        return 0;
      });
    }

    if (processedData) {
      setFilteredData(
        processedData.map((element) => {
          return (
            <TableRow
              {...element}
              setSelected={setSelected}
              refresh={refresh}
            />
          );
        })
      );
    }
  }, [data, search, filters, sortColumn, sortCycle]);

  function searchSubmit() {
    const values = form.getFieldsValue();
    setSearch(values.username);
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    let headers = ["Utilisateur,Email, Titre,Score,Dernier test"];
    let usersCsv = [];
    data.forEach((element) => {
      const { user_infos, created_at, quiz_infos, score } = element;
      usersCsv.push(
        [
          user_infos.firstname + " " + user_infos.lastname,
          user_infos.email,
          quiz_infos.name,
          created_at?.split(",")[1],
          score,
        ].join(",")
      );
    });

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "quiz.csv",
      fileType: "text/csv",
    });
  };

  const onPaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Row className="flex justify-between">
        <Col>
          <Row>
            <Col>
              <Form
                onFieldsChange={searchSubmit}
                onFinish={searchSubmit}
                form={form}
              >
                <Form.Item className="w-full" name="username">
                  <Search
                    name="username"
                    placeholder={t("backoffice.table.SearchText")}
                    enterButton
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <TableFilter filters={filters} setFilters={setFilters} />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="right-0 flex justify-end">
            <Button key="export-csv" variant="outline" onClick={exportToCsv}>
              {t("Users.download.csv")}
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <div className="w-full">
        <Table className="w-full" zebra compact>
          <Table.Head>
            <span
              onClick={() => handleSort("username")}
              style={{
                fontWeight: sortColumn === "username" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.Username")}&nbsp;
              {sortColumn === "username" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("title")}
              style={{
                fontWeight: sortColumn === "title" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("BackOffice.table.quiz.Name")}&nbsp;
              {sortColumn === "title" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("score")}
              style={{
                fontWeight: sortColumn === "score" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("BackOffice.table.quiz.Score")}&nbsp;
              {sortColumn === "score" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("created_at")}
              style={{
                fontWeight: sortColumn === "created_at" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("BackOffice.table.quiz.LastPass")}&nbsp;
              {sortColumn === "created_at" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
          </Table.Head>
          <Table.Body>
            {paginate(filteredData, pageSize, currentPage)}
          </Table.Body>
        </Table>
        <Col className="flex justify-center" style={{ marginTop: "1.5rem" }}>
          <div>
            <Pagination
              style={{ textAlign: "center", verticalAlign: "center" }}
              current={currentPage}
              pageSize={pageSize}
              onChange={onPaginationChange}
              total={filteredData?.length}
            />
          </div>
        </Col>
      </div>
    </>
  );
}

function TableFilter({ filters, setFilters }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const filter_options = [
    { value: "all", label: t("backoffice.filter.all") },
    { value: "sso", label: t("backoffice.filter.sso") },
    { value: "native", label: t("backoffice.filter.native") },
  ];

  function get_filter_name(value) {
    let entry = filter_options.find((entry) => entry.value == value);
    return entry.label;
  }

  function disable_filter(value) {
    form.setFieldValue("filter", "all");
    setFilters([]);
  }

  function get_filter_tags() {
    let result = filters.map((entry) => (
      <Tag
        key={entry}
        closable
        onClose={() => {
          disable_filter(entry);
        }}
      >
        {get_filter_name(entry)}
      </Tag>
    ));
    return result;
  }

  return (
    <div className="flex items-center mx-2 space-x-2">
      <Dropdown horizontal="right" className="bg-primary-content">
        <AntdButton shape="circle" icon={<FilterOutlined />} />
        <Dropdown.Menu className="w-64 p-2 m-1 border-2 border-solid shadow card card-compact">
          <Card.Body>
            <Card.Title>{t("backoffice.filter.filter")} :</Card.Title>
            <Form
              onValuesChange={() => {
                const selectedValue = form.getFieldValue("filter");
                if (selectedValue === "all") {
                  setFilters([]);
                } else {
                  setFilters([selectedValue]);
                }
              }}
              form={form}
            >
              <Form.Item name="filter">
                <Radio.Group options={filter_options} />
              </Form.Item>
            </Form>
          </Card.Body>
        </Dropdown.Menu>
      </Dropdown>
      <div>{get_filter_tags()}</div>
    </div>
  );
}

function format_date(datetime, t) {
  if (datetime === null || datetime === undefined) {
    return t("backoffice.table.NoDate");
  }
  return <Tooltip title={datetime}>{moment(datetime).fromNow()}</Tooltip>;
}

function format_number(value) {
  if (value === 0) {
    return <span className="text-base text-gray-500">{value}</span>;
  }
  return <span className="text-base font-bold">{value}</span>;
}

function TableRow({ user_infos, created_at, score, quiz_infos }) {
  const { t } = useTranslation();

  return (
    <Table.Row>
      <div className="flex items-center space-x-2 truncate">
        <Avatar
          className="mask mask-squircle"
          shape="square"
          style={{
            backgroundColor: getColorFromString(
              `${user_infos?.firstname}${user_infos?.lastname}`
            ),
          }}
        >
          {`${user_infos?.firstname[0]}${user_infos?.lastname[0]}`}
        </Avatar>
        <div>
          <div className="font-bold">{`${user_infos?.firstname} ${user_infos?.lastname}`}</div>
          <div className="text-xs text-gray-500">{user_infos?.email}</div>
          {/* <div><span className="text-sm opacity-50">{user?.company}</span> {user?.sso && <Badge key={user.id} count="SSO" size="small" style={{ backgroundColor: "#1890ff" }} />}</div> */}
        </div>
      </div>
      <div>{quiz_infos?.name}</div>
      <div style={getScoreColor(score)}>{format_number(score)}</div>
      <div>{format_date(created_at, t)}</div>
    </Table.Row>
  );
}

function getScoreColor(score) {
  let scoreStyle = { color: "hsl(var(--su))" };
  if (score < 70) scoreStyle = { color: "#faad14" };
  if (score < 50) scoreStyle = { color: "hsl(var(--er))" };
  return scoreStyle;
}
