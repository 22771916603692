const EN_LANG = {
  salam: "test",
  Dashboard: "Dashboard",
  BackOffice: "Administration",
  Organizations: "Organizations",
  Invites: "Invites",
  Scans: "Scans",
  Assets: "Assets",
  "Awareness Training": "Awareness Training",
  Quiz: "Quiz",
  "User Roles": "User Roles",
  "Scan History": "Scan History",
  "Training Stats": "Training presentations",
  "One Month": "One Month",
  Users: "Users",
  "Users.download.csv": "Export CSV",
  "HIBP Search": "Check Breaches",
  "HIBP Email": "Enter your email",
  "HIBP Title": "Check if your email is in a data breach",
  "No Data": "No Data",
  "HIBP History": "Queries History",
  "HIBP Submit Success": "Successfully submitted a breach query",
  "HIBP Ready": "Ready",
  "HIBP Queued": "Queued",
  HIBP: "Email Breaches",
  "HIBP Loading Str 4": "Preparing results",
  "HIBP Loading Str 1": "Searching leaked databases",
  "HIBP Loading Str 3": "Scanning public pastes",
  "HIBP Loading Str 2": "Looking for sensitive information",
  "HIBP No Breaches": "Your email figures in no breaches on our database",
  "HIBP Breached n times": "Your email was found in {{count}} breaches",
  PhishingGame: "Phishing Game",
  finished: "finished",
  failed: "failed",
  "Invite Users": "Invite Users",
  "Create Static Invite": "Create Static Invite",
  User: "User",
  Status: "Status",
  Actions: "Actions",
  Organization: "Organization",
  OriginalInvitId: "Original Invite",
  UsageCount: "Usage Count",
  "Static Invite": "Static Invite",
  "Send Invitation": "Send Invitation",
  pending: "pending",
  accepted: "accepted",
  disabled: "disabled",
  "Add New Asset": "Add New Asset",
  "Start New Scan": "Start New Scan",
  Phish: "Phish",
  Genuine: "Genuine",
  "Your Answered": "Your Answered",
  Correct: "Correct",
  Wrong: "Wrong",
  Score: "Score",
  "Accept All": "Accept All",
  "Bulk actions": "Bulk Actions",
  enabled: "enabled",
  "Create Invitation": "Create Invitation",
  expired: "expired",
  "legal notice": "Legal Notice",
  "Pass the Quizzes": "Pass the Quizzes",
  "Test your knowledge ?": "Test your knowledge",
  QuizStart: "Start",
  Questions: "Questions",
  Difficulty: "Difficulty",
  "Quiz.Nottaken": "N/A",
  "Quiz Stats": "Quiz Score Distribution",
  "Quiz.SelectCount": "Select {{count}} option(s)",
  "Quiz.YourScoreIs": "Your Score Is",
  "Quiz.SelectAllValid": "Select all valid options",
  "Invitation.WillExpireAt": "The invitation will expire",
  "Invitation.YouInvitedBy": "You have been invited by",
  "Invitation.ToJoin":
    "to join the digital risk awareness platform CYBER-SERVICES",
  "Invitation.CreateAccount": "Create Account",
  "HIBP Breached At Dates": "at following times",
  "Quiz Answered": "Quiz Completion",
  QuizNotAnswered: "Fail",
  QuizAnswered: "Success",
  "Breach History": "Email Breach Query History",
  "Game Stats": "Phishing Game Score Distribution",
  "passPol-length": "at least 8 characters long.",
  "passPol-uppercase": "uppercase letters.",
  "passPol-lowercase": "lowercase letters.",
  "passPol-digits": "digits.",
  "passPol-special": "special characters.",
  invite_actions_delete: "Delete invitation",
  invite_actions_accept: "Enable account",
  invite_actions_copy: "Copy invitation link",
  Reference: "Reference",
  "invitation.waitForEnable":
    "Your account is not yet active. You will receive very soon an email, as soon as your access is validated by our teams.",
  logout: "logout",
  "quiz.YourResultIs": "Your result is: ",
  "notif.copiedInviteLink": "Copied Invite Link!",
  "notif.QuizSubmitError": "Error submitting quiz result !",
  "notif.QuizResults": "Quiz Results !",
  "notif.ErrorSavingChanges": "Error saving changes",
  "notif.ErrorDeletingQuestion": "Error deleting Question !",
  "notif.ErrorSavingQuestion": "Error Saving Question !",
  "notif.ErrorSavingQuiz": "Error Saving Quiz !",
  "notif.ErrorCreatingOption": "Cannot Create New Option",
  "notif.ErrorCreatingQuestion": "Error Creating New Question !",
  "notif.SuccessCreatingQuestion": "Created New Question !",
  "notif.DeletedOption": "Deleted Option !",
  "notif.ErrorDeletingOption": "Error Deleting Option !",
  "notif.PhisingGameDeleted": "Phishing game Deleted",
  "notif.ErrorDeletingPhisingGame": "Can't delete phishing game",
  "notif.ErrorCreatingPhisingGame": "Can't create new phishing game",
  "notif.ErrorGettingQuizResults": "Can't get quiz results",
  "notif.SuccessCreatingAccount": "Your account was created !",
  "notif.ErrorCreatingAccount": "An error occured",
  "notif.SuccessCreatingInvite": "Invite Created Successfully",
  "notif.ErrorCreatingInvite": "Error Creating Invite",
  "notif.ErrorGettingOrganizations": "Error Getting Organizations",
  "notif.EnabledUserAccount": "User account enabled !",
  "notif.InviteDeleted": "Invite Deleted !",
  "notif.ErrorDeletingInvite": "Error occured while deleting invite",
  "notif.StaticInviteCreated": "Static Invite Created",
  "notif.AnErrorOccured": "An error occured",
  "notif.ResetPasswordSent":
    "You'll receive an email shortly to reset your password",
  "notif.ResetPasswordChanged": "Your password was changed successfully",
  "error.UserEmailAlreadyExists": "This email already exists",
  "error.Unknown": "An error occured",
  "error.InvalidInvitation": "This invitation is not valid",
  "error.SlideNotFound": "This presentation was not found",
  "error.ReturnToTraining": "Return to training page",
  "backoffice.table.Username": "User",
  "backoffice.table.Company": "Company",
  "backoffice.table.LastVisit": "Last Login",
  "backoffice.table.LoginCount": "Login Count",
  "backoffice.table.CreatedAt": "Created At",
  "backoffice.table.InvitationId": "Invitation",
  "backoffice.table.NoInvitationId": "No Invitation",
  "backoffice.table.QuizCount": "Quiz Count",
  "backoffice.table.MailScans": "Breach Scan Count",
  "backoffice.table.TrainingCount": "Training Presentations Viewed",
  "backoffice.table.PhishingCount": "Quiz Count",
  "backoffice.table.NoDate": "Never",
  "backoffice.modal.Read": "Read",
  "backoffice.modal.Presentations": "Presentations :",
  "backoffice.modal.Quiz": "Quizzes :",
  "backoffice.modal.PhishingGames": "Phishing Games :",
  "backoffice.modal.EmailScans": "Email Scans :",
  "backoffice.modal.EmailScanCount": "Number of scanned emails :",
  "backoffice.modal.QuizScore": "Score ",
  "backoffice.table.MoreDetails": "More Details",
  "backoffice.filter.filter": "filter",
  "backoffice.filter.all": "All Users",
  "backoffice.filter.sso": "SSO Users",
  "backoffice.filter.native": "Invited Users",
  "BackOffice.table.deleteUser": "Delete User",
  "BackOffice.table.enableUser": "Enable User",
  "BackOffice.table.disableUser": "Disable User",
  "BackOffice.table.moreDetails": "More usage details",
  "BackOffice.table.confirmDeleteUser":
    "Are you sure you want to delete this user ?",
  "BackOffice.table.alert.enabled": "User enabled",
  "BackOffice.table.alert.disabled": "User disabled",
  "BackOffice.table.alert.deleted": "User deleted",
  "BackOffice.table.quiz.Name": "Title",
  "BackOffice.table.quiz.Score": "Score",
  "BackOffice.table.quiz.LastPass": "Last time",
  cancel: "Cancel",
  confirm: "Confirm",
  "BackOffice.stats.scanCount": "Number of email scans performed",
  "BackOffice.stats.scanCountMonth":
    "Number of scans performed in the last 30 days",
  "BackOffice.tabs.users": "Users",
  "BackOffice.tabs.quiz": "Quiz",
  "BackOffice.tabs.phishing": "Phishing",
  "BackOffice.tabs.charts": "Usage",
  "BackOffice.stats.emailLeakPercent":
    "% of scans with at least one leak found",
  "BackOffice.stats.presentations": "Sensitization",
  "BackOffice.stats.PresentationViews": "User who followed the awareness",
  "BackOffice.stats.quiz": "Quiz",
  "BackOffice.stats.quizViews": "User who took the quiz",
  "BackOffice.stats.quizAverage": "Average score",
  "BackOffice.stats.phishing": "Phishing simulations Tracking",
  "BackOffice.stats.phishingViews": "User who took the quiz",
  "BackOffice.stats.phishingAverage": "Average score",
  "BackOffice.stats.emailScan": "Scans email Tracking",
  "BackOffice.stats.scanMailCount": "Total number of mails scanned",
  "BackOffice.stats.quizAnswerChartTitle": "Quiz Pass Status",
  UserSSO: "User SSO",
  "training.guides": "Guides",
  "training.level1": "Beginner",
  "training.level2": "Intermediate",
  "training.level3": "Advanced",
};

export default EN_LANG;
