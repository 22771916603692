import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import BackOfficeQuiz from "./BackOfficeQuiz";
import BackOfficeQuizMode from "./BackOfficeQuizMode";

export default function BackOfficeQuizMenu({}) {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tabs.TabPane tab={"All"} key="0">
        <BackOfficeQuiz type="game" />
      </Tabs.TabPane>
      <Tabs.TabPane tab={"Généralités et bonnes pratiques"} key="1">
        <BackOfficeQuizMode quiz_id={1} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={"Exemples d'attaques cyber"} key="2">
        <BackOfficeQuizMode quiz_id={2} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={"Se prémunir contre le Phishing"} key="3">
        <BackOfficeQuizMode quiz_id={4} />
      </Tabs.TabPane>
    </Tabs>
  );
}
