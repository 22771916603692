import React, { useState, useEffect } from 'react';
import { Modal, Button, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const WelcomePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const popupHidden = localStorage.getItem('welcomePopupHidden');
    if (!popupHidden) {
      setIsVisible(true);
    }
  }, []);

  const handleOk = () => {
    setIsVisible(false);
  };

  const handleNeverShowAgain = () => {
    localStorage.setItem('welcomePopupHidden', 'true');
    setIsVisible(false);
  };

  return (
    <Modal
      title="Bienvenue sur la plateforme de sensibilisation à la cybersécurité CGPA !"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={[
        <Button key="never-show" onClick={handleNeverShowAgain}>
          Ne plus afficher
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
    >
      <Typography>
        <Paragraph>
          CGPA est fière de proposer à ses sociétaires une suite complète d'outils innovants pour renforcer votre sécurité numérique. Découvrez nos modules interactifs :
        </Paragraph>
        <Paragraph>
          <ul>
            <li>
              <Text strong>Sensibilisation avec Quiz et Groupes de Niveau</Text> : Progressez à votre rythme et testez vos connaissances grâce à des quiz adaptés à chaque niveau.
            </li>
            <li>
              <Text strong>Jeux de Sensibilisation au Phishing</Text> : Apprenez à repérer les tentatives de phishing de manière ludique et engageante.
            </li>
            <li>
              <Text strong>Recherche de Fuite de Mots de Passe</Text> : Assurez-vous que vos informations restent protégées en vérifiant la sécurité des mots de passe utilisés avec vos adresses mails.
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          Rejoignez-nous dès maintenant pour une expérience éducative et dynamique, et devenez un expert en cybersécurité avec CGPA !
        </Paragraph>
      </Typography>
    </Modal>
  );
};

export default WelcomePopup;