import { Col, Typography } from "antd";
import { useEffect, useState } from "react";
import { Card, Table } from "react-daisyui";
import { useTranslation } from "react-i18next";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";

const barColors = ["#4b6bfb", "#f87272"];

export default function QuizCharts({ data }) {
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const newChartData = Object.values(data)?.map((item, index) => ({
        name: item?.data?.name,
        utilisateurs: item?.count,
        moyenne: item?.average.toFixed(2),
        fill: barColors[index % barColors.length],
      }));
      console.log(newChartData);
      setChartData(newChartData);
    }
  }, [data]);

  return (
    <Card style={{ padding: 5 }}>
      <Card.Title className="text-center">
        <Col span={24} className="text-center">
          <Typography.Title level={3}>
            {t("BackOffice.stats.quiz")}
          </Typography.Title>
        </Col>
      </Card.Title>
      <Card.Body style={{ padding: 5 }}>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="utilisateurs" orientation="left" yAxisId="1" />
            <YAxis dataKey="moyenne" orientation="right" yAxisId="2" />
            <Tooltip />
            <Legend />
            <Bar yAxisId="1" dataKey="utilisateurs" barSize={80} />
            <Line
              yAxisId="2"
              type="monotone"
              name="score moyen"
              dataKey="moyenne"
              stroke="#a35a28"
            />
          </ComposedChart>
        </ResponsiveContainer>
        {/* <Table className="w-full" zebra compact>
                    <Table.Head>
                        <span>{t('BackOffice.stats.quiz')}</span>
                        <span>{t('BackOffice.stats.quizViews')}</span>
                        <span>{t('BackOffice.stats.quizAverage')}</span>
                    </Table.Head>
                    <Table.Body>
                        {data && Object.values(data)?.map((item) => (
                            <Table.Row>
                                <span>{item?.data?.name}</span>
                                <span>{item?.count}</span>
                                <span>{item?.average}%</span>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table> */}
      </Card.Body>
    </Card>
  );
}
