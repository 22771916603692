import { Button, Col, Row, Spin, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

export default function EmbedPresentation(props) {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={loading}>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.url}`}
              onLoad={() => {
                setLoading(false);
              }}
              width="100%"
              height="600px"
              frameBorder="0"
              title="slides"
            ></iframe>
          </Spin>
        </Col>
        <Col style={{ textAlign: "center" }} span={24}>
          <Typography.Text type="secondary">
            {t("Test your knowledge ?")}
          </Typography.Text>
        </Col>
        <Col style={{ textAlign: "center" }} span={24}>
          <a href="/quiz">{t("Pass the Quizzes")}</a>
        </Col>
      </Row>
    </>
  );
}
