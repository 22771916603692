import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Select,
  Spin,
  Steps,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Card, Divider as DDivider } from "react-daisyui";
import {
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import ImageUploader from "./ImageUpload";
import message from "./Alert";
import { TrashIcon } from "@heroicons/react/solid";
import Icon from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export function QuizOption(props) {
  const [formRef] = Form.useForm();
  const [type, setType] = useState();
  const [changed, setChanged] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const { t } = useTranslation();

  function loadFields(data) {
    if (data !== undefined) {
      formRef.setFieldsValue(data);
      setType(data.type);
    }
  }

  function refresh() {
    if (props.refresh) props.refresh();
  }

  async function saveChanges(data) {
    let reqData = { ...data };
    delete reqData.id;
    try {
      const response = await sendPatchRequest(
        `/quiz/option/${data.id}`,
        reqData,
      );
      console.log(response);
      refresh();
      return true;
    } catch (error) {
      console.log(error);
      message.error(t("notif.ErrorSavingChanges"));
    }
    return false;
  }

  async function submitFields(data) {
    setLoadingSave(true);
    data = { ...props.data, ...data };
    const result = await saveChanges(data);
    setLoadingSave(false);
    if (result) {
      setChanged(false);
      if (props.onChange) props.onChange(data);
    }
  }

  async function deleteOption() {
    try {
      const response = await sendDeleteRequest(`/quiz/option/${props.data.id}`);
      refresh();
      message.success(t("notif.DeletedOption"));
    } catch (error) {
      message.error(t("notif.ErrorDeletingOption"));
    }
  }

  function changeType(value) {
    setType(value);
    formRef.setFieldValue("type", value);
    formRef.setFieldValue("content", "");
    setChanged(true);
  }

  useEffect(() => {
    loadFields(props.data);
  }, [props]);

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      form={formRef}
      onChange={() => {
        setChanged(true);
      }}
      onFinish={submitFields}
    >
      <Form.Item label="Type" name="type">
        <Select onChange={changeType}>
          <Select.Option value="text">Text</Select.Option>
          <Select.Option value="image">Image</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Content" name="content">
        {type === "text" ? (
          <Input.TextArea rows={1} />
        ) : (
          <ImageUploader count={1} single>
            Upload Image
          </ImageUploader>
        )}
      </Form.Item>
      <Form.Item label="Correct" name="correct" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Row gutter={16} justify="center">
        <Col span={12} className="text-right">
          <Button
            disabled={!changed}
            type="primary"
            htmlType="submit"
            loading={loadingSave}
          >
            Save
          </Button>
        </Col>
        <Col span={12} className="text-left">
          <Popconfirm
            title="Delete this option ?"
            onConfirm={() => {
              deleteOption();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<Icon component={TrashIcon} />}></Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form>
  );
}

export function QuizQuestion(props) {
  const [title, setTitle] = useState();
  const [loadCreate, setLoadCreate] = useState(false);
  const { t } = useTranslation();

  function loadFields(data) {
    if (data !== undefined) {
      setTitle(data.title);
    }
  }

  function refresh(id) {
    if (props.refresh) props.refresh(id);
  }

  async function deleteQuestion() {
    const question = props.data;
    try {
      const response = await sendDeleteRequest(`/quiz/question/${question.id}`);
      refresh(0);
    } catch (error) {
      message.error(t("notif.ErrorDeletingQuestion"));
    }
  }

  async function saveChange(newData) {
    const editedData = {
      ...props.data,
      ...newData,
      select_count: calcSelectCount(props.data.options),
    };
    if (props.onChange) {
      props.onChange(editedData);
    }
    const question = props.data;
    console.log("QUESTION SAVE : ", question, title);
    const data = editedData;
    delete data.id;
    delete data.options;
    try {
      const response = await sendPatchRequest(
        `/quiz/question/${question.id}`,
        data,
      );
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorSavingQuestion"));
    }
  }

  async function addOption() {
    if (!props.data) return;
    const newOption = {
      question: props.data.id,
      type: "text",
      content: "",
      correct: false,
    };
    setLoadCreate(true);
    try {
      const response = await sendPostRequest("/quiz/option", newOption);
      message.success(t("notif.SuccessCreatingQuestion"));
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorCreatingOption"));
    }
    setLoadCreate(false);
  }

  function calcSelectCount(options) {
    return options.reduce((prev, current) => prev + current.correct, 0);
  }

  useEffect(() => {
    loadFields(props.data);
  }, [props]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row gutter={16} className="w-full">
            <Col span={20}>
              <Typography.Text
                editable={{
                  onChange: (value) => {
                    setTitle(value);
                    saveChange({ title: value });
                  },
                }}
              >
                {title}
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Title level={5} className="text-center">
                Selections :
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  value={calcSelectCount(props.data.options)}
                />
              </Typography.Title>
              <Popconfirm
                title="Delete this question ?"
                onConfirm={() => {
                  deleteQuestion();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  block
                  icon={<Icon component={TrashIcon} />}
                ></Button>
              </Popconfirm>
            </Col>
          </Row>
        </Card.Title>
        <Divider />
        <Collapse>
          {props.data?.options?.map((item, index) => (
            <Collapse.Panel header={<>Option : {index + 1}</>} key={index}>
              <QuizOption refresh={props.refresh} data={item} />
            </Collapse.Panel>
          ))}
        </Collapse>
        <Button loading={loadCreate} onClick={addOption} block>
          Add Option
        </Button>
      </Card.Body>
    </Card>
  );
}

export default function QuizCreator(props) {
  const [quiz, setQuiz] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [_refresh, _setRefresh] = useState(0);
  const { t } = useTranslation();

  const [title, setTitle] = useState();

  function refresh(questionId) {
    _setRefresh((val) => val + 1);
    if (questionId !== undefined) setSelectedIndex(questionId);
  }

  function loadFields(data) {
    if (data !== undefined) {
      setTitle(data.name);
    }
  }

  async function saveChange(newData) {
    const data = { ...quiz, ...newData };
    delete data.id;
    delete data.questions;
    try {
      const response = await sendPatchRequest(`/quiz/quiz/${quiz.id}`, data);
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorSavingQuiz"));
    }
  }

  async function createQuestion() {
    console.log("CREATING QUESTION");
    const newQuestion = {
      title: "New Question",
      select_count: 1,
      quiz: quiz.id,
      type: "single",
      index: quiz.questions.length,
    };
    setLoading(true);
    try {
      const response = await sendPostRequest("/quiz/question", newQuestion);
      message.success(t("notif.SuccessCreatingQuestion"));
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorCreatingQuestion"));
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/quiz/editor/${props.id}`);
        setQuiz(response.data.data);
        setSelectedQuestion(response.data.data?.questions[selectedIndex]);
        loadFields(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    if (props.id !== undefined) loadQuiz();
  }, [props.id, _refresh]);

  return (
    <Spin spinning={loading}>
      {quiz !== undefined && (
        <Card>
          <Card.Body>
            <Card.Title>
              <Typography.Title
                className="text-center w-full"
                editable={{
                  onChange: (value) => {
                    setTitle(value);
                    saveChange({ name: value });
                  },
                }}
              >
                {title}
              </Typography.Title>
            </Card.Title>
            <Row gutter={16}>
              <Col>
                <br />
              </Col>
              <Col span={24}>
                <Steps
                  progressDot
                  current={selectedIndex}
                  onChange={(val) => {
                    console.log("PRESSING THE ADD NEW QUESTION :", val);
                    if (val >= quiz.questions.length) {
                      // createQuestion();
                    } else {
                      setSelectedIndex(val);
                      setSelectedQuestion(quiz.questions[val]);
                    }
                  }}
                >
                  {quiz.questions.map((question, index) => (
                    <Steps.Step
                      color={selectedQuestion == question ? "primary" : "ghost"}
                      title={index + 1}
                      description={
                        <Typography.Text ellipsis={{ tooltip: true }}>
                          {question.title}
                        </Typography.Text>
                      }
                    />
                  ))}
                  <Steps.Step
                    onClick={(val) => {
                      createQuestion();
                    }}
                    title="Add Question"
                  ></Steps.Step>
                </Steps>
              </Col>
              <Col>
                <br />
              </Col>
              <Col span={24}>
                {selectedQuestion ? (
                  <QuizQuestion
                    refresh={refresh}
                    data={selectedQuestion}
                    onChange={(value) => {
                      console.log("EDITED QUESTION :", value);
                    }}
                  />
                ) : (
                  <Empty description="Start by adding a new question" />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Spin>
  );
}
