import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import Middleware from "i18next-http-middleware";
import FR_LANG from "./langs/fr";
import EN_LANG from "./langs/en";

// i18n
//     .use(Backend)
//     .use(initReactI18next)
//     .use(Middleware.LanguageDetector)
//     .init({
//         backend: {
//             loadPath: "https://api.translation.nalabs.xyz/sekera_cgpa/{{lng}}.json",
//             addPath: "https://api.translation.nalabs.xyz/sekera_cgpa/{{lng}}.json",
//         },
//         load: "languageOnly",
//         debug: true,
//         fallbackLng: ["fr", "en"],
//         lng: localStorage.getItem('lang') || 'fr',
//         keySeparator: false,
//         saveMissing: true,
//         interpolation: {
//             escapeValue: false,
//         },
//     });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: { translation: EN_LANG },
      fr: { translation: FR_LANG },
    },
    debug: true,
    lng: localStorage.getItem("lang") || "fr",
    fallbackLng: ["fr", "en"],

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
