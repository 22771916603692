import { Col, Typography } from "antd";
import { Card } from "react-daisyui";
import { useTranslation } from "react-i18next";

export default function ScanMailComponent({ data }) {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 5 }}>
      <Card.Title className="text-center">
        <Col span={24} className="text-center">
          <Typography.Title level={3}>
            {t("BackOffice.stats.emailScan")}
          </Typography.Title>
        </Col>
      </Card.Title>
      <Card.Body style={{ padding: 5 }}>
        {/* <Table className="w-full" zebra compact>
                    <Table.Head>
                        <span></span>
                        <span></span>
                        <span>{t('BackOffice.stats.emailLeakPercent')}</span>
                    </Table.Head>
                    <Table.Body>
                        <Table.Row>
                            <span>{t('BackOffice.stats.scanCount')}</span>
                            <span>{data?.global.count}</span>
                            <span>{data?.global.leak.toFixed(0)}%</span>
                        </Table.Row>
                        <Table.Row>
                            <span>{t('BackOffice.stats.scanCountMonth')}</span>
                            <span>{data?.month.count}</span>
                            <span>{data?.month.leak.toFixed(0)}%</span>
                        </Table.Row>
                    </Table.Body>
                </Table> */}
        <div className="flex text-center">
          {/* Nombre de mails scannés */}
          <Card className="flex-1 p-4">
            <Card.Title className="text-gray-600">
              {t("BackOffice.stats.scanMailCount")}
            </Card.Title>
            <Card.Body className="font-extrabold text-3xl text-primary">
              {data?.global.count}
            </Card.Body>
          </Card>
          <div className="w-6"></div>
          {/* Nombre de scans effectués les 30 derniers jours */}
          <Card className="flex-1 p-4">
            <Card.Title className="text-gray-600">
              {t("BackOffice.stats.scanCountMonth")}
            </Card.Title>
            <Card.Body className="font-extrabold text-3xl text-primary">
              {data?.month.count}
            </Card.Body>
          </Card>
          <div className="w-6"></div>
          {/* Taux des scans avec au moins une fuite trouvée */}
          <Card className="flex-1 p-4">
            <Card.Title className="text-gray-600">
              {t("BackOffice.stats.emailLeakPercent")}
            </Card.Title>
            <Card.Body className="font-extrabold text-3xl text-primary">
              {data?.global.leak.toFixed(0)}%
            </Card.Body>
          </Card>
        </div>
      </Card.Body>
    </Card>
  );
}
