import { Col, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendGetRequest } from "../auth";
import MailCharts from "./charts/mailCharts";
import QuizCharts from "./charts/quizCharts";
import PhishingCharts from "./charts/phishingCharts";
import PresentationCharts from "./charts/presentationCharts";

export default function BackofficeCharts({}) {
  const [data, setData] = useState();
  const [, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const [presentations, quiz, phishing, scan] = await Promise.all([
          sendGetRequest("/backoffice/usage/presentations"),
          sendGetRequest("/backoffice/usage/quiz"),
          sendGetRequest("/backoffice/usage/phishing"),
          sendGetRequest("/backoffice/usage/scan")
        ]);
    
        const aggregatedData = {
          presentations: presentations.data.data,
          quiz: quiz.data.data,
          phishing: phishing.data.data,
          scan: scan.data.data
        };
    
        setData(aggregatedData);
        console.log(aggregatedData);
      } catch (error) {
        message.error(t("error.Unknown"));
      }
      setLoading(false);
    }
    
    loadData();
    
  }, []);

  //return (<Row gutter={[16, 16]}>
  return (
    <>
      <Col span={24}>
        <MailCharts data={data?.scan} />
      </Col>
      <Col span={12}>
        <PresentationCharts data={data?.presentations} />
      </Col>
      <Col span={12}>
        <PhishingCharts data={data?.phishing} />
      </Col>
      <Col span={12}>
        <QuizCharts data={data?.quiz} />
      </Col>
    </>
  );
  //</Row>)
}
