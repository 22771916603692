import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Button, Dropdown, Swap } from "react-daisyui";
import i18n from "../i18n";

export default function LanguageSwitcher(props) {
  return (
    <div className="m-3">
      <Dropdown>
        <div className="flex text-center w-8 h-8 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="m-auto text-base">
            {i18n.language.toUpperCase()}
          </span>
        </div>
        <Dropdown.Menu className="p-0 shadow-xl rounded-box">
          {i18n.languages.map((entry) => (
            <Dropdown.Item
              onClick={() => {
                i18n.changeLanguage(entry);
                localStorage.setItem("lang", entry);
              }}
            >
              {entry.toUpperCase()}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
