import { Form, Input, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { sendGetRequest, uploadFile } from "../auth";
import { API_URL } from "../conf";

export function ImageBase64(props) {
  const [fileList, setFileList] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const count = props.count === undefined ? 0 : props.count;

  function setFilesValue(value, count) {
    setFileList(value);
    setFileCount(count);
    if (props.single === true && value.length > 0) value = value[0];
    props.onChange(value);
  }

  return (
    <Upload
      maxCount={count}
      listType="picture-card"
      onRemove={(file) => {
        const fileId = file.originFileObj.fileId;
        const index = fileList.indexOf(fileId);
        fileList.splice(index, 1);
        setFilesValue(fileList, fileCount - 1);
      }}
      onChange={(file) => {
        console.log("CHANGED FILE UPLOAD :", file);
      }}
      // beforeUpload={(file) => {
      //     return true;
      // }}
      // customRequest={upload}
      // fileList={fileList}
    >
      {/* {props.children} */}
      {count == 0 || fileCount < count ? props.children : null}
    </Upload>
  );
}

export default function ImageUploader(props) {
  const [fileList, setFileList] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const count = props.count === undefined ? 0 : props.count;

  useEffect(() => {
    if (props.single && props.value) {
      setFileList([props.value]);
      setFileCount(1);
    }
  }, [props.value]);

  function getFileList() {
    return fileList.map((item) => ({
      uid: item,
      thumbUrl: `${API_URL}/files/${item}`,
    }));
  }

  function setFilesValue(value, count) {
    setFileList(value);
    setFileCount(count);
    if (props.single === true) value = value[0];
    console.log("NEW VALUE", value);
    props.onChange(value);
  }

  async function upload(options) {
    try {
      const response = await uploadFile(
        "/upload/PUBLIC/",
        options.file,
        options.onProgress,
      );
      options.onSuccess();
      const fileId = response.data.data.id;
      options.file.fileId = fileId;
      setFilesValue([...fileList, fileId], fileCount + 1);
      return true;
    } catch (error) {
      options.onError();
      return false;
    }
  }

  const content = (
    <Upload
      maxCount={props.count}
      listType="picture-card"
      onRemove={(file) => {
        const fileId = file.id;
        const index = fileList.indexOf(fileId);
        fileList.splice(index, 1);
        setFilesValue(fileList, fileCount - 1);
      }}
      customRequest={upload}
      isImageUrl={() => true}
      fileList={getFileList()}
    >
      {/* {props.children} */}
      {count == 0 || fileCount < count ? props.children : null}
    </Upload>
  );

  if (props.crop) {
    return (
      <>
        <ImgCrop rotate>{content}</ImgCrop>
      </>
    );
  } else {
    return content;
  }
}
