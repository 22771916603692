import { Result } from "antd";
import { useParams } from "react-router-dom";
import QuizCreator from "../components/QuizCreator";

export default function QuizCreationPage(props) {
  const { quizId } = useParams();
  if (quizId != undefined) {
    return <QuizCreator id={quizId} />;
  }
  return <Result status="404" />;
}
