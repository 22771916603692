import { Col, Typography } from "antd";
import { useEffect, useState } from "react";
import { Card, Table } from "react-daisyui";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const barColors = ["#4b6bfb", "#f87272"];

export default function PresentationCharts({ data }) {
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const newChartData = Object.values(data)?.map((item, index) => ({
        name: item?.data?.title,
        vues: item?.count,
        fill: barColors[index % barColors.length],
      }));
      console.log(newChartData);
      setChartData(newChartData);
    }
  }, [data]);

  return (
    <Card style={{ padding: 5 }}>
      <Card.Title className="text-center">
        <Col span={24} className="text-center">
          <Typography.Title level={3}>
            {t("BackOffice.stats.presentations")}
          </Typography.Title>
        </Col>
      </Card.Title>
      <Card.Body style={{ padding: 5 }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            fill="#4b6bfb"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="vues" barSize={80} />
          </BarChart>
        </ResponsiveContainer>
        {/* <Table className="w-full" zebra compact>
                    <Table.Head>
                        <span>{t('BackOffice.stats.presentations')}</span>
                        <span>{t('BackOffice.stats.PresentationViews')}</span>
                    </Table.Head>
                    <Table.Body>
                        {data && Object.values(data)?.map((item) => (
                            <Table.Row>
                                <span>{item?.data?.title}</span>
                                <span>{item?.count}</span>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table> */}
      </Card.Body>
    </Card>
  );
}
