import {
  DeleteFilled,
  EditFilled,
  SecurityScanFilled,
} from "@ant-design/icons";
import { PlusCircleIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import { Button } from "react-daisyui";
import {
  Tag,
  Table,
  Skeleton,
  Modal,
  Typography,
  Form,
  Input,
  List,
  Card,
  Statistic,
  Row,
  Col,
  Avatar,
  Badge,
  Descriptions,
  Tooltip,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import {
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import message from "../components/Alert";
import { ScanCreationModal } from "./ScansPage";
import { useTranslation } from "react-i18next";

const colors = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

const getStringHash = function (str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getColorFromString = (str) => {
  return colors[getStringHash(str) % colors.length];
};

function AssetCreationModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function cancelCreate() {
    formRef.resetFields();
    props.setVisible(false);
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPostRequest("/scanning/targets", data);
      message.success("Asset Created Successfully");
      formRef.resetFields();
      props.setVisible(false);
    } catch (error) {
      message.error("Error Creating Asset");
    }
    setLoading(false);
  }

  function submitButton() {
    formRef.submit();
  }

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={<Typography.Title level={4}>Add New Asset</Typography.Title>}
      visible={props.visible}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={formRef}
        initialValues={{ type: "web" }}
        name="CreateOrganization"
        onFinish={submitCreate}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input placeholder="eg: Backend API"></Input>
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: "Please select a type!" }]}
        >
          <Select disabled>
            <Select.Option value="web">Web</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          rules={[{ required: true, message: "Please input a url!" }]}
        >
          <Input placeholder="eg: http://example.com"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function AssetUpdateModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function cancelCreate() {
    props.setVisible(false);
    formRef.resetFields();
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPatchRequest(`/scanning/targets/${props.data.id}`, data);
      message.success("Asset Created Successfully");
      props.setVisible(false);
      formRef.resetFields();
    } catch (error) {
      message.error("Error Creating Asset");
    }
    setLoading(false);
  }

  function submitButton() {
    formRef.submit();
  }

  useEffect(() => {
    formRef.setFieldsValue(props.data);
  }, [props]);

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={<Typography.Title level={4}>Invite New User</Typography.Title>}
      visible={props.visible}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={formRef}
        name="CreateOrganization"
        onFinish={submitCreate}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input placeholder="eg: Backend API"></Input>
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: "Please select a type!" }]}
        >
          <Select disabled>
            <Select.Option value="web">Web</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          rules={[{ required: true, message: "Please input a url!" }]}
        >
          <Input placeholder="eg: http://example.com"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function AssetsPage(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [createVisible, setCreateVisible] = useState();
  const [scanTarget, setScanTarget] = useState();
  const [toEdit, setToEdit] = useState();
  const [_refresh, _setRefresh] = useState(0);
  const { t } = useTranslation();

  function refresh() {
    _setRefresh((old) => old + 1);
  }

  async function deleteAsset(id) {
    try {
      await sendDeleteRequest(`/scanning/targets/${id}`);
      message.success("Asset Deleted Successfully !");
      refresh();
    } catch (error) {
      message.error("An error occured while deleting an asset !");
    }
  }

  useEffect(() => {
    async function getAssets() {
      setLoading(true);
      try {
        const response = await sendGetRequest("/scanning/targets");
        setData(response.data.data);
      } catch (error) {
        message.error("An error occured");
      }
      setLoading(false);
    }
    getAssets();
  }, [_refresh]);

  return (
    <>
      <div className="flex flex-row place-content-end">
        <Button
          animation={true}
          size="sm"
          color="primary"
          className="text-right"
          onClick={() => {
            setCreateVisible(true);
          }}
        >
          <PlusCircleIcon className="mr-2 h-5 w-5" />
          {t("Add New Asset")}
        </Button>
      </div>
      <br />
      <List
        grid={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
          gutter: [16, 16],
        }}
        dataSource={data}
        loading={loading}
        renderItem={(item) => {
          let actions = [
            <Tooltip title="Edit Asset">
              <EditFilled
                onClick={() => {
                  setToEdit(item);
                }}
                key="Edit"
              />
            </Tooltip>,
            <Tooltip title="Launch Scan">
              <SecurityScanFilled
                style={{ fontSize: 20, color: "#1890ff" }}
                onClick={() => {
                  setScanTarget(item);
                }}
                key="LaunchScan"
              />
            </Tooltip>,
            <Tooltip title="Delete Asset">
              <DeleteFilled
                style={{ color: "red" }}
                onClick={() => {
                  deleteAsset(item.id);
                }}
                key="Delete"
              />
            </Tooltip>,
          ];
          let extra = (
            <Tag color={getColorFromString(item.type)}>{item.type}</Tag>
          );
          return (
            <List.Item>
              <Card
                bodyStyle={{ padding: 0 }}
                extra={<div style={{ textAlign: "center" }}>{extra}</div>}
                actions={actions}
                title={<Typography.Text strong>{item.name}</Typography.Text>}
              >
                <Descriptions
                  contentStyle={{ textAlign: "center" }}
                  bordered
                  layout="horizontal"
                  column={1}
                  size="small"
                >
                  <Descriptions.Item
                    label={<Typography.Text>URL</Typography.Text>}
                  >
                    <Typography.Text code>{item.url}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<Typography.Text>Scan Tasks</Typography.Text>}
                  >
                    <Typography.Text>{item.scans}</Typography.Text>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </List.Item>
          );
        }}
      />
      <AssetCreationModal
        visible={createVisible}
        setVisible={(val) => {
          setCreateVisible(val);
          refresh();
        }}
      />
      <AssetUpdateModal
        data={toEdit}
        visible={toEdit !== undefined}
        setVisible={() => {
          setToEdit(undefined);
          refresh();
        }}
      />
      <ScanCreationModal
        visible={scanTarget !== undefined}
        setVisible={() => {
          setScanTarget(undefined);
          refresh();
        }}
        target={scanTarget}
      />
    </>
  );
}
