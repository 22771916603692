import {
  MailOpenIcon,
  OfficeBuildingIcon,
  ServerIcon,
  ShieldCheckIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { Avatar, Countdown, Stats } from "react-daisyui";
import Icon from "@ant-design/icons";
import { Col, Row } from "antd";
import { useContext } from "react";
import { AppContext } from "../context";
import { useTranslation } from "react-i18next";
const { Stat } = Stats;

export default function StatsBar(props) {
  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();
  const data = props.data;

  let items = [
    {
      title: t("Users"),
      value: data?.users.count,
      icon: <UsersIcon style={{ height: 42 }} />,
    },
    {
      title: t("Invites"),
      value: data?.invites.count,
      icon: <MailOpenIcon style={{ height: 42 }} />,
    },
    {
      title: t("Assets"),
      value: data?.assets.count,
      icon: <ServerIcon style={{ height: 42 }} />,
    },
    {
      title: t("Scans"),
      value: data?.tasks.count,
      icon: <ShieldCheckIcon style={{ height: 42 }} />,
    },
  ];
  if (userProfile.role === "staff")
    items.push({
      title: t("Organizations"),
      value: data?.organizations.count,
      icon: <OfficeBuildingIcon style={{ height: 42 }} />,
    });
  return (
    <Row justify="center" align="middle" style={{ width: "100%" }}>
      <Stats
        style={{ width: "100%" }}
        className="stats-vertical md:stats-horizontal shadow font-sans"
      >
        {items.map((item) => (
          <Stats.Stat>
            <Stat.Item variant="figure" className="text-primary">
              {item.icon}
            </Stat.Item>
            <Stat.Item variant="title">{item.title}</Stat.Item>
            <Stat.Item variant="value" className="text-primary">
              {item.value}
            </Stat.Item>
          </Stats.Stat>
        ))}
      </Stats>
    </Row>
  );
}
