import { API_URL } from "./conf";
import axios from "axios";

export async function uploadFile(path, file, onProgress) {
  console.log("UPLOADING FILE :", path);
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onProgress,
  };
  const data = {
    file: file,
  };
  return axios.post(`${API_URL}${path}`, data, config);
}

export async function sendGetRequest(path) {
  console.log("SENDING GET REQUEST :", path);
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(`${API_URL}${path}`, config);
}

export async function sendPostRequest(path, data) {
  console.log("SENDING POST REQUEST :", path);
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.post(`${API_URL}${path}`, data, config);
}

export async function sendPatchRequest(path, data) {
  console.log("SENDING PATCH REQUEST :", path);
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.patch(`${API_URL}${path}`, data, config);
}

export async function sendDeleteRequest(path) {
  console.log("SENDING DELETE REQUEST :", path);
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.delete(`${API_URL}${path}`, config);
}

export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export function removeToken() {
  localStorage.removeItem("token");
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function getUserClaims() {
  let token = getToken();
  if (token === null) {
    return null;
  }
  return parseJwt(token);
}
