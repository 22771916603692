import { Card, List, Result, Spin } from "antd";
import message from "../components/Alert";
import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { sendGetRequest } from "../auth";
import EmbedPresentation from "../components/EmbedPresentation";

// const presentations_data = [
//     {
//         description: "Sensibilisation sécurité", title: "Généralités et bonnes pratiques", cover: "/slides/thumbnail1.jpg",
//         link: `${window.location.origin}/slides/presentation1.pptx`
//     },
//     {
//         description: "Sensibilisation sécurité", title: "Exemples d’attaques cyber", cover: "/slides/thumbnail2.jpg",
//         link: `${window.location.origin}/slides/presentation2.pptx`
//     },
//     // {
//     //     description: "Sensibilisation sécurité", title: "Sécurité des terminaux mobiles", cover: "/slides/thumbnail3.jpg",
//     //     link: `${window.location.origin}/slides/presentation3.pptx`
//     // }
// ]

export default function (props) {
  const { slideId } = useParams();

  if (slideId) {
    return <PresentationSlide />;
  } else {
    return <PresentationsList />;
  }
}

function PresentationSlide() {
  const [presentationData, setPresentationData] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { slideId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/training/${slideId}`);
        setPresentationData(response.data.data);
      } catch (error) {
        message.error(t(error.response.data.error));
      }
      setLoading(false);
    }
    getData();
  }, []);

  if (loading === false && presentationData != undefined) {
    return (
      <EmbedPresentation
        url={`${window.location.origin}${presentationData.link}`}
      />
    );
  } else if (loading === false && presentationData === undefined) {
    return (
      <Result
        status="warning"
        title={t("error.SlideNotFound")}
        extra={
          <Button
            onClick={() => {
              navigate("/training");
            }}
          >
            {t("error.ReturnToTraining")}
          </Button>
        }
      />
    );
  }
}

export function PresentationCard(props) {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => {
        navigate(`/training/${props.id}`);
      }}
      hoverable
      cover={
        <img
          style={{ borderBottom: "1px solid lightgrey" }}
          alt="presentation display"
          src={props.cover}
        />
      }
    >
      <Card.Meta title={props.title} description={props.description} />
    </Card>
  );
}

// export function PresentationsList(props) {
//   const [presentationData, setPresentationData] = useState();
//   const [loading, setLoading] = useState(true);
//   const { t } = useTranslation();

//   useEffect(() => {
//     async function getData() {
//       setLoading(true);
//       try {
//         const response = await sendGetRequest(`/training/`);
//         setPresentationData(response.data.data);
//       } catch (error) {
//         message.error(t("error.Unknown"));
//       }
//       setLoading(false);
//     }
//     getData();
//   }, []);

//   return (
//     <List
//       dataSource={presentationData}
//       renderItem={(item, index) => (
//         <List.Item>
//           <PresentationCard {...item} id={item.id} />
//         </List.Item>
//       )}
//       grid={{ gutter: 16, column: 3 }}
//     />
//   );
// }

export function PresentationsList(props) {
  const [presentationData, setPresentationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/training/`);
        setPresentationData(response.data.data);
      } catch (error) {
        message.error(t("error.Unknown"));
      }
      setLoading(false);
    }
    getData();
  }, []);

  // Filter presentations into guides and others based on the title
  const guides = presentationData.filter((item) =>
    item.title.toLowerCase().includes("guide"),
  );
  const otherPresentations = presentationData.filter(
    (item) => !item.title.toLowerCase().includes("guide"),
  );

  // Split other presentations into 3 categories
  // Niveau 1 « pour démarrer »
  // Niveau 2 « pour se perfectionner »
  // Niveau 3 « pour aller plus loin »
  // Le contenu existant et futur en l’état des différents livrables attendus se ventilerait comme suit :
  // Pour démarrer (niveau 1)
  //    Généralités et bonnes pratiques
  //    Exemples d’attaques cyber
  //    Sécurité des mots de passe
  // Pour se perfectionner (niveau 2)
  //    Se prémunir contre le phishing
  //    Fraude cyber
  //    Sécurité et confidentialité sur les réseaux sociaux
  //    Navigation sur internet et messagerie
  //    Séparation activités professionnelles et personnelles
  //    Sécurité des postes de travail
  // Pour aller plus loin (niveau 3)
  //    Sécurité au cours des déplacements
  //    Sécurité des terminaux mobiles
  //    Protection des données et RGPD
  //    Les bases de la réglementation DORA
  //    Introduction au RGPD

  const categories = {
    1: ["Généralités et bonnes pratiques", "Exemples d'attaques cyber", "Sécurité des mots de passe"],
    2: ["Se prémunir contre le phishing", "Fraude cyber", "Sécurité et confidentialité sur les réseaux sociaux", "Sécurité navigation Internet", "Sécurité messagerie", "Séparation des activités professionnelles et personnelles", "Sécurité des postes de travail"],
    3: ["Sécurité au cours des déplacements", "Sécurité des terminaux mobiles", "Protection des données et RGPD", "Les bases de la réglementation DORA", "Introduction au RGPD"]
  };

  const splitPresentations = (presentations) => {
    const result = {};
    for (const [key, value] of Object.entries(categories)) {
      result[key] = presentations.filter((item) => value.includes(item.title));
    }
    return result;
  }

  const splitOtherPresentations = splitPresentations(otherPresentations);

  // Function to render a list of presentations
  const renderPresentations = (presentations) => (
    <List
      dataSource={presentations}
      renderItem={(item) => (
        <List.Item>
          <PresentationCard {...item} id={item.id} />
        </List.Item>
      )}
      grid={{ gutter: 16, column: 3 }}
    />
  );

  // Render the loading state, guides section and other presentations section
  return loading ? (
    <Spin tip={t("loading")} />
  ) : (
    <div>
      {/* {otherPresentations.length > 0 && (
        <div>{renderPresentations(otherPresentations)}</div>
      )} */}
      {splitOtherPresentations[1].length > 0 && (
        <div>
          <h2 className="text-3xl font-bold">{t("training.level1")}</h2>
          {renderPresentations(splitOtherPresentations[1])}
        </div>
      )}
      {splitOtherPresentations[2].length > 0 && (
        <div>
          <h2 className="text-3xl font-bold">{t("training.level2")}</h2>
          {renderPresentations(splitOtherPresentations[2])}
        </div>
      )}
      {splitOtherPresentations[3].length > 0 && (
        <div>
          <h2 className="text-3xl font-bold">{t("training.level3")}</h2>
          {renderPresentations(splitOtherPresentations[3])}
        </div>
      )}
      {guides.length > 0 && (
        <div>
          <h2 className="text-3xl font-bold">{t("training.guides")}</h2>
          {renderPresentations(guides)}
        </div>
      )}
    </div>
  );
}
