import {
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeFilled,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { PlusCircleIcon } from "@heroicons/react/solid";
import {
  Button,
  Card,
  Col,
  List,
  Popconfirm,
  Result,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import { Button as DaisyButton } from "react-daisyui";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendDeleteRequest, sendGetRequest, sendPostRequest } from "../auth";
import QuizComponent from "../components/QuizComponent";
import { AppContext } from "../context";
import message from "../components/Alert";
import { useTranslation } from "react-i18next";

export function QuizPlayPage(props) {
  const { quizId } = useParams();
  return <QuizComponent id={quizId} />;
}

export default function QuizPage(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [_refresh, setRefresh] = useState(true);
  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function refresh() {
    setRefresh((old) => old + 1);
  }

  function getColorFromScore(score) {
    if (score === undefined) return "inherit";
    if (score >= 70) return "#52c41a";
    if (score >= 50) return "#faad14";
    return "#ff4d4f";
  }

  async function deleteQuiz(id) {
    setLoading(true);
    try {
      const response = await sendDeleteRequest(`/quiz/quiz/${id}`);
      refresh();
      message.success("Quiz Deleted");
    } catch (error) {
      message.error("Can't delete quiz");
    }
    setLoading(false);
  }

  async function createNewQuiz() {
    setLoading(true);
    const defaultData = {
      name: "New Quiz",
    };
    try {
      const response = await sendPostRequest("/quiz/quiz", defaultData);
      console.log("RESPONSE FROM CREATING QUIZ :", response);
      navigate(`/quizCreate/${response.data.data.id}`);
    } catch (error) {
      message.error("Can't create new quiz");
    }
    setLoading(false);
  }

  useEffect(() => {
    async function listQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest("/quiz/quiz");
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    listQuiz();
  }, [_refresh]);

  if (!loading && !data) {
    return <Result status="info" title="No quiz for now, check back soon" />;
  } else {
    return (
      <>
        <div className="flex flex-row place-content-end">
          {userProfile?.role === "staff" && (
            <Row gutter={[16, 16]}>
              <DaisyButton
                animation={true}
                size="sm"
                color="primary"
                className="text-right"
                pill={false}
                onClick={createNewQuiz}
              >
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Create New Quiz
              </DaisyButton>
            </Row>
          )}
        </div>
        <br />
        <List
          dataSource={data}
          loading={loading}
          renderItem={(item) =>
            userProfile.role === "staff"
              ? staffCard(item, t)
              : userCard(item, t)
          }
        />
      </>
    );
  }

  function userCard(item, t) {
    return (
      <List.Item>
        <Card
          style={{ width: "100%" }}
          hoverable
          onClick={() => {
            if (userProfile?.role !== "staff") navigate(`/quiz/${item.id}`);
          }}
        >
          <Row align="middle" gutter={16}>
            <Col span={12}>
              <Typography.Title level={3} strong>
                {item.name}
              </Typography.Title>
            </Col>
            <Col span={4}>
              <Statistic
                style={{ textAlign: "center" }}
                title={t("Questions")}
                value={item.questions}
              />
            </Col>
            <Col span={4}>
              <Statistic
                style={{ textAlign: "center" }}
                valueStyle={{ color: getColorFromScore(item.score) }}
                title={t("Score")}
                value={
                  item.score !== undefined ? item.score : t("Quiz.Nottaken")
                }
                suffix={item.score !== undefined ? "/ 100" : ""}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" block icon={<PlayCircleOutlined />}>
                {t("QuizStart")}
              </Button>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  }

  function staffCard(item, t) {
    return (
      <List.Item>
        <Card
          style={{ width: "100%" }}
          title={
            <Typography.Title level={3} strong>
              {item.name}
            </Typography.Title>
          }
          extra={
            userProfile?.role === "staff" && (
              <Space size={10}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    navigate(`/quizCreate/${item.id}`);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    navigate(`/quiz/${item.id}`);
                  }}
                >
                  View
                </Button>
                <Popconfirm
                  title="Delete this Quiz ?"
                  onConfirm={() => {
                    deleteQuiz(item.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" danger icon={<DeleteOutlined />}>
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            )
          }
        >
          <Row align="middle">
            <Col span={12}>
              <Statistic
                style={{ textAlign: "center" }}
                title="Questions"
                value={item.questions}
              />
            </Col>
            <Col span={12}>
              <Statistic
                style={{ textAlign: "center" }}
                valueStyle={{ color: getColorFromScore(item.score) }}
                title="Score"
                value={
                  item.score !== undefined ? item.score : t("Quiz.Nottaken")
                }
                suffix={item.score !== undefined ? "/ 100" : ""}
              />
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  }
  // return (<QuizComponent id={1} />)
}
