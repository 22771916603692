import message from "../components/Alert";
import { Button } from "react-daisyui";
import { useEffect, useState } from "react";
import {
  getUserClaims,
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import { PlusCircleIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import { PencilAltIcon } from "@heroicons/react/outline";
import {
  Table,
  Button as AntdBtn,
  Skeleton,
  Modal,
  Typography,
  Form,
  Input,
  List,
  Card,
  Statistic,
  Row,
  Col,
  Descriptions,
  Badge,
  Tooltip,
  Tag,
  Select,
  DatePicker,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  CloudServerOutlined,
  DatabaseOutlined,
  DeleteFilled,
  EditFilled,
  EditOutlined,
  FileTextFilled,
  LikeOutlined,
  PlayCircleFilled,
  SecurityScanFilled,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { getColorFromString } from "./AssetsPage";
import { useNavigate } from "react-router-dom";
import Ribbon from "antd/lib/badge/Ribbon";
import { useTranslation } from "react-i18next";

export function getBadgeStatus(status) {
  switch (status) {
    case "finished":
      return "success";
    case "started":
    case "queued":
      return "processing";
    case "failed":
      return "error";
    case "idle":
    case "scheduled":
    default:
      return "default";
  }
}

export function scanStillRunning(status) {
  switch (status) {
    case "started":
    case "queued":
      return true;
    default:
      return false;
  }
}

export function scanStarted(status) {
  switch (status) {
    case "finished":
    case "failed":
    case "started":
    case "queued":
      return true;
    default:
      return false;
  }
}

export function ScanCreationModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingTargets, setLoadingTargets] = useState(false);
  const [targets, setTargets] = useState([]);
  const [startMode, setStartMode] = useState(false);
  const userRole = getUserClaims()["role"];

  function cancelCreate() {
    formRef.resetFields();
    props.setVisible(false);
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPostRequest("/scanning/tasks/", data);
      message.success("Invite Created Successfully");
      formRef.resetFields();
      props.setVisible(false);
    } catch (error) {
      message.error("Error Creating Invite");
    }
    setLoading(false);
  }

  useEffect(() => {
    async function getOrganizations() {
      setLoadingTargets(true);
      try {
        const response = await sendGetRequest("/scanning/targets/");
        setTargets(response.data.data);
        setLoadingTargets(false);
      } catch (error) {
        message.error("Error Getting Organizations");
      }
    }
    getOrganizations();
  }, []);

  function submitButton() {
    formRef.submit();
  }

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={<Typography.Title level={4}>Start New Scan</Typography.Title>}
      visible={props.visible}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={formRef}
        initialValues={{ role: "client", target: props.target?.id }}
        name="CreateOrganization"
        onFinish={submitCreate}
      >
        {props.target === undefined ? (
          <Form.Item
            label="Asset"
            name="target"
            rules={[{ required: true, message: "Select target !" }]}
          >
            <Select
              loading={loadingTargets}
              showSearch
              optionFilterProp="children"
            >
              {targets.map((element) => (
                <Select.Option key={element.id} value={element.id}>
                  {element.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            label="Asset"
            name="target"
            rules={[{ required: true, message: "Select target !" }]}
          >
            <Select
              defaultValue={props.target.id}
              value={props.target.id}
              optionFilterProp="children"
              disabled
            >
              <Select.Option key={props.target.id} value={props.target.id}>
                {props.target.name}
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Start mode"
          name="status"
          rules={[{ required: true, message: "Select start mode !" }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            onSelect={setStartMode}
          >
            <Select.Option key="idle" value="idle">
              start later
            </Select.Option>
            <Select.Option key="queued" value="queued">
              start now
            </Select.Option>
            <Select.Option key="scheduled" value="scheduled">
              schedule
            </Select.Option>
          </Select>
        </Form.Item>
        {startMode == "scheduled" && (
          <Form.Item
            label="Schedule"
            name="start_time"
            rules={[{ required: true, message: "Select scan time !" }]}
          >
            <DatePicker showTime />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

function ScansList(props) {
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState();
  const [createVisible, setCreateVisible] = useState();
  const [_refresh, _setRefresh] = useState(0);
  const [toEdit, setToEdit] = useState(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function refresh() {
    _setRefresh((old) => old + 1);
  }

  async function deleteScan(id) {
    try {
      const response = await sendDeleteRequest(`/scanning/tasks/${id}`);
      refresh();
      message.success("Scan deleted !");
    } catch (error) {
      message.error("An error occured");
    }
  }

  async function startScan(id) {
    try {
      const response = await sendPostRequest(`/scanning/tasks/${id}/start`);
      refresh();
      message.success("Scan Queued !");
    } catch (error) {
      message.error("An error occured");
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        const response = await sendGetRequest("/scanning/tasks");
        setStatus("success");
        setData(response.data.data);
      } catch (error) {
        setStatus("error");
        message.error("An error occured");
      }
    }
    loadData();
  }, [_refresh]);

  // if (status === "loading" || status === "error") {
  //     return (<List grid={{
  //         xs: 1,
  //         sm: 2,
  //         md: 2,
  //         lg: 3,
  //         xl: 3,
  //         xxl: 3,
  //         gutter: [16, 16]
  //     }} dataSource={[0, 0, 0, 0, 0, 0]}
  //         renderItem={item => (<List.Item><Card loading /></List.Item>)} />);
  // }
  // else if (status === "success") {
  return (
    <>
      <div className="flex flex-row place-content-end">
        <Button
          animation={true}
          size="sm"
          color="primary"
          className="text-right"
          onClick={() => {
            setCreateVisible(true);
          }}
        >
          <ShieldCheckIcon className="mr-2 h-5 w-5" />
          {t("Start New Scan")}
        </Button>
      </div>
      <br />
      <List
        grid={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
          gutter: [16, 16],
        }}
        dataSource={data}
        loading={status !== "success"}
        renderItem={(item) => {
          const extra = [
            <Tag color={getColorFromString(item.target_obj.type)}>
              {item.target_obj.type}
            </Tag>,
            <Badge status={getBadgeStatus(item.status)} text={item.status} />,
          ];
          let actions = [];
          if (!scanStarted(item.status)) {
            actions.push(
              <Tooltip title="Start Now">
                <PlayCircleFilled
                  style={{ color: "#1890ff" }}
                  onClick={() => {
                    startScan(item.id);
                  }}
                  key="Start"
                />
              </Tooltip>,
            );
            actions.push(
              <Tooltip title="Delete Scan">
                <DeleteFilled
                  style={{ color: "red" }}
                  onClick={() => {
                    deleteScan(item.id);
                  }}
                  key="Delete"
                />
              </Tooltip>,
            );
          } else {
            actions.push(
              <Tooltip title="View Results">
                <FileTextFilled
                  onClick={() => {
                    navigate(`/scans/${item.id}`);
                  }}
                  key="Edit"
                />
              </Tooltip>,
            );
          }
          return (
            <List.Item>
              <Card
                actions={actions}
                extra={<div style={{ textAlign: "center" }}>{extra}</div>}
                title={
                  <Tooltip title={`ScanID: ${item.id}`}>
                    <Typography.Text strong>
                      {item.target_obj.name}
                    </Typography.Text>
                  </Tooltip>
                }
                bodyStyle={{ padding: 0 }}
              >
                <Descriptions
                  contentStyle={{ textAlign: "center" }}
                  bordered
                  layout="horizontal"
                  column={1}
                  size="small"
                >
                  <Descriptions.Item
                    label={<Typography.Text>URL</Typography.Text>}
                  >
                    <Typography.Text code>
                      {item.target_obj.url}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<Typography.Text>Start</Typography.Text>}
                  >
                    {item.start_time ? (
                      <Typography.Text>
                        <Tooltip title={item.start_time}>
                          {moment(Date.parse(item.start_time)).calendar()}
                        </Tooltip>
                      </Typography.Text>
                    ) : (
                      <Typography.Text type="secondary">N/A</Typography.Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<Typography.Text>End</Typography.Text>}
                  >
                    {item.end_time ? (
                      <Typography.Text>
                        <Tooltip title={item.end_time}>
                          {moment(Date.parse(item.end_time)).calendar()}
                        </Tooltip>
                      </Typography.Text>
                    ) : (
                      <Typography.Text type="secondary">N/A</Typography.Text>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </List.Item>
          );
        }}
      />
      <ScanCreationModal
        visible={createVisible}
        setVisible={() => {
          setCreateVisible(false);
          refresh();
        }}
      />
    </>
  );
  // }
}

export default function ScansPage(props) {
  return (
    <>
      <ScansList />
    </>
  );
}
