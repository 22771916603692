export default function MentionsLegales() {
  return (
    <div>
      <ol>
        <li>
          <p>
            <strong>&emsp;1. Mentions l&eacute;gales</strong>
          </p>
        </li>
      </ol>
      <p>
        Conform&eacute;ment aux dispositions de l&rsquo;article 6-III-1 de la
        loi n&deg;2004-575 du 21 juin 2004 pour la confiance dans
        l&rsquo;&eacute;conomie num&eacute;rique, vous trouverez ci-dessous
        l&rsquo;ensemble des informations l&eacute;gales relatives au
        pr&eacute;sent site Internet.&nbsp;
      </p>
      <p>
        <strong>Identit&eacute; de l&rsquo;&eacute;diteur&nbsp;</strong>
      </p>
      <p>
        Soci&eacute;t&eacute; &eacute;ditrice du pr&eacute;sent site Internet,
        et son propri&eacute;taire exclusif :<br />
        &nbsp;NB : Cette soci&eacute;t&eacute; sera d&eacute;sign&eacute;e en
        tant qu&rsquo;&ldquo;&eacute;diteur du site&rdquo; dans le reste des
        mentions l&eacute;gales du pr&eacute;sent site.
      </p>
      <p>
        INQUEST
        <br />
        &nbsp;Soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital de
        10 000 euros,
        <br />
        &nbsp;830 425 971 RCS Paris
        <br />
        &nbsp;Adresse du si&egrave;ge social :<br />
        &nbsp;13-15 rue Jean Jaur&egrave;s, 92800 Puteaux
        <br />
        &nbsp;
        <br />
        &nbsp;
      </p>
      <p>
        Pr&eacute;sident : Alexis Nardone
        <br />
        &nbsp;Directrice de la publication du pr&eacute;sent site Internet :
        Thibault Carr&eacute;
      </p>
      <p>
        <strong>R&eacute;alisation :</strong>
      </p>
      <p>
        Conception graphique, r&eacute;alisation technique :&nbsp;
        <strong>SEKERA GROUP</strong>
      </p>
      <p>
        <strong>H&eacute;bergement :</strong>
        <strong>&nbsp;OVH</strong>
      </p>
      <ol start="2">
        <li>
          <p>
            <strong>&emsp;2. Conditions d&rsquo;utilisation&nbsp;</strong>
          </p>
        </li>
      </ol>
      <p>
        En utilisant le pr&eacute;sent site Internet, vous reconnaissez avoir
        lu, compris et accept&eacute; les pr&eacute;sentes conditions
        d&rsquo;utilisation du site, et vous vous engagez &agrave; les respecter
        en int&eacute;gralit&eacute; et sans restriction.
      </p>
      <p>
        L&rsquo;&eacute;diteur du site met en &oelig;uvre tous les moyens
        &agrave; sa disposition afin de fournir un contenu &agrave; jour et
        fiable sur son site, ainsi que pour s&eacute;curiser
        l&rsquo;h&eacute;bergement de son site Internet, en vue notamment
        d&rsquo;emp&ecirc;cher toute propagation de virus informatique via le
        site. Toutefois, l&rsquo;&eacute;diteur du site d&eacute;cline toute
        responsabilit&eacute; relative &agrave; d&rsquo;&eacute;ventuels
        dommages directs ou indirects qui pourraient avoir &eacute;t&eacute;
        engendr&eacute;s par l&rsquo;acc&egrave;s au pr&eacute;sent site, ou par
        l&rsquo;utilisation du site et rubrique/ou des informations qui y sont
        mises &agrave; disposition de l&rsquo;utilisateur. Ceci inclut entre
        autres l&rsquo;inaccessibilit&eacute;, la d&eacute;t&eacute;rioration,
        la destruction ou perte de donn&eacute;es, et rubrique/ou la propagation
        de virus informatiques qui pourraient survenir sur le mat&eacute;riel
        informatique de l&rsquo;utilisateur. De m&ecirc;me, il ne saurait
        &ecirc;tre tenu pour responsable d&rsquo;une coupure temporaire ou
        d&eacute;finitive de l&rsquo;acc&egrave;s au pr&eacute;sent site
        Internet.
      </p>
      <p>
        Le Client ne peut en aucune mani&egrave;re r&eacute;utiliser,
        c&eacute;der ou exploiter pour son propre compte tout ou partie des
        &eacute;l&eacute;ments ou travaux du Site.
      </p>
      <p>
        Ce site internet est normalement accessible &agrave; tout moment aux
        Clients. Une interruption pour raison de maintenance technique peut
        &ecirc;tre toutefois d&eacute;cid&eacute;e par INQUEST ou son
        prestataire, qui s&rsquo;efforceront alors de communiquer
        pr&eacute;alablement aux Clients les dates et heures de
        l&rsquo;intervention.&nbsp;
      </p>
      <p>
        De la m&ecirc;me fa&ccedil;on, les mentions l&eacute;gales peuvent
        &ecirc;tre modifi&eacute;es &agrave; tout moment : elles
        s&rsquo;imposent n&eacute;anmoins au Client qui est invit&eacute;
        &agrave; s&rsquo;y r&eacute;f&eacute;rer le plus souvent possible afin
        d&rsquo;en prendre connaissance.
      </p>
      <p>
        Le pr&eacute;sent site est susceptible de contenir des liens vers
        d&rsquo;autres sites Internet, ou d&rsquo;autres ressources sur
        Internet. Ces liens sont propos&eacute;s &agrave; simple but de
        compl&eacute;ment d&rsquo;information. Dans la mesure o&ugrave;
        l&rsquo;&eacute;diteur du site n&rsquo;a aucun contr&ocirc;le sur les
        sites ou services en question, il ne saurait &ecirc;tre tenu pour
        responsable quant aux contenus, services, ou publicit&eacute;s, ou tout
        autre mat&eacute;riel disponible sur ou &agrave; partir de ces sites ou
        ressources externes. L&rsquo;activation de ces liens n&rsquo;a aucun
        caract&egrave;re obligatoire, et l&rsquo;utilisateur reconna&icirc;t les
        activer &agrave; ses propres risques et p&eacute;rils.&nbsp;
      </p>
      <p>
        <strong>Propri&eacute;t&eacute; intellectuelle :</strong>
      </p>
      <p>
        L&rsquo;&eacute;diteur du site d&eacute;tient tous les droits de
        propri&eacute;t&eacute;, notamment intellectuelle, sur le pr&eacute;sent
        site et les &eacute;l&eacute;ments qu&rsquo;il contient (textes, photos,
        &eacute;l&eacute;ments graphiques et multim&eacute;dia, et autres), ou
        bien d&eacute;tient l&rsquo;autorisation d&rsquo;utilisation des
        &eacute;l&eacute;ments qui ne lui appartiennent pas, par leurs
        propri&eacute;taires respectifs ou ayants droit. Ceci comprend les
        droits d&rsquo;utilisation et de reproduction. En cons&eacute;quence,
        toute reproduction partielle ou totale du pr&eacute;sent site et des
        &eacute;l&eacute;ments qu&rsquo;il contient est strictement interdite
        sans autorisation &eacute;crite de l&rsquo;&eacute;diteur du site ou du
        tiers d&eacute;tenteur des droits.
      </p>
      <p>
        Les marques, brevets, logos et tous autres signes distinctifs contenus
        sur ce site sont la propri&eacute;t&eacute; de l&rsquo;&eacute;diteur du
        site, ou font l&rsquo;objet d&rsquo;une autorisation d&rsquo;utilisation
        de la part de leurs propri&eacute;taires respectifs. Aucun droit ou
        licence sur l&rsquo;un de ces &eacute;l&eacute;ments ne sera
        conc&eacute;d&eacute; sans l&rsquo;autorisation &eacute;crite de
        l&rsquo;&eacute;diteur du site ou du tiers d&eacute;tenteur des droits.
      </p>
      <p>
        <strong>Droit applicable :</strong>
      </p>
      <p>
        Le pr&eacute;sent site Internet est r&eacute;gi par l&rsquo;application
        des lois fran&ccedil;aises. Il appartient aux utilisateurs
        acc&eacute;dant au site depuis un autre pays que la France de
        s&rsquo;assurer que les conditions d&rsquo;utilisation du pr&eacute;sent
        site Internet sont conformes aux lois localement applicables dans le
        pays depuis lequel le site est visit&eacute;.
      </p>
      <p>
        <strong>Mise &agrave; jour des mentions l&eacute;gales :</strong>
      </p>
      <p>
        Les pr&eacute;sentes mentions l&eacute;gales ont &eacute;t&eacute; mises
        &agrave; jour pour la derni&egrave;re fois le&nbsp;04/01/2023.
        <br />
        &nbsp;L&rsquo;&eacute;diteur du site se r&eacute;serve le droit de
        modifier les mentions l&eacute;gales &agrave; tout moment et sans
        pr&eacute;avis. Il appartient &agrave; l&rsquo;utilisateur de
        v&eacute;rifier qu&rsquo;il a pris connaissance de la derni&egrave;re
        version en date lors de ses visites sur le pr&eacute;sent site.
      </p>
      <p>
        <strong>3. Gestion des donn&eacute;es personnelles</strong>
      </p>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        Dans le cadre de la cr&eacute;ation du compte personnel du Client et de
        sa navigation sur le site, INQUEST, en sa qualit&eacute; de responsable
        de traitement, est amen&eacute; &agrave; collecter des donn&eacute;es
        &agrave; caract&egrave;re personnel.&nbsp;
      </p>
      <p>
        En tant que responsable du traitement des donn&eacute;es INQUEST
        s&rsquo;engage &agrave; respecter le cadre des dispositions
        l&eacute;gales en vigueur. INQUEST est susceptible de traiter tout ou
        partie des&nbsp;donn&eacute;es :
      </p>
      <ul className="MentionsLegalesUl">
        <li>
          <p>
            Pour pr&eacute;venir et lutter contre la fraude informatique
            (spamming, hacking&hellip;) : mat&eacute;riel informatique
            utilis&eacute; pour la navigation, l&rsquo;adresse IP, le mot de
            passe (hash&eacute;). Ces donn&eacute;es sont conserv&eacute;es
            pendant un maximum de 12 mois.
          </p>
        </li>
        <li>
          <p>
            Pour permettre et am&eacute;liorer la navigation sur le Site :
            donn&eacute;es de connexion et d&rsquo;utilisation. Ces
            donn&eacute;es sont conserv&eacute;es pendant un maximum de 12 mois.
          </p>
        </li>
        <li>
          <p>
            Pour la r&eacute;alisation des scans, fuite d&rsquo;emails, service
            disponible sur la plateforme. Ces donn&eacute;es ne sont pas
            conserv&eacute;es.
          </p>
        </li>
      </ul>
      <p>
        INQUEST ne commercialise pas les donn&eacute;es personnelles qui sont
        donc uniquement utilis&eacute;es pour les finalit&eacute;s
        pr&eacute;cit&eacute;es ou &agrave; des fins statistiques et
        d&rsquo;analyses.
      </p>
      <ul>
        <li>
          <p>
            <strong>
              Droit d&rsquo;acc&egrave;s, de rectification et d&rsquo;opposition
            </strong>
          </p>
        </li>
      </ul>
      <p>
        Conform&eacute;ment &agrave; la r&eacute;glementation europ&eacute;enne
        en vigueur, les Utilisateurs disposent des droits suivants :
      </p>
      <ul className="MentionsLegalesUl">
        <li>
          <p>
            Droit d&apos;acc&egrave;s (article 15 RGPD) et de rectification
            (article 16 RGPD), de mise &agrave; jour, de compl&eacute;tude des
            donn&eacute;es des Utilisateurs droit de verrouillage ou
            d&rsquo;effacement des donn&eacute;es des Utilisateurs &agrave;
            caract&egrave;re personnel (article 17 du RGPD), lorsqu&rsquo;elles
            sont inexactes, incompl&egrave;tes, &eacute;quivoques,
            p&eacute;rim&eacute;es, ou dont la collecte, l&apos;utilisation, la
            communication ou la conservation est interdite
          </p>
        </li>
        <li>
          <p>
            Droit de retirer &agrave; tout moment un consentement (article 13-2c
            RGPD)
          </p>
        </li>
        <li>
          <p>
            Droit &agrave; la limitation du traitement des donn&eacute;es des
            Utilisateurs (article 18 RGPD)
          </p>
        </li>
        <li>
          <p>
            Droit d&rsquo;opposition au traitement des donn&eacute;es des
            Utilisateurs (article 21 RGPD)
          </p>
        </li>
        <li>
          <p>
            Droit &agrave; la portabilit&eacute; des donn&eacute;es que les
            Utilisateurs auront fournies, lorsque ces donn&eacute;es font
            l&rsquo;objet de traitements automatis&eacute;s fond&eacute;s sur
            leur consentement ou sur un contrat (article 20 RGPD)
          </p>
        </li>
      </ul>
      <p>
        Vous pouvez exercer vos droits &agrave; l&rsquo;adresse suivante&nbsp;:
        contact@inquest-risk.com
      </p>
      <p>
        Votre demande doit &ecirc;tre accompagn&eacute;e d&rsquo;une copie de
        votre carte d&rsquo;identit&eacute; qui sera conserv&eacute;e le temps
        de la demande. A la r&eacute;ception de votre demande, nous disposons
        d&rsquo;un mois pour vous r&eacute;pondre.&nbsp;
      </p>
      <ul>
        <li>
          <p>
            <strong>Non-communication des donn&eacute;es personnelles</strong>
          </p>
        </li>
      </ul>
      <p>
        INQUEST s&rsquo;interdit de traiter, h&eacute;berger ou
        transf&eacute;rer les Informations collect&eacute;es sur ses Clients
        vers un pays situ&eacute; en dehors de l&rsquo;Union europ&eacute;enne
        ou reconnu comme &laquo; non ad&eacute;quat &raquo; par la Commission
        europ&eacute;enne sans en informer pr&eacute;alablement le client. Pour
        autant,&nbsp;INQUEST reste libre du choix de ses sous-traitants
        techniques et commerciaux &agrave; la condition qu&rsquo;ils
        pr&eacute;sentent les garanties suffisantes au regard des exigences du
        R&egrave;glement g&eacute;n&eacute;ral sur la Protection des
        Donn&eacute;es (RGPD : n&deg; 2016-679).
      </p>
      <p>
        INQUEST s&rsquo;engage &agrave; prendre toutes les pr&eacute;cautions
        n&eacute;cessaires afin de pr&eacute;server la s&eacute;curit&eacute;
        des Informations et notamment qu&rsquo;elles ne soient pas
        communiqu&eacute;es &agrave; des personnes non autoris&eacute;es.&nbsp;
      </p>
      <p>
        Les Donn&eacute;es personnelles de l&rsquo;Utilisateur peuvent
        &ecirc;tre trait&eacute;es par des sous-traitants (prestataires de
        services), exclusivement afin de r&eacute;aliser les finalit&eacute;s de
        la pr&eacute;sente politique.
      </p>
      <ul>
        <li>
          <p>
            <strong>Mesures de s&eacute;curit&eacute;</strong>
          </p>
        </li>
      </ul>
      <p>
        INQUEST s&rsquo;engage &agrave; assurer l&rsquo;int&eacute;grit&eacute;,
        la s&eacute;curit&eacute; et la confidentialit&eacute; des
        donn&eacute;es personnelles que vous nous confiez. Nous veillons
        &agrave; mettre en &oelig;uvre une protection maximale de toutes vos
        donn&eacute;es personnelles afin qu&rsquo;elles ne soient pas
        d&eacute;form&eacute;es, endommag&eacute;s ou bien que des tiers non
        autoris&eacute;s y aient acc&egrave;s. Nous utilisons des moyens de
        s&eacute;curisation physiques et logiques conformes aux r&egrave;gles de
        l&rsquo;art. Toutes vos donn&eacute;es sont s&eacute;curis&eacute;es de
        la m&ecirc;me mani&egrave;re.
      </p>
      <p>
        <strong>9.1. &laquo; COOKIES &raquo;</strong>
      </p>
      <p>
        Un &laquo; cookie &raquo; est un petit fichier d&rsquo;information
        envoy&eacute; sur le navigateur de l&rsquo;Utilisateur et
        enregistr&eacute; au sein du terminal de l&rsquo;Utilisateur (ex :
        ordinateur, smartphone), (ci-apr&egrave;s &laquo; Cookies &raquo;). Ce
        fichier comprend des informations telles que le nom de domaine de
        l&rsquo;Utilisateur, le fournisseur d&rsquo;acc&egrave;s Internet de
        l&rsquo;Utilisateur, le syst&egrave;me d&rsquo;exploitation de
        l&rsquo;Utilisateur, ainsi que la date et l&rsquo;heure
        d&rsquo;acc&egrave;s. Les Cookies ne risquent en aucun cas
        d&rsquo;endommager le terminal de l&rsquo;Utilisateur.
      </p>
      <p>
        INQUEST est susceptible de traiter les informations de
        l&rsquo;Utilisateur concernant sa visite du Site, telles que les pages
        consult&eacute;es, les recherches effectu&eacute;es. Ces informations
        permettent &agrave;&nbsp;d&rsquo;am&eacute;liorer le contenu du Site, de
        la navigation de l&rsquo;Utilisateur.
      </p>
      <p>
        Les Cookies facilitant la navigation et/ou la fourniture des services
        propos&eacute;s par le Site, l&rsquo;Utilisateur peut configurer son
        navigateur pour qu&rsquo;il lui permette de d&eacute;cider s&rsquo;il
        souhaite ou non les accepter de mani&egrave;re &agrave; ce que des
        Cookies soient enregistr&eacute;s dans le terminal ou, au contraire,
        qu&rsquo;ils soient rejet&eacute;s, soit syst&eacute;matiquement, soit
        selon leur &eacute;metteur. L&rsquo;Utilisateur peut &eacute;galement
        configurer son logiciel de navigation de mani&egrave;re &agrave; ce que
        l&rsquo;acceptation ou le refus des Cookies lui soient propos&eacute;s
        ponctuellement, avant qu&rsquo;un Cookie soit susceptible
        d&rsquo;&ecirc;tre enregistr&eacute; dans son terminal.&nbsp;
      </p>
      <p>
        Si l&rsquo;Utilisateur refuse l&rsquo;enregistrement de Cookies dans son
        terminal ou son navigateur, ou si l&rsquo;Utilisateur supprime ceux qui
        y sont enregistr&eacute;s, l&rsquo;Utilisateur est inform&eacute; que sa
        navigation et son exp&eacute;rience sur le Site peuvent &ecirc;tre
        limit&eacute;es. Cela pourrait &eacute;galement &ecirc;tre le cas
        lorsque,&nbsp;ou l&rsquo;un de ses prestataires ne peut pas
        reconna&icirc;tre, &agrave; des fins de compatibilit&eacute; technique,
        le type de navigateur utilis&eacute; par le terminal, les
        param&egrave;tres de langue et d&rsquo;affichage ou le pays depuis
        lequel le terminal semble connect&eacute; &agrave; Internet.
      </p>
      <p>
        Le cas &eacute;ch&eacute;ant,&nbsp;d&eacute;cline toute
        responsabilit&eacute; pour les cons&eacute;quences li&eacute;es au
        fonctionnement d&eacute;grad&eacute; du Site et des services
        &eacute;ventuellement propos&eacute;s, r&eacute;sultant (i) du refus de
        Cookies par l&rsquo;Utilisateur (ii) de l&rsquo;impossibilit&eacute;
        pour&nbsp;d&rsquo;enregistrer ou de consulter les Cookies
        n&eacute;cessaires &agrave; leur fonctionnement du fait du choix de
        l&rsquo;Utilisateur. Pour la gestion des Cookies et des choix de
        l&rsquo;Utilisateur, la configuration de chaque navigateur est
        diff&eacute;rente. Elle est d&eacute;crite dans le menu d&rsquo;aide du
        navigateur, qui permettra de savoir de quelle mani&egrave;re
        l&rsquo;Utilisateur peut modifier ses souhaits en mati&egrave;re de
        Cookies.
      </p>
      <p>
        &Agrave; tout moment, l&rsquo;Utilisateur peut faire le choix
        d&rsquo;exprimer et de modifier ses souhaits en mati&egrave;re de
        Cookies.&nbsp;
      </p>
      <p>
        <br />
        &nbsp;
      </p>
    </div>
  );
}
