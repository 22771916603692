import { useEffect, useState } from "react";
import { sendGetRequest } from "../auth";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Col, Row, Spin, Typography } from "antd";
import { Card } from "react-daisyui";
import StatsBar from "../components/StatsBar";
import { useTranslation } from "react-i18next";
import BackofficeCharts from "../components/BackofficeCharts";

// const colors = ["#f94144", "#f3722c", "#f8961e", "#f9844a", "#f9c74f", "#90be6d", "#43aa8b", "#4d908e", "#577590", "#277da1"];
// const colors = ['#fbf8cc', '#fde4cf', '#ffcfd2', '#f1c0e8', '#cfbaf0', '#a3c4f3', '#90dbf4', '#8eecf5', '#98f5e1', '#b9fbc0'];
// const colors = ['#305252', '#373e40', '#72a98f', '#8d80ad', '#f2a541'];
const colors = [
  "hsl(var(--p))",
  "hsl(var(--a))",
  "hsl(var(--in))",
  "hsl(var(--su))",
  "hsl(var(--wa))",
  "hsl(var(--er))",
];
// const colors = ['#1f2937', '#008eab', '#58bc82', '#e65f5c', '#9d69a3']

const getStringHash = function (str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getColorFromIndex = (index) => {
  return colors[Math.abs(index * 1337) % colors.length];
};

export const getNameFromIndex = (index) => {
  if (index === 0) {
    return "Quiz passés avec succès";
  }
  return "Quiz échoués";
};

export const getColorFromString = (str) => {
  const color = colors[Math.abs(getStringHash(str)) % colors.length];
  return color;
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        style={{ fontSize: 20 }}
        textAnchor="middle"
        fill={fill}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

// export function UsersChart(props) {
//     const [activeIndex, setActiveIndex] = useState(0);
//     let { t, i18n } = useTranslation();
//     let data = [];
//     let initialData = props.data;

//     if (initialData === undefined) {
//         return (
//             <Card title="Users">
//                 <Spin />
//             </Card>
//         );
//     }
//     Object.keys(initialData.role).forEach((key) => {
//         data.push({ 'name': key, 'value': initialData.role[key].count });
//     })

//     const onPieEnter = (_, index) => {
//         setActiveIndex(index);
//     };

//     return (
//         <Card title="Users" className="shadow">
//             <Card.Body>
//                 <Card.Title tag="h2">
//                     {t("User Roles")} ({initialData.count})
//                 </Card.Title>
//                 <ResponsiveContainer width={"100%"} height={300}>
//                     <PieChart>
//                         <Pie
//                             activeShape={renderActiveShape}
//                             activeIndex={activeIndex}
//                             onMouseEnter={onPieEnter}
//                             innerRadius={"50%"}
//                             outerRadius={"70%"}
//                             data={data} dataKey="value" nameKey="name" fill="#4b6bfb">
//                             {data.map((entry, index) =>
//                                 <Cell key={`cell-${index}`} fill={getColorFromIndex(index)} />
//                             )}
//                         </Pie>
//                     </PieChart>
//                 </ResponsiveContainer>
//             </Card.Body>
//         </Card >
//     );
// }

export function ScanStatus(props) {
  let { t } = useTranslation();
  let data = [];
  let initialData = props.data;

  if (initialData === undefined) {
    return (
      <Card title="Users">
        <Spin />
      </Card>
    );
  }

  data = initialData.history;

  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title tag="h2">
          {t("Breach History")} - {t("One Month")}
        </Card.Title>
        <ResponsiveContainer width={"100%"} height={200}>
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              stackId="a"
              dataKey={"breached"}
              stroke={"hsl(var(--er))"}
              fill={"hsl(var(--er))"}
            />
            {/* <Area
              type="monotone"
              stackId="a"
              dataKey={"no breach"}
              stroke={"hsl(var(--p))"}
              fill={"hsl(var(--p))"}
            /> */}
          </AreaChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
}

// export function PresentationsCard(props) {
//     const [activeIndex, setActiveIndex] = useState(0);
//     let { t } = useTranslation();
//     let data = [];
//     let initialData = props.data;

//     if (initialData === undefined) {
//         return (
//             <Card title={t("Users")}>
//                 <Spin />
//             </Card>
//         );
//     }

//     initialData.results.forEach((value, index) => {
//         data.push({ 'name': initialData.titles[index], 'value': value });
//     })

//     return (
//         <Card className="shadow">
//             <Card.Body>
//                 <Card.Title tag="h2">
//                     {t("Training Stats")}
//                 </Card.Title>
//                 <ResponsiveContainer width={"100%"} height={300}>
//                     <BarChart
//                         data={data}
//                     >
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" />
//                         <YAxis />
//                         <Tooltip />
//                         <Legend />
//                         <Bar dataKey='value' fill={getColorFromIndex(0)} />
//                     </BarChart>
//                 </ResponsiveContainer>
//             </Card.Body>
//         </Card >
//     );
// }

// export function InvitesChart(props) {
//     const [activeIndex, setActiveIndex] = useState(0);
//     let { t } = useTranslation();
//     let data = [];
//     let initialData = props.data;

//     if (initialData === undefined) {
//         return (
//             <Card title={t("Users")}>
//                 <Spin />
//             </Card>
//         );
//     }

//     Object.keys(initialData.status).forEach((key) => {
//         data.push({ 'name': key, 'value': initialData.status[key] });
//     })

//     const onPieEnter = (_, index) => {
//         setActiveIndex(index);
//     };

//     return (
//         <Card className="shadow">
//             <Card.Body>
//                 <Card.Title tag="h2">
//                     {t("Invites")}
//                 </Card.Title>
//                 <ResponsiveContainer width={"100%"} height={300}>
//                     <PieChart>
//                         <Pie
//                             cy={200}
//                             activeShape={renderActiveShape}
//                             activeIndex={activeIndex}
//                             onMouseEnter={onPieEnter}
//                             data={data}
//                             startAngle={180}
//                             endAngle={0}
//                             innerRadius={100}
//                             outerRadius={130}
//                             fill="#4b6bfb"
//                             dataKey="value">
//                             {data.map((entry, index) => (
//                                 <Cell key={`cell-${index}`} fill={getColorFromIndex(index)}></Cell>
//                             ))}
//                         </Pie>
//                     </PieChart>
//                 </ResponsiveContainer>
//             </Card.Body>
//         </Card >
//     );
// }

// export function QuizChart(props) {
//     const { t } = useTranslation();
//     let initialData = props.data?.summary;
//     let data = [];

//     if (initialData === undefined) {
//         return (
//             <Card title={t("Quiz Stats")}>
//                 <Spin />
//             </Card>
//         );
//     }

//     data = initialData;
//     let quizzes = { ...data[0] }
//     delete quizzes.name
//     quizzes = Object.keys(quizzes)

//     return (
//         <Card className="shadow">
//             <Card.Body>
//                 <Card.Title tag="h2">
//                     {t("Quiz Stats")}
//                 </Card.Title>
//                 <ResponsiveContainer width={"100%"} height={300}>
//                     <BarChart
//                         data={data}
//                     >
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" />
//                         <YAxis />
//                         <Tooltip />
//                         <Legend />
//                         {quizzes.map((element, index) => (
//                             <Bar dataKey={element} stackId='a' fill={getColorFromIndex(index)} />
//                         ))}
//                     </BarChart>
//                 </ResponsiveContainer>
//             </Card.Body>
//         </Card >
//     );
// }

export function QuizAnswerChart(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  let initialData = props.data;
  let data = [];

  if (initialData === undefined) {
    return (
      <Card title={t("BackOffice.stats.quizAnswerChartTitle")}>
        <Spin />
      </Card>
    );
  }

  const percent = Math.round((initialData.count_correct_qsts + Number.EPSILON) * 100) / 100;
  const restPercent = Math.round((100 - percent + Number.EPSILON) * 100) / 100;
  
  data = [
    { name: t("QuizAnswered"), value: percent},
    {
      name: t("QuizNotAnswered"),
      value: restPercent,
    },
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Card style={{ padding: 5 }}>
      <Card.Title className="text-center">
        <Col span={24} className="text-center">
          <Typography.Title level={3}>
            {t("BackOffice.stats.quizAnswerChartTitle")}
          </Typography.Title>
        </Col>
      </Card.Title>
      <ResponsiveContainer width={"100%"} height={300}>
        <PieChart>
          <Legend
            payload={data.map((item, index) => ({
              id: item.name,
              type: "square",
              value: getNameFromIndex(index),
              color: getColorFromIndex(index),
            }))}
          />
          <Pie
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            innerRadius={"50%"}
            outerRadius={"70%"}
            data={data}
            dataKey="value"
            nameKey="name"
            fill="#4b6bfb"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColorFromIndex(index)} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
}

// export function GameAnswerChart(props) {
//     const { t } = useTranslation();
//     let initialData = props.data?.summary;
//     let data = [];

//     if (initialData === undefined) {
//         return (
//             <Card title={t("Game Stats")}>
//                 <Spin />
//             </Card>
//         );
//     }

//     data = initialData;
//     let quizzes = { ...data[0] }
//     delete quizzes.name
//     quizzes = Object.keys(quizzes)

//     return (
//         <Card className="shadow">
//             <Card.Body>
//                 <Card.Title tag="h2">
//                     {t("Game Stats")}
//                 </Card.Title>
//                 <ResponsiveContainer width={"100%"} height={300}>
//                     <BarChart
//                         data={data}
//                     >
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" />
//                         <YAxis />
//                         <Tooltip />
//                         <Legend />
//                         {quizzes.map((element, index) => (
//                             <Bar dataKey={element} stackId='a' fill={getColorFromIndex(index)} />
//                         ))}
//                     </BarChart>
//                 </ResponsiveContainer>
//             </Card.Body>
//         </Card >
//     );
// }

export default function DashBoard() {
  const [data, setData] = useState();
  const [quizData, setQuizData] = useState();

  useEffect(() => {
    async function getOverview() {
      try {
          const endpoints = [
              "/dashboard/organizations",
              "/dashboard/invites",
              "/dashboard/assets",
              "/dashboard/tasks",
              "/dashboard/users",
              "/dashboard/quiz",
              "/dashboard/scores",
              "/dashboard/breaches",
              "/dashboard/game",
              "/dashboard/training"
          ];
  
          // Create an array of promises for each endpoint
          const requests = endpoints.map(endpoint => sendGetRequest(endpoint));
  
          // Execute all requests in parallel
          const responses = await Promise.all(requests);
  
          // Aggregate the results into a single object
          const aggregatedData = {
              organizations: responses[0].data.data,
              invites: responses[1].data.data,
              assets: responses[2].data.data,
              tasks: responses[3].data.data,
              users: responses[4].data.data,
              quiz: responses[5].data.data,
              scores: responses[6].data.data,
              breaches: responses[7].data.data,
              game: responses[8].data.data,
              training: responses[9].data.data
          };
  
          setData(aggregatedData);
      } catch (error) {
          console.error("Error fetching overview data:", error);
      }
  }
  

    async function getQuizData() {
      try {
        const responseQuiz = await sendGetRequest("/quiz/states");
        setQuizData(responseQuiz.data.data);
      } catch (error) {}
    }

    getOverview();
    getQuizData();
  }, []);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StatsBar data={data} />
      </Col>
      <Col span={24}>
        <ScanStatus data={data?.breaches} />
      </Col>
      <BackofficeCharts />
      <Col span={12}>
        <QuizAnswerChart data={quizData} />
      </Col>
    </Row>
  );
}
