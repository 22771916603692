import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Spin,
  Typography,
  Progress,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Countdown from "antd/lib/statistic/Countdown";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Avatar } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest } from "../auth";
import message from "../components/Alert";
import { API_URL } from "../conf";
import { getColorFromString } from "./DashBoard";

export function passwordComplexity(password) {
  let checks = [
    {
      name: "length",
      func: (pass) => {
        return pass.length > 8;
      },
    },
    {
      name: "uppercase",
      func: (pass) => {
        return /[A-Z]+/.test(pass);
      },
    },
    {
      name: "lowercase",
      func: (pass) => {
        return /[a-z]+/.test(pass);
      },
    },
    {
      name: "digits",
      func: (pass) => {
        return /[0-9]+/.test(pass);
      },
    },
    {
      name: "special",
      func: (pass) => {
        return /[^A-Za-z0-9]+/.test(pass);
      },
    },
  ];

  let missing = [];
  checks.forEach((check) => {
    if (check.func(password) !== true) {
      missing.push(check.name);
    }
  });
  return missing;
}

export function PassComplexityIndicator({ missingRules }) {
  const [score, setScore] = useState(0);
  let { t, i18n } = useTranslation();

  useEffect(() => {
    if (missingRules === undefined) setScore(0);
    else {
      setScore(5 - missingRules.length);
    }
  }, [missingRules]);

  function getScoreColor() {
    return ["#ddd", "#ef4836", "#f6b44d", "#2b90ef", "#25c281"][score - 1];
  }

  let rules = ["length", "uppercase", "lowercase", "digits", "special"];
  let rules_display = rules.map((rule) => (
    <Col key={`passPol-${rule}`} span={24}>
      <Typography.Text>
        {missingRules?.includes(rule) ? (
          <CloseCircleFilled style={{ color: "hsl(var(--er))" }} />
        ) : (
          <CheckCircleFilled style={{ color: "hsl(var(--su))" }} />
        )}{" "}
        {t(`passPol-${rule}`)}
      </Typography.Text>
    </Col>
  ));

  return (
    <div style={{ width: "100%" }}>
      <Progress
        percent={score * 20}
        showInfo={false}
        strokeColor={getScoreColor()}
      />
      <Row>
        <Col span={24}>Le mot de passe doit contenir :</Col>
        {rules_display}
      </Row>
    </div>
  );
}

function ResetPasswordForm({ resetId }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [passComplexity, setPassComplexity] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function submit(data) {
    setLoading(true);
    console.log(data);
    try {
      const response = await sendPostRequest(
        `auth/resetPassword/${resetId}`,
        data,
      );
      message.success(t("notif.ResetPasswordChanged"));
      navigate("/");
    } catch (error) {
      console.log(error.response.data);
      message.error(
        t(error?.response?.data?.error ?? "notif.ErrorCreatingAccount"),
      );
    }
    setLoading(false);
  }

  return (
    <Modal
      visible={true}
      title="Changer votre mot de passe"
      cancelText={"Annuler"}
      confirmLoading={loading}
      placement="bottom"
      onCancel={() => {
        navigate("/");
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onFinish={submit}
      >
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            { required: true, message: "Entrez votre mot de passe!" },
            {
              validator: (_, value) =>
                passwordComplexity(value).length == 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Veuillez entrer un mot de passe complex"),
                    ),
            },
          ]}
        >
          <Input
            placeholder="mot de passe"
            type="password"
            onChange={(e) => {
              let value = e.target.value;
              setPassComplexity(passwordComplexity(value));
            }}
          />
        </Form.Item>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <PassComplexityIndicator missingRules={passComplexity} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

function RequestPasswordReset({ resetId }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function submit(data) {
    setLoading(true);
    console.log(data);
    try {
      const response = await sendPostRequest(`auth/resetPassword`, data);
      message.success(t("notif.ResetPasswordSent"));
      navigate("/");
    } catch (error) {
      console.log(error.response.data);
      message.error(
        t(error?.response?.data?.error ?? "notif.ErrorCreatingAccount"),
      );
    }
    setLoading(false);
  }

  return (
    <Modal
      visible={true}
      title="Réinitialiser votre mot de passe"
      cancelText={"Annuler"}
      confirmLoading={loading}
      placement="bottom"
      onCancel={() => {
        navigate("/");
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onFinish={submit}
      >
        <Form.Item
          label="Adresse email"
          name="email"
          rules={[{ required: true, message: "Entrez votre adresse email!" }]}
        >
          <Input placeholder="Adresse email" type="email" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function ResetPasswordPage(props) {
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { resetId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    async function getInvite() {
      try {
        const result = await sendGetRequest(`/auth/resetPassword/${resetId}`);
        setData(result.data.data);
        console.log(result.data.data);
      } catch (error) {}
      setLoading(false);
    }
    getInvite();
  }, []);

  if (resetId === undefined) {
    return <RequestPasswordReset />;
  }

  if (loading) {
    return (
      <Row className="min-h-screen" justify="center" align="middle">
        <Col>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
        </Col>
      </Row>
    );
  } else if (data === true) {
    return <ResetPasswordForm resetId={resetId} />;
  } else {
    return (
      <Row className="min-h-screen" justify="center" align="middle">
        <Col>
          <Result
            status="404"
            title="Invalide"
            subTitle="Désolé, le lien de réinitialisation du mot de passe n'est pas valide"
            extra={
              <Button type="primary">
                <Link to="/">Go Home</Link>
              </Button>
            }
          />
        </Col>
      </Row>
    );
  }
}
