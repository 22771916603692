import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { getUserClaims, removeToken, sendGetRequest } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context";
import { API_URL } from "../conf";
import PageFooter from "./PageFooter";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function PageLayout(props) {
  const [location, setLocation] = useState(window.location.pathname);
  const { t } = useTranslation();
  const { userProfile } = useContext(AppContext);
  const navigationByRole = {
    staff: [
      { name: t("Dashboard"), href: "/", current: true },
      { name: t("BackOffice"), href: "/administration", current: true },
      { name: t("Organizations"), href: "/organizations", current: false },
      { name: t("Invites"), href: "/invites", current: false },
      // { name: t('Assets'), href: '/assets', current: false },
      // { name: t('Scans'), href: '/scans', current: false },
      { name: t("Awareness Training"), href: "/training", current: false },
      {
        name: t("Quiz"),
        href: "/quiz",
        current: false,
        title: "Quiz sécurité",
      },
      { name: t("PhishingGame"), href: "/phishing", current: false },
      {
        name: t("HIBP"),
        href: "/breaches",
        current: false,
        title: "Scan des fuites de mails",
      },
    ],
    master_client: [
      { name: t("Dashboard"), href: "/", current: true },
      { name: t("BackOffice"), href: "/administration", current: true },
      // { name: t('Organizations'), href: '/organizations', current: false },
      { name: t("Invites"), href: "/invites", current: false },
      // { name: t('Assets'), href: '/assets', current: false },
      // { name: t('Scans'), href: '/scans', current: false },
      { name: t("Awareness Training"), href: "/training", current: false },
      {
        name: t("Quiz"),
        href: "/quiz",
        current: false,
        title: "Quiz sécurité",
      },
      { name: t("PhishingGame"), href: "/phishing", current: false },
      {
        name: t("HIBP"),
        href: "/breaches",
        current: false,
        title: "Scan des fuites de mails",
      },
    ],
    client: [
      // { name: t('Dashboard'), href: '/', current: true },
      // { name: t('Organizations'), href: '/organizations', current: false },
      // { name: t('Invites'), href: '/invites', current: false },
      // { name: t('Assets'), href: '/assets', current: false },
      // { name: t('Scans'), href: '/scans', current: false },
      { name: t("Awareness Training"), href: "/training", current: false },
      {
        name: t("Quiz"),
        href: "/quiz",
        current: false,
        title: "Quiz sécurité",
      },
      { name: t("PhishingGame"), href: "/phishing", current: false },
      {
        name: t("HIBP"),
        href: "/breaches",
        current: false,
        title: "Scan des fuites de mails",
      },
    ],
  };

  const user = {
    imageUrl: "/images/avatar.jpg",
  };
  const navigation = chooseNavigation();
  const userNavigation = [
    // { name: 'Your Profile', href: '#' },
    // { name: 'Quiz', href: '/quiz' },
  ];

  function setCurrentFromURL(navigation) {
    const path = window.location.pathname;
    let match = null;
    let matchLength = 0;
    navigation.forEach((element) => {
      element.current = false;
      if (path.startsWith(element.href) && element.href.length > matchLength) {
        match = element;
        matchLength = element.href.length;
      }
    });
    if (match !== null) {
      match.current = true;
      return match.href;
    }
    return path;
  }

  function chooseNavigation() {
    const claims = getUserClaims();
    if (!claims) return [];
    const navigation = navigationByRole[claims.role];
    if (!navigation) return [];
    return navigation;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function getLogoUrl() {
    let url = "/images/logo-CGPA.png";

    if (userProfile?.role === "staff") return url;
    try {
      const iconId = userProfile.organizations[0].icon;
      if (iconId !== null && iconId !== undefined) {
        url = `${API_URL}/files/${iconId}`;
      }
    } catch (error) {}
    return url;
  }

  function getOrgName() {
    const orgName = userProfile?.organizations[0]?.name;
    if (userProfile.role === "staff") return "CGPA";
    return orgName;
  }

  function logout() {
    removeToken();
    props.setToken(undefined);
  }

  function getCurrentPage(nav) {
    let currentPage = null;
    nav.forEach((page) => {
      if (page.current === true) {
        currentPage = page;
      }
    });
    return currentPage;
  }

  function getPageTitle() {
    setCurrentFromURL(navigation);
    let page = getCurrentPage(navigation);
    if (page !== null) {
      return page.title ? page.title : page.name;
    }
    return null;
  }

  setCurrentFromURL(navigation);
  return (
    <>
      <div
        className="flex flex-col"
        style={{ minHeight: "calc(100vh - 88px)" }}
      >
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        style={{ maxWidth: 50, maxHeight: 50 }}
                        alt="CGPA"
                        src={getLogoUrl()}
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium",
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={() => {
                              setLocation(item.href);
                            }}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <LanguageSwitcher />
                      {/* <button
                                                type="button"
                                                className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button> */}
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    onClick={item.handler}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700",
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item key="logout">
                              <a
                                onClick={logout}
                                className={
                                  "block px-4 py-2 text-sm text-gray-700"
                                }
                              >
                                Logout
                              </a>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium",
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user.email}
                      </div>
                    </div>
                    <LanguageSwitcher />
                    {/* <button
                                            type="button"
                                            className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button> */}
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">
              {getPageTitle()}
              {getPageTitle() === "Quiz" && (
                <span
                  id="Secondary-Layout-Title"
                  className="float-right"
                ></span>
              )}
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            {props.children}
            {/* /End replace */}
          </div>
        </main>
      </div>
      <PageFooter orgIcon={getLogoUrl()} orgName={getOrgName()} />
    </>
  );
}
