import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Spin,
  Typography,
  Progress,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Countdown from "antd/lib/statistic/Countdown";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Avatar } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest } from "../auth";
import message from "../components/Alert";
import { API_URL } from "../conf";
import { getColorFromString } from "./DashBoard";

export function passwordComplexity(password) {
  let checks = [
    {
      name: "length",
      func: (pass) => {
        return pass.length > 8;
      },
    },
    {
      name: "uppercase",
      func: (pass) => {
        return /[A-Z]+/.test(pass);
      },
    },
    {
      name: "lowercase",
      func: (pass) => {
        return /[a-z]+/.test(pass);
      },
    },
    {
      name: "digits",
      func: (pass) => {
        return /[0-9]+/.test(pass);
      },
    },
    {
      name: "special",
      func: (pass) => {
        return /[^A-Za-z0-9]+/.test(pass);
      },
    },
  ];

  let missing = [];
  checks.forEach((check) => {
    if (check.func(password) !== true) {
      missing.push(check.name);
    }
  });
  return missing;
}

export function PassComplexityIndicator({ missingRules }) {
  const [score, setScore] = useState(0);
  let { t, i18n } = useTranslation();

  useEffect(() => {
    if (missingRules === undefined) setScore(0);
    else {
      setScore(5 - missingRules.length);
    }
  }, [missingRules]);

  function getScoreColor() {
    return ["#ddd", "#ef4836", "#f6b44d", "#2b90ef", "#25c281"][score - 1];
  }

  let rules = ["length", "uppercase", "lowercase", "digits", "special"];
  let rules_display = rules.map((rule) => (
    <Col key={`passPol-${rule}`} span={24}>
      <Typography.Text>
        {missingRules?.includes(rule) ? (
          <CloseCircleFilled style={{ color: "hsl(var(--er))" }} />
        ) : (
          <CheckCircleFilled style={{ color: "hsl(var(--su))" }} />
        )}{" "}
        {t(`passPol-${rule}`)}
      </Typography.Text>
    </Col>
  ));

  return (
    <div style={{ width: "100%" }}>
      <Progress
        percent={score * 20}
        showInfo={false}
        strokeColor={getScoreColor()}
      />
      <Row>
        <Col span={24}>Le mot de passe doit contenir :</Col>
        {rules_display}
      </Row>
    </div>
  );
}

function InviteSignin(props) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [passComplexity, setPassComplexity] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function submit(data) {
    setLoading(true);
    console.log(data);
    try {
      const response = await sendPostRequest(`/invite/view/${props.id}`, data);
      message.success(t("notif.SuccessCreatingAccount"));
      navigate("/");
    } catch (error) {
      console.log(error.response.data);
      message.error(
        t(error?.response?.data?.error ?? "notif.ErrorCreatingAccount"),
      );
    }
    setLoading(false);
  }

  function cancel() {
    // form.resetFields();
    props.close();
  }

  return (
    <Modal
      title="Creer un compte"
      cancelText={"Annuler"}
      confirmLoading={loading}
      placement="bottom"
      onCancel={cancel}
      onOk={() => {
        form.submit();
      }}
      visible={props.visible}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onFinish={submit}
      >
        {props.email ? (
          <Form.Item label="Email">
            <Input value={props.email} disabled />
          </Form.Item>
        ) : (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Entrez votre addresse email!" },
            ]}
          >
            <Input type="email" placeholder="addresse email" />
          </Form.Item>
        )}
        {/* {props.organization &&
                    <Form.Item label="Organisation" rules={[{ required: true }]}>
                        <Input value={props.organization} disabled />
                    </Form.Item>
                } */}
        <Form.Item
          label="Prenom"
          name="firstname"
          rules={[{ required: true, message: "Entrez votre prenom!" }]}
        >
          <Input placeholder="prenom" />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="lastname"
          rules={[{ required: true, message: "Entrez votre nom!" }]}
        >
          <Input placeholder="nom" />
        </Form.Item>
        <Form.Item
          label="Reference"
          name="company"
          rules={[
            { required: true, message: "Entrez la référence contrat CGPA!" },
          ]}
        >
          <Input placeholder="référence contrat CGPA" />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            { required: true, message: "Entrez votre mot de passe!" },
            {
              validator: (_, value) =>
                passwordComplexity(value).length == 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Veuillez entrer un mot de passe complex"),
                    ),
            },
          ]}
        >
          <Input
            placeholder="mot de passe"
            type="password"
            onChange={(e) => {
              let value = e.target.value;
              setPassComplexity(passwordComplexity(value));
            }}
          />
        </Form.Item>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <PassComplexityIndicator missingRules={passComplexity} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default function InviteSigninPage(props) {
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { inviteId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    async function getInvite() {
      try {
        const result = await sendGetRequest(`/invite/view/${inviteId}`);
        setData(result.data.data);
        console.log(result.data.data);
      } catch (error) {}
      setLoading(false);
    }
    getInvite();
  }, []);

  if (loading) {
    return (
      <Row className="min-h-screen" justify="center" align="middle">
        <Col>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
        </Col>
      </Row>
    );
  } else {
    if (data !== undefined) {
      let avatar = (
        <div className="flex">
          <img src="/images/cgpa.png" className="w-auto h-24 justify-center" />
        </div>
      );
      let title = [
        <Typography.Text>
          {t("Invitation.YouInvitedBy")}{" "}
          <Typography.Text strong>CGPA</Typography.Text>
        </Typography.Text>,
      ];
      if (data.organization !== undefined) {
        title.push(<br />);
        title.push(
          <Typography.Text>{t("Invitation.ToJoin")} </Typography.Text>,
        );
      }
      let expitation_date = new Date(Date.parse(data.expiration_date))
        .toLocaleString("fr-FR")
        .split(" ");
      return (
        <>
          <Row className="min-h-screen" justify="center" align="middle">
            <InviteSignin
              id={inviteId}
              visible={openCreate}
              close={() => {
                setOpenCreate(false);
              }}
              email={data.email}
              organization={data.organization}
            />
            <Col>
              <Result
                icon={avatar}
                title={title}
                subTitle={
                  <Typography.Text>
                    <br />
                    {t("Invitation.WillExpireAt")} : {expitation_date[0]}{" "}
                    {expitation_date[1] ? `à ${expitation_date[1]}` : ""}
                  </Typography.Text>
                }
                extra={[
                  <Button
                    onClick={() => {
                      setOpenCreate(true);
                    }}
                    type="primary"
                  >
                    {t("Invitation.CreateAccount")}
                  </Button>,
                ]}
              />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row className="min-h-screen" justify="center" align="middle">
          <Col>
            <Result
              status="404"
              title="Invitation not found"
              subTitle="Sorry, the invitation you are looking for does not exist."
              extra={
                <Button type="primary">
                  <Link to="/">Go Home</Link>
                </Button>
              }
            />
          </Col>
        </Row>
      );
    }
  }
  return <h1>Manage this case</h1>;
}
