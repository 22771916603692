import { Result } from "antd";
import { useParams } from "react-router-dom";
import GameCreator from "../components/GameCreator";

export default function GameCreationPage(props) {
  const { quizId } = useParams();
  if (quizId != undefined) {
    return <GameCreator id={quizId} />;
  }
  return <Result status="404" />;
}
