import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import BackOfficeUsers from "../components/backoffice/BackOfficeUsers";
import BackOfficeQuizMenu from "../components/backoffice/BackOfficeQuizMenu";
import BackOfficePhishingMenu from "../components/backoffice/BackOfficePhishingMenu";

export default function BackOffice({}) {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tabs.TabPane tab={t("BackOffice.tabs.users")} key="0">
        <BackOfficeUsers />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("BackOffice.tabs.quiz")} key="1">
        <BackOfficeQuizMenu />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("BackOffice.tabs.phishing")} key="2">
        <BackOfficePhishingMenu />
      </Tabs.TabPane>
    </Tabs>
  );
}
