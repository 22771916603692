import message from "../components/Alert";
import { Avatar, Button, Card, Divider, Stats } from "react-daisyui";
import { useEffect, useState } from "react";
import { sendGetRequest, sendPatchRequest, sendPostRequest } from "../auth";
import { PlusCircleIcon } from "@heroicons/react/solid";
import {
  MailOpenIcon,
  PencilAltIcon,
  ServerIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import {
  Modal,
  Typography,
  Form,
  Input,
  List,
  Statistic,
  Row,
  Col,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  CloudServerOutlined,
  DatabaseOutlined,
  DownOutlined,
  EditFilled,
  EditOutlined,
  LikeOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ImageUploader from "../components/ImageUpload";
import { API_URL } from "../conf";
import { getColorFromString } from "./DashBoard";
const { Stat } = Stats;

function OrganizationCreationModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function cancelCreate() {
    formRef.resetFields();
    props.setVisible(false);
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPostRequest("/organizations", data);
      message.success("Organization Created Successfully");
      formRef.resetFields();
      props.setVisible(false);
    } catch (error) {
      message.error("Error Creating Organization");
    }
    setLoading(false);
  }

  function submitButton() {
    formRef.submit();
  }

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={
        <Typography.Title level={4}>Create New Organization</Typography.Title>
      }
      visible={props.visible}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={formRef}
        name="CreateOrganization"
        onFinish={submitCreate}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input placeholder="eg: The Future Gadget Lab"></Input>
        </Form.Item>
        <Form.Item label="Icon" name="icon">
          <ImageUploader single count={1}>
            Choose Image
          </ImageUploader>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function OrganizationUpdateModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function cancelCreate() {
    formRef.resetFields();
    props.setVisible(false);
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPatchRequest(`/organizations/${props.data.id}`, data);
      message.success("Organization Updated Successfully");
      formRef.resetFields();
      props.setVisible(false);
    } catch (error) {
      message.error("Error Updating Organization");
    }
    setLoading(false);
  }

  function submitButton() {
    formRef.submit();
  }

  useEffect(() => {
    formRef.setFieldsValue(props.data);
  }, [props]);

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={
        <Typography.Title level={4}>Create New Organization</Typography.Title>
      }
      visible={props.visible}
    >
      <Form form={formRef} name="CreateOrganization" onFinish={submitCreate}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input placeholder="eg: The Future Gadget Lab"></Input>
        </Form.Item>
        <Form.Item label="Icon" name="icon">
          <ImageUploader single count={1}>
            Choose Image
          </ImageUploader>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function OrganizationsTable(props) {
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState();
  const [toEdit, setToEdit] = useState(undefined);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await sendGetRequest("/organizations");
        setStatus("success");
        setData(response.data.data);
      } catch (error) {
        setStatus("error");
        message.error("An error occured");
      }
    }
    loadData();
  }, [props.refresh]);

  // if (status === "loading" || status === "error") {
  //     return (<List grid={{
  //         xs: 1,
  //         sm: 2,
  //         md: 2,
  //         lg: 3,
  //         xl: 3,
  //         xxl: 3,
  //         gutter: [16, 16]
  //     }} dataSource={[0, 0, 0, 0, 0, 0]}
  //         renderItem={item => (<List.Item><Card loading /></List.Item>)} />);
  // }
  // else if (status === "success") {
  const statisticSpan = { span: 8 };
  return (
    <>
      <List
        grid={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
          gutter: [16, 16],
        }}
        dataSource={data}
        loading={status !== "success"}
        renderItem={(item) => {
          let avatar;
          if (item.icon) {
            avatar = (
              <Avatar
                className="mask mask-squircle"
                shape="square"
                size={50}
                src={`${API_URL}/files/${item.icon}`}
                style={{
                  backgroundColor: item.icon
                    ? "initial"
                    : getColorFromString(item.name),
                }}
              ></Avatar>
            );
          } else {
            avatar = (
              <Avatar
                className="mask mask-squircle"
                shape="square"
                size={50}
                style={{ backgroundColor: getColorFromString(item.name) }}
                letters={item.name.substr(0, 2)}
              />
            );
          }
          return (
            <List.Item>
              <Card
                className="shadow"
                onClick={() => {
                  setToEdit(item);
                }}
              >
                <Card.Body className="p-6">
                  <Card.Title className="flex items-center justify-between">
                    <div>
                      <span>{item.name}</span>
                    </div>
                    <div>{avatar}</div>
                  </Card.Title>
                  <Divider className="h-auto" />
                  <Row
                    justify="center"
                    align="middle"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <Stats style={{ width: "100%" }} className="font-sans">
                      <Stats.Stat className="p-0">
                        <Stat.Item variant="title">Users</Stat.Item>
                        <Stat.Item variant="value" className="text-slate-500">
                          {item.users}
                        </Stat.Item>
                      </Stats.Stat>
                      <Stats.Stat className="p-0">
                        <Stat.Item variant="title">Invites</Stat.Item>
                        <Stat.Item variant="value" className="text-slate-500">
                          {item.users}
                        </Stat.Item>
                      </Stats.Stat>
                      <Stats.Stat className="p-0">
                        <Stat.Item variant="title">Assets</Stat.Item>
                        <Stat.Item variant="value" className="text-slate-500">
                          {item.users}
                        </Stat.Item>
                      </Stats.Stat>
                    </Stats>
                  </Row>
                </Card.Body>
              </Card>
            </List.Item>
          );
        }}
      />
      {/* <OrganizationUpdateModal visible={toEdit !== undefined} data={toEdit} setVisible={() => { setToEdit(undefined); }} /> */}
    </>
  );
  // }
}

export default function OrganizationsPage(props) {
  const [createVisible, setCreateVisible] = useState(false);
  const [refreshTable, setRefresh] = useState(0);

  return (
    <div className="space-y-4">
      <div className="flex flex-row place-content-end">
        <Button
          animation={true}
          size="sm"
          color="primary"
          className="text-right"
          onClick={() => {
            setCreateVisible(true);
          }}
        >
          <PlusCircleIcon className="mr-2 h-5 w-5" />
          Create Organization
        </Button>
      </div>
      <OrganizationsTable refresh={refreshTable} />
      <OrganizationCreationModal
        visible={createVisible}
        setVisible={(visible) => {
          setCreateVisible(visible);
          if (visible === false) {
            setRefresh((old) => old + 1);
          }
        }}
      />
    </div>
  );
}
