import "./App.css";
import { Link, Route, Routes, useNavigate, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { getToken, setToken, removeToken, sendGetRequest } from "./auth";
import { useEffect, useState } from "react";
import DashBoard from "./pages/DashBoard";
import PageLayout from "./components/PageLayout";
import OrganizationsPage from "./pages/OrganizationsPage";
import AccountDisabledModal from "./components/AccountDisabledModal";
import InvitesPage from "./pages/InvitesPage";
import InviteSigninPage from "./pages/InviteSigninPage";
import AssetsPage from "./pages/AssetsPage";
import QuizPage, { QuizPlayPage } from "./pages/QuizPage";
import ScansPage from "./pages/ScansPage";
import ScanReportPage from "./pages/ScanReportPage";
import TrainingPage from "./pages/TrainingPage";
import GameCreationPage from "./pages/GameCreationPage";
import { AppContext } from "./context";
import { Button, Col, Result, Row, Spin } from "antd";
import QuizCreationPage from "./pages/QuizCreationPage";
import HIBPPage from "./pages/HIBPPage";
import GamePage, { GamePlayPage } from "./pages/GamePage";
import QuizGameScorePage from "./pages/QuizGameScorePage";
import PhishingGameScorePage from "./pages/PhishingGameScorePage";
import "moment/locale/fr";
import { SSOCallback } from "./components/SSOCallback";
import MentionsLegales from "./components/MentionsLegales";
import BackOffice from "./pages/BackOffice";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import WelcomePopup from './components/WelcomePopup';

function ProtectedRoute(props) {
  const requiredRoles = props.roles;
  const role = props.user?.role;
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (!(role && requiredRoles.includes(role))) {
        navigate("*");
      }
    } catch (error) {}
  }, [props]);
  try {
    if (role && requiredRoles.includes(role)) {
      return props.element;
    }
  } catch (error) {}
  return <></>;
}

function App(props) {
  const [token, setTokenState] = useState(getToken());
  const [userDisabled, setUserDisabled] = useState();
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    async function getUserProfile() {
      let responsed = false;
      try {
        const response = await sendGetRequest("/auth/profile");
        setUserProfile(response.data);
        setUserDisabled(false);
      } catch (error) {
        setUserProfile(error.response.data);
        setUserDisabled(true);
      }
    }
    if (token) {
      setUserDisabled(undefined);
      setUserProfile(undefined);
      getUserProfile();
    }
  }, [props, token]);

  if (token === undefined || token === null) {
    return (
      <div className="App">
        <Routes>
          <Route
            path="/sso/callback"
            element={<SSOCallback onToken={setTokenState} />}
          />
          <Route path="/invites/:inviteId" element={<InviteSigninPage />} />
          <Route path="/resetPassword/" element={<ResetPasswordPage />} />
          <Route
            path="/resetPassword/:resetId"
            element={<ResetPasswordPage />}
          />
          <Route path="/" element={<LoginPage setToken={setTokenState} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  } else if (userProfile && userDisabled === true) {
    if (userProfile.contact) {
      return (
        <AccountDisabledModal
          contact={userProfile.contact}
          setToken={setTokenState}
        />
      );
    } else {
      return (
        <Row className="min-h-screen" justify="center" align="middle">
          <Col className="text-center">
            <Result
              status="error"
              title="There's a problem with your account"
              subTitle="Your account was banned by a staff member"
            />
            <Button
              type="ghost"
              onClick={() => {
                setTokenState(null);
              }}
            >
              Log Out
            </Button>
          </Col>
        </Row>
      );
    }
  } else if (userProfile && userDisabled === false) {
    return (
      <div>
        <WelcomePopup />
        <AppContext.Provider value={{ userProfile }}>
          <PageLayout setToken={setTokenState} profile={userProfile}>
            <Routes>
              <Route path="/organizations" element={<OrganizationsPage />} />
              <Route path="/invites" element={<InvitesPage />} />
              <Route path="/assets" element={<AssetsPage />} />
              <Route path="/scans" element={<ScansPage />} />
              <Route path="/scans/:scanId" element={<ScanReportPage />} />
              <Route path="/quiz" element={<QuizPage />} />
              <Route path="/quiz/:quizId" element={<QuizPlayPage />} />
              <Route
                path="/quiz/result/:quizId"
                element={<QuizGameScorePage />}
              />
              <Route
                path="/quizCreate/:quizId"
                element={
                  <ProtectedRoute
                    user={userProfile}
                    roles={["staff"]}
                    element={<QuizCreationPage />}
                  />
                }
              />
              <Route path="/phishing" element={<GamePage />} />
              <Route path="/phishing/:quizId" element={<GamePlayPage />} />
              <Route
                path="/phishing/result/:quizId"
                element={<PhishingGameScorePage />}
              />
              <Route
                path="/phishingCreate/:quizId"
                element={
                  <ProtectedRoute
                    user={userProfile}
                    roles={["staff"]}
                    element={<GameCreationPage />}
                  />
                }
              />
              <Route path="/training" element={<TrainingPage />} />
              <Route path="/training/:slideId" element={<TrainingPage />} />
              <Route path="/breaches/:queryId" element={<HIBPPage />} />
              <Route path="/breaches" element={<HIBPPage />} />
              <Route path="/MentionsLegales" element={<MentionsLegales />} />
              <Route
                path="/administration"
                element={
                  <ProtectedRoute
                    user={userProfile}
                    roles={["staff", "master_client"]}
                    element={<BackOffice />}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute
                    user={userProfile}
                    roles={["staff", "master_client"]}
                    element={<DashBoard />}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/training" />} />
            </Routes>
          </PageLayout>
        </AppContext.Provider>
      </div>
    );
  } else if (userDisabled !== undefined) {
    return (
      <Row className="min-h-screen" justify="center" align="middle">
        <Col>
          <Result
            status="error"
            title="Can't contact the backend"
            subTitle="Make sure you are connected to internet, or try after some time"
          />
        </Col>
      </Row>
    );
  } else {
    <Row className="min-h-screen" justify="center" align="middle">
      <Col>
        <Spin />
      </Col>
    </Row>;
  }
}

export default App;
