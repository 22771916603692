import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Button as AntdButton,
  Avatar,
  Col,
  Form,
  Pagination,
  Row,
  Tooltip,
  Typography,
  Radio,
  Tag,
  Popconfirm,
} from "antd";
import Search from "antd/lib/transfer/search";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Modal,
  Table,
} from "react-daisyui";
import { useTranslation } from "react-i18next";
import { sendPostRequest } from "../auth";
import { getColorFromString } from "../pages/DashBoard";
import popMessage from "./Alert";

const filter_functions = {
  sso: (item) => item?.user?.sso,
  native: (item) => !item?.user?.sso,
};

function paginate(array, page_size, page_number) {
  // Ensure array is not null or undefined
  if (!Array.isArray(array)) {
    console.log("Paginate", []);
    return [];
  }

  const start = (page_number - 1) * page_size;
  const end = page_number * page_size;

  // Slice the array to get the items for the current page
  const result = array.slice(start, end);

  console.log("Paginate", result);
  return result;
}

function applyFilters(data, filters) {
  let filtered = data;

  filters.forEach((filter) => {
    filtered = filtered.filter(filter_functions[filter]);
  });
  return filtered;
}

function processData(data, search, page, filters) {
  // Replace 'n/a' with "Utilisateur SSO" in the company field
  if (data) {
    console.log("Data is available", data.length);
    const updatedData = data.map((item) => {
      if (item.user.company === "n/a") {
        item.user.company = "Utilisateur SSO";
      }
      return item;
    });

    return applyFilters(
      updatedData?.filter((element) => {
        if (search === undefined) return true;
        else
          return `${element.user.firstname} ${element.user.lastname} ${element.user.email} ${element.user.company}`
            .toLowerCase()
            .match(search.toLowerCase());
      }),
      filters
    );
  } else {
    console.log("No data", data);
  }
}

export default function BackOfficeUserStats({ data, refresh }) {
  const [form] = Form.useForm();
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();

  const [sortColumn, setSortColumn] = useState(null);
  const [sortCycle, setSortCycle] = useState(0); // 0 for default, 1 for ascending, 2 for descending

  function handleSort(column) {
    if (sortColumn === column) {
      setSortCycle((sortCycle + 1) % 3);
    } else {
      setSortColumn(column);
      setSortCycle(1); // default order is ascending when a new column is selected
    }
  }

  useEffect(() => {
    let processedData = processData(data, search, currentPage, filters);

    if (sortColumn) {
      processedData.sort((a, b) => {
        let aVal, bVal;
        switch (sortColumn) {
          case "username":
            aVal = (a.user.firstname + " " + a.user.lastname).toLowerCase();
            bVal = (b.user.firstname + " " + b.user.lastname).toLowerCase();
            break;
          case "company":
            aVal = a.user.company.toLowerCase();
            bVal = b.user.company.toLowerCase();
            break;
          case "last_login":
            aVal = new Date(a.last_login);
            bVal = new Date(b.last_login);
            break;
          case "created_at":
            aVal = new Date(a.user.created_at);
            bVal = new Date(b.user.created_at);
            break;
          case "visits":
            aVal = a.visits;
            bVal = b.visits;
            break;
          case "invitation_id":
            aVal = a.user.invitation_id;
            bVal = b.user.invitation_id;
            break;
          case "quiz":
            aVal = a.quiz;
            bVal = b.quiz;
            break;
          case "mail_scans":
            aVal = a.mail_scans;
            bVal = b.mail_scans;
            break;
          case "presentation":
            aVal = a.presentation;
            bVal = b.presentation;
            break;
          case "phishing":
            aVal = a.phishing;
            bVal = b.phishing;
            break;
          default:
            aVal = a;
            bVal = b;
        }

        if (sortCycle === 1) {
          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
        } else if (sortCycle === 2) {
          if (aVal < bVal) {
            return 1;
          }
          if (aVal > bVal) {
            return -1;
          }
        }
        return 0;
      });
    }

    if (processedData) {
      setFilteredData(
        processedData.map((element) => {
          return (
            <TableRow
              {...element}
              setSelected={setSelected}
              refresh={refresh}
            />
          );
        })
      );
    }
  }, [data, search, filters, sortColumn, sortCycle]);

  function searchSubmit() {
    const values = form.getFieldsValue();
    setSearch(values.username);
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    let headers = [
      "UTILISATEUR,EMAIL,SOCIÉTÉ,DERNIÈRE CNX,CRÉÉ,INVITATION,CONNEXIONS,QUIZ,SCANS EMAIL,FORMATIONS,SIMULATIONS",
    ];
    let usersCsv = [];
    console.log("DATA LENGTH", data.length);
    data.forEach((element) => {
      const {
        user,
        last_login,
        visits,
        quiz,
        mail_scans,
        presentation,
        phishing,
      } = element;
      usersCsv.push(
        [
          user.firstname + " " + user.lastname,
          user.email,
          user.company,
          last_login?.split(",")[1],
          user.created_at ? user.created_at.split(",")[1] : "-",
          user.invitation_id
            ? document.location.origin + "/invites?id=" + user.invitation_id
            : "-",
          visits,
          quiz,
          mail_scans,
          presentation,
          phishing,
        ].join(",")
      );
    });

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "users.csv",
      fileType: "text/csv",
    });
  };

  const onPaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Row className="flex justify-between">
        <Col>
          <Row>
            <Col>
              <Form
                onFieldsChange={searchSubmit}
                onFinish={searchSubmit}
                form={form}
              >
                <Form.Item className="w-full" name="username">
                  <Search
                    name="username"
                    placeholder={t("backoffice.table.SearchText")}
                    enterButton
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <TableFilter filters={filters} setFilters={setFilters} />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="right-0 flex justify-end">
            <Button key="export-csv" variant="outline" onClick={exportToCsv}>
              {t("Users.download.csv")}
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <div className="w-full">
        <Table className="w-full" zebra compact>
          <Table.Head>
            <span
              onClick={() => handleSort("username")}
              style={{
                fontWeight: sortColumn === "username" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.Username")}&nbsp;
              {sortColumn === "username" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("company")}
              style={{
                fontWeight: sortColumn === "company" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.Company")}&nbsp;
              {sortColumn === "company" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("last_login")}
              style={{
                fontWeight: sortColumn === "last_login" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.LastVisit")}&nbsp;
              {sortColumn === "last_login" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("created_at")}
              style={{
                fontWeight: sortColumn === "created_at" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.CreatedAt")}&nbsp;
              {sortColumn === "created_at" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("invitation_id")}
              style={{
                fontWeight: sortColumn === "invitation_id" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.InvitationId")}&nbsp;
              {sortColumn === "invitation_id" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("visits")}
              style={{
                fontWeight: sortColumn === "visits" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.LoginCount")}&nbsp;
              {sortColumn === "visits" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("quiz")}
              style={{
                fontWeight: sortColumn === "quiz" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.QuizCount")}&nbsp;
              {sortColumn === "quiz" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("mail_scans")}
              style={{
                fontWeight: sortColumn === "mail_scans" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.MailScans")}&nbsp;
              {sortColumn === "mail_scans" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("presentation")}
              style={{
                fontWeight: sortColumn === "presentation" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.TrainingCount")}&nbsp;
              {sortColumn === "presentation" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span
              onClick={() => handleSort("phishing")}
              style={{
                fontWeight: sortColumn === "phishing" ? "bold" : "normal",
                cursor: "pointer",
              }}
            >
              {t("backoffice.table.PhishingCount")}&nbsp;
              {sortColumn === "phishing" &&
                (sortCycle === 1 ? (
                  <UpOutlined />
                ) : (
                  sortCycle === 2 && <DownOutlined />
                ))}
            </span>
            <span>{t("backoffice.table.MoreDetails")}</span>
          </Table.Head>
          <Table.Body>
            {paginate(filteredData, pageSize, currentPage)}
          </Table.Body>
        </Table>
        <Col className="flex justify-center" style={{ marginTop: "1.5rem" }}>
          <div>
            <Pagination
              style={{ textAlign: "center", verticalAlign: "center" }}
              current={currentPage}
              pageSize={pageSize}
              onChange={onPaginationChange}
              total={filteredData?.length}
            />
          </div>
        </Col>
        <ExtendedStats data={selected} setSelected={setSelected} />
      </div>
    </>
  );
}

function TableFilter({ filters, setFilters }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const filter_options = [
    { value: "all", label: t("backoffice.filter.all") },
    { value: "sso", label: t("backoffice.filter.sso") },
    { value: "native", label: t("backoffice.filter.native") },
  ];

  function get_filter_name(value) {
    let entry = filter_options.find((entry) => entry.value == value);
    return entry.label;
  }

  function disable_filter(value) {
    form.setFieldValue("filter", "all");
    setFilters([]);
  }

  function get_filter_tags() {
    let result = filters.map((entry) => (
      <Tag
        key={entry}
        closable
        onClose={() => {
          disable_filter(entry);
        }}
      >
        {get_filter_name(entry)}
      </Tag>
    ));
    return result;
  }

  return (
    <div className="flex items-center mx-2 space-x-2">
      <Dropdown horizontal="right" className="bg-primary-content">
        <AntdButton shape="circle" icon={<FilterOutlined />} />
        <Dropdown.Menu className="w-64 p-2 m-1 border-2 border-solid shadow card card-compact">
          <Card.Body>
            <Card.Title>{t("backoffice.filter.filter")} :</Card.Title>
            <Form
              onValuesChange={() => {
                const selectedValue = form.getFieldValue("filter");
                if (selectedValue === "all") {
                  setFilters([]);
                } else {
                  setFilters([selectedValue]);
                }
              }}
              form={form}
            >
              <Form.Item name="filter">
                <Radio.Group options={filter_options} />
              </Form.Item>
            </Form>
          </Card.Body>
        </Dropdown.Menu>
      </Dropdown>
      <div>{get_filter_tags()}</div>
    </div>
  );
}

function format_date(datetime, t) {
  if (datetime === null) {
    return t("backoffice.table.NoDate");
  }
  return <Tooltip title={datetime}>{moment(datetime).fromNow()}</Tooltip>;
}

function format_number(value) {
  if (value === 0) {
    return <span className="text-base text-gray-500">{value}</span>;
  }
  return <span className="text-base font-bold">{value}</span>;
}

function TableRow({
  user,
  last_login,
  visits,
  quiz,
  mail_scans,
  presentation,
  phishing,
  details,
  setSelected,
  refresh,
}) {
  const { t } = useTranslation();

  async function disableUser(user) {
    try {
      const response = await sendPostRequest(`manageUser/${user?.id}/disable`);
      refresh();
      popMessage.success(t("BackOffice.table.alert.disabled"));
    } catch (error) { }
  }

  async function enableUser(user) {
    try {
      const response = await sendPostRequest(`manageUser/${user?.id}/enable`);
      refresh();
      popMessage.success(t("BackOffice.table.alert.enabled"));
    } catch (error) { }
  }

  async function deleteUser(user) {
    try {
      const response = await sendPostRequest(`manageUser/${user?.id}/delete`);
      refresh();
      popMessage.success(t("BackOffice.table.alert.deleted"));
    } catch (error) { }
  }

  return (
    <Table.Row>
      <div className="flex items-center space-x-2 truncate">
        <Avatar
          className="mask mask-squircle"
          shape="square"
          style={{
            backgroundColor: getColorFromString(
              `${user?.firstname}${user?.lastname}`
            ),
          }}
        >
          {`${user?.firstname[0]}${user?.lastname[0]}`}
        </Avatar>
        <div>
          <div className="font-bold">{`${user?.firstname} ${user?.lastname}`}</div>
          <div className="text-xs text-gray-500">{user?.email}</div>
        </div>
      </div>
      <div>{user?.company}</div>
      <div>{format_date(last_login, t)}</div>
      <div>{format_date(user?.created_at, t)}</div>
      <div>
        {user?.invitation_id ? (
          <a
            href={`/invites?id=${user?.invitation_id}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:underline"
          >
            {user?.invitation_id}
          </a>
        ) : (
          t("backoffice.table.NoInvitationId")
        )}
      </div>
      <div className="text-center">{format_number(visits)}</div>
      <div className="text-center">{format_number(quiz)}</div>
      <div className="text-center">{format_number(mail_scans)}</div>
      <div className="text-center">{format_number(presentation)}</div>
      <div className="text-center">{format_number(phishing)}</div>
      <div className="text-center">
        <ButtonGroup className="button-group">
          <Tooltip title={t("BackOffice.table.moreDetails")}>
            <Button
              animation={true}
              size="sm"
              color="primary"
              className="text-right"
              pill={false}
              onClick={() => {
                setSelected(details);
              }}
            >
              <PlusCircleOutlined />
            </Button>
          </Tooltip>
          {user?.enabled ? (
            <Tooltip title={t("BackOffice.table.disableUser")}>
              <Button
                key={`disable-${user?.id}`}
                animation={true}
                size="sm"
                color="warning"
                className="text-right"
                pill={false}
                onClick={() => {
                  disableUser(user);
                }}
              >
                <CloseCircleOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("BackOffice.table.enableUser")}>
              <Button
                key={`enable-${user?.id}`}
                animation={true}
                size="sm"
                color="accent"
                className="text-right"
                pill={false}
                onClick={() => {
                  enableUser(user);
                }}
              >
                <CheckCircleOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("BackOffice.table.deleteUser")}>
            <Popconfirm
              title={t("BackOffice.table.confirmDeleteUser")}
              cancelText={t("cancel")}
              okText={t("confirm")}
              onConfirm={() => {
                deleteUser(user);
              }}
            >
              <Button
                key={`delete-${user?.id}`}
                animation={true}
                size="sm"
                color="error"
                className="text-right"
                pill={false}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </ButtonGroup>
      </div>
    </Table.Row>
  );
}

function getScoreColor(score) {
  let scoreStyle = { color: "hsl(var(--su))" };
  if (score < 70) scoreStyle = { color: "#faad14" };
  if (score < 50) scoreStyle = { color: "hsl(var(--er))" };
  return scoreStyle;
}

function ExtendedStats({ data, setSelected }) {
  const { t } = useTranslation();

  let phishing = data?.phishing;
  let quiz = data?.quiz;
  let presentation = data?.presentation;
  let mail_scans = data?.mail_scans;

  return (
    <Modal
      open={data}
      onClickBackdrop={() => {
        setSelected(undefined);
      }}
    >
      <Typography.Title level={3}>
        {t("backoffice.modal.Presentations")}
      </Typography.Title>
      <Typography.Paragraph>
        <ul className="list-disc">
          {presentation?.map((item) => (
            <li>
              <Typography.Paragraph>
                {item.title} :{" "}
                <span
                  className="font-bold"
                  style={{
                    color:
                      item.date != null ? "hsl(var(--su))" : "hsl(var(--er))",
                  }}
                >
                  {item.date != null && t("backoffice.modal.Read")}{" "}
                  {format_date(item.date, t)}
                </span>
              </Typography.Paragraph>
            </li>
          ))}
        </ul>
      </Typography.Paragraph>
      <Typography.Title level={3}>
        {t("backoffice.modal.Quiz")}
      </Typography.Title>
      <Typography.Paragraph>
        <ul className="list-disc">
          {quiz?.map((item) => (
            <li>
              <Typography.Paragraph>
                {item.title} :{" "}
                <span className="font-bold" style={getScoreColor(item.score)}>
                  {t("backoffice.modal.QuizScore")} {item.score}%
                </span>
              </Typography.Paragraph>
            </li>
          ))}
        </ul>
      </Typography.Paragraph>
      <Typography.Title level={3}>
        {t("backoffice.modal.PhishingGames")}
      </Typography.Title>
      <Typography.Paragraph>
        <ul className="list-disc">
          {phishing?.map((item) => (
            <li>
              <Typography.Paragraph>
                {item.title} :{" "}
                <span className="font-bold" style={getScoreColor(item.score)}>
                  {t("backoffice.modal.QuizScore")} {item.score}%
                </span>
              </Typography.Paragraph>
            </li>
          ))}
        </ul>
      </Typography.Paragraph>
      <Typography.Title level={3}>
        {t("backoffice.modal.EmailScans")}
      </Typography.Title>
      <Typography.Paragraph>
        <ul className="list-dict">
          <li>
            <Typography.Paragraph>
              {t("backoffice.modal.EmailScanCount")} {mail_scans}
            </Typography.Paragraph>
          </li>
        </ul>
      </Typography.Paragraph>
    </Modal>
  );
}
