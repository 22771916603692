import { Button } from "antd";

export function SSOSignin() {
  return (
    <Button
      block
      type="primary"
      href="https://cgpa-cyber.fr/api/auth/sso/login"
    >
      S'identifier avec SSO
    </Button>
  );
}
