import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setToken } from "../auth";

export function SSOCallback({ onToken }) {
  const [params] = useSearchParams();

  const token = params.get("token");
  useEffect(() => {
    onToken(token);
  }, []);

  if (!token) {
    return (
      <div className="flex items-cetner justify-center">token not provided</div>
    );
  }

  setToken(token);
}
