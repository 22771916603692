import { LinkIcon, PlusCircleIcon } from "@heroicons/react/solid";
import {
  Avatar,
  Badge as AntdBadge,
  Row,
  Spin,
  Tooltip as AntdTooltip,
  Typography,
} from "antd";
import {
  Table,
  Tooltip,
  Badge,
  Checkbox,
  Button,
  ButtonGroup,
  Card,
  Stats,
} from "react-daisyui";
import Icon, {
  CheckCircleFilled,
  CopyFilled,
  DeleteFilled,
} from "@ant-design/icons";
import message from "../components/Alert";
import { getColorFromString } from "../pages/DashBoard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// return a promise
function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

function getBadgeStatus(status) {
  switch (status) {
    case "accepted":
      return "success";
    case "pending":
    case "viewed":
    case "sent":
      return "processing";
    case "denied":
      return "error";
    case "expired":
    default:
      return "default";
  }
}

function getRoleString(role) {
  switch (role) {
    case "staff":
      return "Staff";
    case "master_client":
      return "Master Client";
    case "client":
      return "Client";
    default:
      return "";
  }
}

export function InvitesTableRow(props) {
  const { t } = useTranslation();
  const id = props.id;
  const original_invitation_id = props.original_invitation_id
    ? props.original_invitation_id
    : "*";
  const usage_count = props.usage_count ? props.usage_count : 0;
  const title = props.email ? props.email : t("Static Invite");
  const subTitle =
    props.user?.firstname && props.user?.lastname
      ? `${props.user?.firstname} ${props.user?.lastname} (${props.user?.company})`
      : "";
  const reference = props.user?.company;
  const avatar = (
    <Avatar
      className="mask mask-squircle"
      shape="square"
      style={{ backgroundColor: getColorFromString(title) }}
    >
      {props.email ? title.substr(0, 2) : <Icon component={LinkIcon} />}
    </Avatar>
  );
  const organization = props.organization ? props.organization : "*";
  const role = getRoleString(props.role);

  let actions = [
    <Button
      color="accent"
      size="sm"
      onClick={() => {
        copyToClipboard(
          `${window.location.origin}/invites/${props.invite_code}`
        );
        message.info(t("notif.copiedInviteLink"));
      }}
    >
      <Tooltip message={t("invite_actions_copy")}>
        <CopyFilled key="Copy" />
      </Tooltip>
    </Button>,
    <Button
      color="error"
      size="sm"
      onClick={() => {
        props.delete(props.id);
      }}
    >
      <Tooltip message={t("invite_actions_delete")}>
        <DeleteFilled key="Delete" />
      </Tooltip>
    </Button>,
    <Button
      disabled={props.user?.enabled !== false}
      color="primary"
      size="sm"
      onClick={() => {
        props.enableUser(props.id);
      }}
    >
      <Tooltip message={t("invite_actions_accept")}>
        <CheckCircleFilled key="ellipsis" />
      </Tooltip>
    </Button>,
  ];
  let extra = [
    <AntdBadge status={getBadgeStatus(props.status)} text={t(props.status)} />,
    <br />,
  ];
  if (props.user !== undefined) {
    if (props.user.enabled === false)
      extra.push(<AntdBadge count={t("disabled")} />);
    else
      extra.push(
        <AntdBadge
          count={t("enabled")}
          style={{ backgroundColor: "#177ddc" }}
        />
      );
  }

  return (
    <Table.Row>
      <Checkbox
        size="sm"
        checked={props.selected}
        onChange={() => {
          props.toggleSelected(props.id);
        }}
      />
      <div className="flex items-center space-x-2">
        <div className="font-bold">{id}</div>
      </div>
      <div className="flex items-center space-x-2 truncate">
        {avatar}
        <div>
          <div className="font-bold truncate-text" style={{ "max-width": 200 }}>
            {title}
          </div>
          <div className="text-sm opacity-50 truncate-text" style={{ "max-width": 200 }}>{subTitle}</div>
        </div>
      </div>
      <div className="w-full bd-black">
        <div className="text-center">
          <span className="font-bold">{organization}</span>
          <br />
          <Badge color="ghost" size="sm">
            {role}
          </Badge>
        </div>
      </div>
      <div className="w-full bd-black">
        <div className="text-center">
          <span className="font-bold">{reference}</span>
        </div>
      </div>
      <div className="w-full bd-black">
        <div className="text-center">
          <span className="font-bold">
            {original_invitation_id == "*" ? (
              original_invitation_id
            ) : (
              <a
                href={`/invites?id=${props.original_invitation_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {original_invitation_id}
              </a>
            )}
          </span>
        </div>
      </div>
      <div className="w-full bd-black">
        <div className="text-center">
          <span className="font-bold">{usage_count}</span>
        </div>
      </div>
      <div className="text-center">{extra}</div>
      <div className="justify-self-end">
        <ButtonGroup>{actions}</ButtonGroup>
      </div>
    </Table.Row>
  );
}

export default function InvitesTable(props) {
  const data = props.data;
  const loaded = props.status === "success";
  const [selected, setSelected] = useState({});
  const [_refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  function refresh() {
    setRefresh((old) => old + 1);
  }

  function getSelect(id) {
    if (selected[id] == undefined) unsetSelect(id);
    return selected[id];
  }

  function setSelect(id) {
    selected[id] = true;
    setSelected(selected);
  }

  function unsetSelect(id) {
    selected[id] = false;
    setSelected(selected);
  }

  function toggleSelect(id) {
    refresh();
    selected[id] = !selected[id];
    setSelected(selected);
  }

  function getUuid(id) {
    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      if (data[i].id == id) {
        return data[i].invite_code;
      }
    }
    return 0;
  }

  function enableAll() {
    Object.keys(selected).forEach((item) => {
      props.enableUser(item);
    });
    setSelected({});
    refresh();
  }

  function toggleAll() {
    const status = allSelected();
    if (status.checked == true) setSelected({});
    else {
      data?.map((item) => {
        setSelect(item.id);
      });
    }
    refresh();
  }

  function allSelected() {
    let count = Object.values(selected).reduce(
      (prev, cur) => prev + (cur == true),
      0
    );
    if (count == 0) return { checked: false };
    else if (count == Object.values(selected).length) return { checked: true };
    return { checked: true, indeterminate: true };
  }

  const selectedStatus = allSelected();

  return (
    <Spin spinning={!loaded}>
      <Card className="rounded-lg">
        {loaded && (
          <>
            <Table compact className="min-w-full">
              <Table.Head>
                <Checkbox size="sm" {...selectedStatus} onClick={toggleAll} />
                <span>ID</span>
                <span>{t("User")}</span>
                <span>{t("Organization")}</span>
                <span>{t("Reference")}</span>
                <span>{t("OriginalInvitId")}</span>
                <span>{t("UsageCount")}</span>
                <span>{t("Status")}</span>
                <span>{t("Actions")}</span>
              </Table.Head>
              <Table.Body>
                {data.map((item) => {
                  return (
                    <InvitesTableRow
                      toggleSelected={toggleSelect}
                      selected={getSelect(item.id)}
                      {...item}
                      delete={props.deleteInvite}
                      enableUser={props.enableUser}
                    />
                  );
                })}
              </Table.Body>
            </Table>
            {selectedStatus.checked != false && (
              <div
                style={{
                  width: "100%",
                  height: "41px",
                  backgroundColor: "hsl(var(--b2))",
                }}
              >
                <Button
                  onMouseEnter={() => {}}
                  style={{ marginTop: "6px" }}
                  variant="link"
                  animation={true}
                  size="sm"
                  color="primary"
                  className="text-right"
                >
                  {t("Bulk actions")} :
                </Button>
                <Button
                  style={{ marginTop: "6px" }}
                  onClick={enableAll}
                  variant="link"
                  disabled={selectedStatus.checked == false}
                  animation={true}
                  size="sm"
                  color="primary"
                  className="text-right"
                >
                  <CheckCircleFilled className="mr-2" /> {t("Accept All")}
                </Button>
              </div>
            )}
          </>
        )}
      </Card>
    </Spin>
  );
}
