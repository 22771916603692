import message from "../components/Alert";
import { useEffect, useState } from "react";
import {
  getUserClaims,
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import { PlusCircleIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import { PencilAltIcon } from "@heroicons/react/outline";
import {
  Table,
  Button as AntdBtn,
  Skeleton,
  Modal,
  Typography,
  Form,
  Input,
  List,
  Card,
  Statistic,
  Row,
  Col,
  Descriptions,
  Badge,
  Tooltip,
  Tag,
  Select,
  DatePicker,
  Spin,
  Result,
  Collapse,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  CloudServerOutlined,
  CopyFilled,
  CopyOutlined,
  DatabaseOutlined,
  DeleteFilled,
  EditFilled,
  EditOutlined,
  FileTextFilled,
  LikeOutlined,
  LoadingOutlined,
  PlayCircleFilled,
  SecurityScanFilled,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { getColorFromString } from "./AssetsPage";
import { useNavigate, useParams } from "react-router-dom";
import Ribbon from "antd/lib/badge/Ribbon";
import { getBadgeStatus, scanStarted, scanStillRunning } from "./ScansPage";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function ReportDetails(props) {
  const results = props.data;
  return (
    <Collapse accordion>
      {Object.keys(results).map(function (key) {
        const item = results[key];
        return (
          <CollapsePanel
            header={
              <>
                <Typography.Text strong>{key}</Typography.Text>
                <Typography.Text
                  copyable={{
                    format: "text/plain",
                    text: item.raw_output,
                    icon: [
                      <CopyOutlined style={{ fontSize: 20 }} />,
                      <CopyFilled style={{ fontSize: 20 }} />,
                    ],
                  }}
                ></Typography.Text>
              </>
            }
            extra={
              <>
                <Typography.Text>
                  {" "}
                  status code :{" "}
                  <Typography.Text code>{item.status_code}</Typography.Text>
                </Typography.Text>
              </>
            }
          >
            <Typography.Paragraph>
              <pre>{item.raw_output}</pre>
            </Typography.Paragraph>
          </CollapsePanel>
        );
      })}
    </Collapse>
  );
}

export default function ScanReportPage(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [_refresh, _setRefresh] = useState(0);
  const { scanId } = useParams();

  function refresh() {
    _setRefresh((old) => old + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && scanStillRunning(data.status)) {
        refresh();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [data]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await sendGetRequest(`/scanning/tasks/${scanId}`);
        setData(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    loadData();
  }, [_refresh]);

  if (loading) {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
            spinning={loading}
          />
        </Col>
      </Row>
    );
  } else if (data !== undefined) {
    const extra = [
      <Tag color={getColorFromString(data.target_obj.type)}>
        {data.target_obj.type}
      </Tag>,
      <Badge status={getBadgeStatus(data.status)} text={data.status} />,
    ];
    return (
      <>
        <Card
          extra={<div style={{ textAlign: "center" }}>{extra}</div>}
          title={
            <Tooltip title={`ScanID: ${data.id}`}>
              <Typography.Text strong>{data.target_obj.name}</Typography.Text>
            </Tooltip>
          }
        >
          <Descriptions
            contentStyle={{ textAlign: "center" }}
            bordered
            layout="horizontal"
            column={1}
            size="small"
          >
            <Descriptions.Item label={<Typography.Text>URL</Typography.Text>}>
              <Typography.Text code>{data.target_obj.url}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Typography.Text>Start</Typography.Text>}>
              {data.start_time ? (
                <Typography.Text>
                  <Tooltip title={data.start_time}>
                    {moment(Date.parse(data.start_time)).calendar()}
                  </Tooltip>
                </Typography.Text>
              ) : (
                <Typography.Text type="secondary">N/A</Typography.Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={<Typography.Text>End</Typography.Text>}>
              {data.end_time ? (
                <Typography.Text>
                  <Tooltip title={data.end_time}>
                    {moment(Date.parse(data.end_time)).calendar()}
                  </Tooltip>
                </Typography.Text>
              ) : (
                <Typography.Text type="secondary">N/A</Typography.Text>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card title="Results">
          <ReportDetails data={data.results} />
        </Card>
      </>
    );
  } else {
    return (
      <>
        <Result
          status="404"
          title="Can't access scan report"
          subTitle="Sorry, the scan report you are requesting can'e be accessed"
        />
      </>
    );
  }
}
