import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  FrownOutlined,
  LoadingOutlined,
  MehOutlined,
  SmileOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { RefreshIcon } from "@heroicons/react/solid";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Image,
  List,
  notification,
  Radio,
  Result,
  Row,
  Spin,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sendGetRequest, sendPostRequest } from "../auth";
import message from "../components/Alert";
import { API_URL } from "../conf";

function QuizOption(props) {
  const [selected, setSelected] = useState(false);
  const [preview, setPreview] = useState(false);
  const data = props.data;

  function toggleSelect() {
    if (props.select(data.id, !props.data.selected)) {
      props.data.selected = !props.data.selected;
      setSelected((old) => !old);
    }
  }

  const selectedStyle = props.data.selected
    ? { boxShadow: "hsl(var(--p)) 0px 1px 4px, hsl(var(--p)) 0px 0px 0px 5px" }
    : {};

  if (data.type === "image") {
    return (
      // <Badge count={data.selected ? <CheckCircleFilled style={{ fontSize: 20, color: "#177ddc" }} /> : 0} color="blue">
      <Card
        key={data.id}
        bodyStyle={{ padding: 0 }}
        style={{ ...selectedStyle }}
        actions={[
          <ZoomInOutlined
            onClick={() => {
              setPreview((prev) => !prev);
            }}
          />,
        ]}
        hoverable
        className="text-center"
      >
        <div onClick={toggleSelect} style={{ padding: 5 }}>
          <Image preview={false} src={`${API_URL}/files/${data.content}`} />
          <Image
            preview={{
              visible: preview,
              src: `${API_URL}/files/${data.content}`,
              onVisibleChange: (value) => {
                setPreview(value);
              },
            }}
          />
        </div>
      </Card>
      // </Badge>
    );
  }
  return (
    <Card
      key={data.id}
      className="text-center"
      style={{ ...selectedStyle, height: "100%" }}
      onClick={toggleSelect}
      hoverable
      value={data.id}
    >
      {data.content}
    </Card>
  );
}

function QuizQuestion(props) {
  const [minGrid, setMinGrid] = useState(2);
  const [maxGrid, setMaxGrid] = useState(3);
  const question = props.data;
  const options = question.options;
  const { t } = useTranslation();

  function selectOption(id, state) {
    let numSelected = options.reduce(
      (total, current) => total + (current.selected === true),
      0,
    );
    if (state && numSelected >= question.select_count) return false;
    if (state) numSelected++;
    else numSelected--;
    props.refresh();
    question.num_selected = numSelected;
    return true;
  }

  useEffect(() => {
    setMinGrid(Math.min(options.length, 2));
    setMaxGrid(Math.min(options.length, 3));
    console.log("min max ", minGrid, maxGrid);
  }, [props]);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Typography.Title>{question.title}</Typography.Title>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Typography.Title strong level={5} type="secondary">
            {t("Quiz.SelectCount", { count: question.select_count })}
          </Typography.Title>
        </Col>
      </Row>
      <br />
      <Row justify="space-evenly" align="center" className="w-full">
        <List
          className="w-full"
          grid={{
            xs: 1,
            sm: minGrid,
            md: minGrid,
            lg: minGrid,
            xl: maxGrid,
            xxl: maxGrid,
            gutter: [16, 16],
          }}
          key={[minGrid, maxGrid]}
          dataSource={options}
          renderItem={(item) => (
            <List.Item>
              <QuizOption
                question={question}
                data={item}
                select={selectOption}
              />
            </List.Item>
          )}
        />
      </Row>
    </>
  );
}

export default function QuizComponent(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [valid, setValid] = useState(false);
  const [_refresh, _setRefresh] = useState(0);
  const [submitData, setSubmitData] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function submitQuiz() {
    setSubmitLoading(true);
    try {
      console.log(valid, submitData);
      const response = await sendPostRequest(
        `/quiz/submit/${props.id}`,
        submitData,
      );
      const score = response.data.data.score;
      let icon = <SmileOutlined style={{ color: "#52c41a" }} />;
      if (score < 70) icon = <MehOutlined style={{ color: "#faad14" }} />;
      else if (score < 50)
        icon = <FrownOutlined style={{ color: "#ff4d4f" }} />;
      notification.open({
        message: "Quiz Results",
        description: (
          <>
            <Typography.Text>{t("Quiz.YourScoreIs")}</Typography.Text>
            <Statistic value={Math.round(score)} suffix="/ 100" />
          </>
        ),
        icon: icon,
      });
      navigate(`/quiz/result/${props.id}`);
    } catch (error) {
      message.error(t("notif.QuizSubmitError"));
      // message.success("Thanks for submitting your answers !");
    }
    setSubmitLoading(false);
  }

  function exportResult() {
    let valid = true;
    let finalData = JSON.parse(JSON.stringify(data));
    delete finalData.name;
    finalData.questions = finalData.questions.map((item) => {
      const num_selected =
        item.num_selected === undefined ? 0 : item.num_selected;
      item.options = item.options.map((opt) => ({
        id: opt.id,
        selected: opt.selected === true,
      }));
      if (num_selected != item.select_count) valid = false;
      return { id: item.id, options: item.options, num_selected: num_selected };
    });
    return [valid, finalData];
  }

  function validateQuestion(question) {
    let valid = true;
    const required_selections = question?.select_count ?? 0;
    const num_selected = question?.options?.reduce(
      (acc, current) => acc + (current.selected === true),
      0,
    );
    console.log(
      "REQUIRED TO SELECT",
      required_selections,
      "SELECTED",
      num_selected,
    );
    return required_selections != num_selected;
  }

  function refresh() {
    _setRefresh((old) => old + 1);
  }

  useEffect(() => {
    if (data) {
      isResultValid();
    }
  }, [_refresh]);

  function isResultValid() {
    const [isValid, finalData] = exportResult();
    if (valid != isValid) {
      setValid(isValid);
      if (isValid) setSubmitData(finalData);
    }
    return isValid;
  }

  useEffect(() => {
    if (!props.id) return;
    async function getQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/quiz/quiz/${props.id}`);
        setData(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    getQuiz();
  }, [props.id]);

  if (loading) {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
            spinning={loading}
          />
        </Col>
      </Row>
    );
  } else if (data) {
    const questions = data.questions.map((item) => (
      <QuizQuestion refresh={refresh} data={item} />
    ));
    const selections_valid = validateQuestion(data?.questions[questionIndex]);
    let nextOrFinish = (
      <Col span={6}>
        <Tooltip title={!selections_valid ? "" : t("Quiz.SelectAllValid")}>
          <Button
            onClick={() => {
              setQuestionIndex((old) => old + 1);
            }}
            size="large"
            icon={<ArrowRightOutlined style={{ fontSize: 25 }} />}
            block
            disabled={selections_valid}
          ></Button>
        </Tooltip>
      </Col>
    );
    if (questionIndex >= questions.length - 1) {
      nextOrFinish = (
        <Col span={6}>
          <Tooltip title={valid ? "" : t("Quiz.SelectAllValid")}>
            <Button
              onClick={submitQuiz}
              size="large"
              icon={<CheckCircleFilled style={{ fontSize: 25 }} />}
              type="primary"
              block
              disabled={!valid}
            ></Button>
          </Tooltip>
        </Col>
      );
    }
    return (
      <Content>
        <Row gutter={[10, 10]}>
          <Col span={6}>
            <Button
              onClick={() => {
                setQuestionIndex((old) => old - 1);
              }}
              size="large"
              icon={<ArrowLeftOutlined style={{ fontSize: 25 }} />}
              block
              disabled={questionIndex <= 0}
            ></Button>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Statistic
              title="Question"
              value={questionIndex + 1}
              suffix={`/${questions.length}`}
            />
          </Col>
          {nextOrFinish}
        </Row>
        <br />
        <br />
        {questions[questionIndex]}
      </Content>
    );
  } else {
    return <Result status="info" title="No Quiz for now, check back later" />;
  }
}
