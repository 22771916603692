import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Button, Modal } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { removeToken } from "../auth";

const AccountDisabledModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal open={true} size="md">
      <Modal.Header
        onClick={() => {
          props.setToken(undefined);
          removeToken();
        }}
      />
      <Modal.Body>
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-gray-400" />
          <h3 className="mb-5 text-lg font-normal text-gray-500">
            {t("invitation.waitForEnable")}
          </h3>
          <div className="flex justify-center gap-">
            <Button
              variant="outline"
              onClick={() => {
                props.setToken(undefined);
                removeToken();
              }}
            >
              {t("logout")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccountDisabledModal;
