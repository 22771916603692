import {
  Button,
  Col,
  Empty,
  Form,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Spin,
  Steps,
  Typography,
} from "antd";
import { useEffect, useState, useTransition } from "react";
import { Card, Divider as DDivider } from "react-daisyui";
import {
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import ImageUploader from "./ImageUpload";
import message from "./Alert";
import { TrashIcon } from "@heroicons/react/solid";
import Icon from "@ant-design/icons";
import validator from "validator";
import { updateLocale } from "moment";
import { useTranslation } from "react-i18next";

export function QuizQuestion(props) {
  const [title, setTitle] = useState();
  const [loadCreate, setLoadCreate] = useState(false);
  const [formRef] = Form.useForm();
  const { t } = useTransition();

  function loadFields(data) {
    if (data !== undefined) {
      setTitle(data.title);
    }
  }

  function refresh(id) {
    if (props.refresh) props.refresh(id);
  }

  async function saveOption(data, correct) {
    console.log(
      "saving option",
      data.content,
      correct ? "correct" : "not correct",
    );
    let reqData = {
      ...data,
      correct: correct,
    };
    delete reqData.id;
    try {
      const response = await sendPatchRequest(
        `/quiz/option/${data.id}`,
        reqData,
      );
      return true;
    } catch (error) {
      message.error(t("notif.ErrorSavingChanges"));
    }
    return false;
  }

  async function updateOptions(option) {
    for (let i = 0; i < 2; i++) {
      const element = props.data?.options[i];
      saveOption(element, element.id == option);
    }
    refresh();
  }

  async function deleteQuestion() {
    const question = props.data;
    try {
      const response = await sendDeleteRequest(`/quiz/question/${question.id}`);
      refresh(0);
    } catch (error) {
      message.error(t("notif.ErrorDeletingQuestion"));
    }
  }

  async function saveChange(newData) {
    if (props.onChange) {
      props.onChange({
        ...props.data,
        title: title,
        options: undefined,
      });
    }
    const question = props.data;
    const data = { ...question, ...newData };
    delete data.id;
    delete data.options;
    try {
      const response = await sendPatchRequest(
        `/quiz/question/${question.id}`,
        data,
      );
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorSavingQuestion"));
    }
  }

  function getCorrectChoice() {
    const data = props.data;
    for (let i = 0; i < data.options.length; i++) {
      const option = data.options[i];
      if (option.correct) {
        return option.id;
      }
    }
  }

  useEffect(() => {
    console.log("props changed");
    loadFields(props.data);
    if (validator.isUUID(props.data?.title))
      formRef.setFieldValue("title", props.data?.title);
    // else
    //     formRef.resetFields();
  }, [props]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row className="w-full">
            <Col span={24}>
              <Form form={formRef} className="w-full" onFinish={saveChange}>
                <Row gutter={16} className="w-full">
                  <Col span={24} className="text-center">
                    {/* <Typography.Text editable={{ onChange: (value) => { setTitle(value); saveChange({ title: value }); } }}>{title}</Typography.Text> */}
                    <div className="flex justify-center w-full items-center">
                      {/* Qst Image */}
                      <div>
                        <span>Image</span>
                        <Form.Item
                          name="title"
                          rules={[
                            { required: true, message: "An image is required" },
                          ]}
                        >
                          <ImageUploader count={1} single>
                            Upload Image
                          </ImageUploader>
                        </Form.Item>
                      </div>
                      <div className="w-10"></div>
                      {/* Indicator Image */}
                      <div>
                        <span>Indicator</span>
                        <Form.Item name="indicator">
                          <ImageUploader count={1} single>
                            Upload Indice
                          </ImageUploader>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col span={24} className="text-center">
                    <Radio.Group
                      value={getCorrectChoice()}
                      className="w-full"
                      onChange={(event) => {
                        updateOptions(event.target.value);
                      }}
                    >
                      {props.data?.options?.map((item) => (
                        <Radio value={item.id} checked={item.correct}>
                          {item.content}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                  <Col span={24}>
                    <br />
                    <div className="flex space-x-8">
                      <Button type="primary" block htmlType="submit">
                        Save
                      </Button>
                      <Popconfirm
                        title="Delete this question ?"
                        onConfirm={() => {
                          deleteQuestion();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          danger
                          block
                          icon={<Icon component={TrashIcon} />}
                        ></Button>
                      </Popconfirm>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default function GameCreator(props) {
  const [quiz, setQuiz] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [_refresh, _setRefresh] = useState(0);
  const { t } = useTranslation();

  const [title, setTitle] = useState();

  function refresh(questionId) {
    _setRefresh((val) => val + 1);
    if (questionId !== undefined) setSelectedIndex(questionId);
  }

  function loadFields(data) {
    if (data !== undefined) {
      setTitle(data.name);
    }
  }

  async function saveChange(newData) {
    const data = { ...quiz, ...newData };
    delete data.id;
    delete data.questions;
    try {
      const response = await sendPatchRequest(`/quiz/quiz/${quiz.id}`, data);
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorSavingQuiz"));
    }
  }

  async function addOption(id, content, correct) {
    const newOption = {
      question: id,
      type: "text",
      content: content,
      correct: correct,
    };
    setLoading(true);
    try {
      const response = await sendPostRequest("/quiz/option", newOption);
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorCreatingOption"));
    }
    setLoading(false);
  }

  async function createQuestion() {
    const newQuestion = {
      title: "",
      select_count: 1,
      quiz: quiz.id,
      type: "single",
      index: quiz.questions.length,
    };
    setLoading(true);
    try {
      const response = await sendPostRequest("/quiz/question", newQuestion);
      message.success(t("notif.SuccessCreatingQuestion"));
      await addOption(response.data.data.id, "Phish", false);
      await addOption(response.data.data.id, "Genuine", true);
      refresh();
    } catch (error) {
      console.log("QUESTION CREATION ERROR :", error);
      message.error(t("notif.ErrorCreatingQuestion"));
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/quiz/editor/${props.id}`);
        setQuiz(response.data.data);
        setSelectedQuestion(response.data.data?.questions[selectedIndex]);
        loadFields(response.data.data);
      } catch (error) {}
      setLoading(false);
    }
    if (props.id !== undefined) loadQuiz();
  }, [props.id, _refresh]);

  return (
    <Spin spinning={loading}>
      {quiz !== undefined && (
        <Card>
          <Card.Body>
            <Card.Title>
              <Typography.Title
                className="text-center w-full"
                editable={{
                  onChange: (value) => {
                    setTitle(value);
                    saveChange({ name: value });
                  },
                }}
              >
                {title}
              </Typography.Title>
            </Card.Title>
            <Row gutter={16}>
              <Col span={24} className="text-center">
                <Typography.Title level={4}>Difficulty</Typography.Title>
                <Rate
                  value={quiz.difficulty}
                  onChange={(value) => {
                    saveChange({ difficulty: value });
                  }}
                  count={3}
                ></Rate>
              </Col>
              <Col>
                <br />
              </Col>
              <Col span={24}>
                <Steps
                  progressDot
                  current={selectedIndex}
                  onChange={(val) => {
                    console.log("PRESSING THE ADD NEW QUESTION :", val);
                    if (val >= quiz.questions.length) {
                      // createQuestion();
                    } else {
                      setSelectedIndex(val);
                      setSelectedQuestion(quiz.questions[val]);
                    }
                  }}
                >
                  {quiz.questions.map((question, index) => (
                    <Steps.Step
                      color={selectedQuestion == question ? "primary" : "ghost"}
                      title={index + 1}
                    />
                  ))}
                  <Steps.Step
                    onClick={(val) => {
                      createQuestion();
                    }}
                    title="Add Question"
                  ></Steps.Step>
                </Steps>
              </Col>
              <Col>
                <br />
              </Col>
              <Col span={24}>
                {selectedQuestion ? (
                  <QuizQuestion
                    refresh={refresh}
                    data={selectedQuestion}
                    onChange={(value) => {
                      console.log("EDITED QUESTION :", value);
                    }}
                  />
                ) : (
                  <Empty description="Start by adding a new question" />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Spin>
  );
}
