import {
  CheckOutlined,
  CloseOutlined,
  FrownOutlined,
  SmileOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Image,
  Result,
  Row,
  Spin,
  Statistic,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { sendGetRequest } from "../auth";
import message from "../components/Alert";
import { API_URL } from "../conf";

function ImageResult(props) {
  const { t } = useTranslation();
  const [preview, setPreview] = useState(false);

  return (
    <Col span={12}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 1000,
          backgroundColor:
            props.correctOption == "Genuine"
              ? "hsl(var(--su))"
              : "hsl(var(--er))",
          opacity: "90%",
        }}
      >
        <Row className="h-full w-full" align="middle">
          <Col span={24} className="text-center">
            <Typography.Title style={{ color: "white" }}>
              {t(props.correctOption)}
            </Typography.Title>
            <ZoomInOutlined
              style={{ fontSize: 50, color: "white" }}
              onClick={() => {
                setPreview((prev) => !prev);
              }}
            />
          </Col>
        </Row>
      </div>
      <Image
        style={{ border: "1px solid black" }}
        src={`${API_URL}/files/${props.item.indicator}`}
      />
      {/* ? props.item.indicator : props.item.title}`} />*/}
      <Image
        preview={{
          visible: preview,
          src: `${API_URL}/files/${props.item.indicator}`,
          onVisibleChange: (value) => {
            setPreview(value);
          },
        }}
      />
    </Col>
  );
}

export default function GameScorePage(props) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState();
  const [data, setData] = useState();
  const { quizId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await sendGetRequest(`/quiz/score/${quizId}`);
        setResults(response.data.data.score);
        setData(response.data.data.quiz);
      } catch (error) {
        message.error(t("notif.ErrorGettingQuizResults"));
      }
      setLoading(false);
    }
    getData();
  }, []);

  function getOptionContentBySelectId(options, select_id) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id == select_id) return options[i].content;
    }
  }

  function getCorrectOption(options) {
    const list_correct = new Array();
    for (let i = 0; i < options.length; i++) {
      if (options[i].correct) list_correct.push(options[i].content);
    }
    return list_correct;
  }

  function getSelectedOption(options, selected) {
    const list_select = new Array();
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].selected)
        list_select.push(getOptionContentBySelectId(options, selected[i].id));
    }
    return list_select;
  }

  function areListEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }
    return false;
  }

  if (!loading && (!data || !results)) {
    return (
      <Result status="404" title="Can't find results for this phishing game" />
    );
  }
  let scoreStyle = { color: "#52c41a" };
  if (results?.score < 70) scoreStyle = { color: "#faad14" };
  if (results?.score < 50) scoreStyle = { color: "#ff4d4f" };

  let score = 0;

  return (
    <Spin spinning={loading}>
      <Row>
        {data?.questions.map((item, index) => {
          const selectedOptions = getSelectedOption(
            item.options,
            results.selections.questions[index].options,
          );
          const correctOptions = getCorrectOption(item.options);
          if (areListEqual(selectedOptions, correctOptions)) {
            score++;
          }
          return (
            <>
              <Col span={14}>
                <span className="text-xl font-bold">{item.title}</span>
                <div className="h-5"></div>
                {item.options.map((option) => {
                  return (
                    <>
                      <div className="text-lg">
                        {option.correct ? (
                          <CheckOutlined style={{ color: "#008000" }} />
                        ) : (
                          <CloseOutlined style={{ color: "#FF0000" }} />
                        )}
                        <span style={{ padding: "1em" }}>{option.content}</span>
                      </div>
                      <div className="h-4"></div>
                    </>
                  );
                })}
              </Col>
              <div className="w-3"></div>
              <div className="flex">
                <Divider type="vertical" style={{ height: "100%" }} />
              </div>
              <div className="w-5"></div>
              <Col span={8}>
                <Row className="w-full h-full" justify="center" align="middle">
                  <Col span={24} className="text-center">
                    <Typography.Text className="text-lg text-center font-bold">
                      {t("Your Answered")} :
                    </Typography.Text>
                    <div className="h-5"></div>
                    {selectedOptions.map((selectedOption) => {
                      return (
                        <div className="text-left">
                          {correctOptions.includes(selectedOption) ? (
                            <CheckOutlined style={{ color: "#008000" }} />
                          ) : (
                            <CloseOutlined style={{ color: "#FF0000" }} />
                          )}
                          <span style={{ padding: "0.5em" }}>
                            {selectedOption}
                          </span>
                          <div className="h-4"></div>
                        </div>
                      );
                    })}
                    <div className="h-5"></div>
                    <Typography.Title>
                      {areListEqual(selectedOptions, correctOptions) ? (
                        <>
                          <span className="text-green-700">
                            {t("Correct")}
                            <br />
                            <SmileOutlined />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-red-700">
                            {t("Wrong")}
                            <br />
                            <FrownOutlined />
                          </span>
                        </>
                      )}
                    </Typography.Title>
                  </Col>
                </Row>
              </Col>
              <Divider />
            </>
          );
        })}
        <Col span={24} className="text-center">
          <Statistic
            title={t("Score")}
            valueStyle={{ fontSize: 50, ...scoreStyle }}
            value={results?.score}
            suffix="/ 100"
          />
        </Col>
      </Row>
    </Spin>
  );
}
