import message from "../components/Alert";
import { Button, ButtonGroup } from "react-daisyui";
import { useContext, useEffect, useState } from "react";
import {
  getUserClaims,
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../auth";
import { LinkIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { PencilAltIcon } from "@heroicons/react/outline";
import {
  Table,
  Button as AntdBtn,
  Skeleton,
  Modal,
  Typography,
  Form,
  Input,
  List,
  Card,
  Statistic,
  Row,
  Col,
  Avatar,
  Badge,
  Descriptions,
  Tooltip,
  Select,
  Switch,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  CaretDownFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  CloudServerOutlined,
  CopyFilled,
  CopyOutlined,
  DatabaseOutlined,
  DeleteFilled,
  EditFilled,
  EditOutlined,
  EllipsisOutlined,
  LikeOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { API_URL } from "../conf";
import { AppContext } from "../context";
import validator from "validator";
import { resolvePath } from "react-router-dom";
import InvitesTable from "../components/InvitesTable";
import { getColorFromString } from "./DashBoard";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

// return a promise
function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

function InviteCreationModal(props) {
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [isStatic, setStatic] = useState(false);
  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();

  function cancelCreate() {
    formRef.resetFields();
    props.setVisible(false);
  }

  async function submitCreate(data) {
    setLoading(true);
    try {
      await sendPostRequest("/bulkinvite/", data);
      message.success(t("notif.SuccessCreatingInvite"));
      formRef.resetFields();
      props.setVisible(false);
    } catch (error) {
      message.error(t("notif.ErrorCreatingInvite"));
    }
    setLoading(false);
  }

  useEffect(() => {
    async function getOrganizations() {
      setLoadingOrgs(true);
      try {
        const response = await sendGetRequest("/organizations/");
        setOrganizations(response.data.data);
        setLoadingOrgs(false);
      } catch (error) {
        message.error(t("notif.ErrorGettingOrganizations"));
      }
    }
    getOrganizations();
  }, []);

  function submitButton() {
    formRef.submit();
  }

  function emailMultipleSelectValidator(rule, value) {
    if (!rule.required) return Promise.resolve();
    for (let i = 0; i < value.length; i++) {
      if (!validator.isEmail(value[i])) {
        return Promise.reject(
          new Error(`'${value[i]}' is not a valid email !`),
        );
      }
    }
    return Promise.resolve();
  }

  return (
    <Modal
      onCancel={cancelCreate}
      confirmLoading={loading}
      onOk={submitButton}
      title={
        <Typography.Title level={4}>
          {isStatic ? t("Create Invitation") : t("Send Invitation")}
        </Typography.Title>
      }
      visible={props.visible}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={formRef}
        initialValues={{ role: "client" }}
        name="CreateOrganization"
        onFinish={submitCreate}
      >
        <Form.Item
          label="Static Invitation"
          hidden={userProfile.role !== "staff"}
        >
          <Switch
            onChange={() => {
              setStatic(!isStatic);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          hidden={isStatic}
          rules={[
            {
              required: !isStatic,
              type: "array",
              validator: emailMultipleSelectValidator,
            },
          ]}
        >
          <Select
            dropdownRender={() => <></>}
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={[",", " "]}
          />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          hidden={userProfile.role !== "staff"}
          rules={[{ required: true }]}
        >
          <Select>
            {userProfile.role === "staff" && (
              <Select.Option value="master_client">Master Client</Select.Option>
            )}
            <Select.Option value="client">Client</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organization"
          hidden={userProfile.role !== "staff"}
          initialValue={organizations.length > 0 ? organizations[0].id : null}
          rules={[{ required: true }]}
        >
          <Select
            loading={loadingOrgs}
            showSearch
            optionFilterProp="children"
            disabled={organizations.length == 1}
          >
            {organizations.map((element) => (
              <Select.Option key={element.id} value={element.id}>
                {element.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function InvitesList(props) {
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState();
  const [_refresh, _setRefresh] = useState(0);
  const [createVisible, setCreateVisible] = useState(false);
  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const filterId = queryParams.id;

  function refresh() {
    _setRefresh((old) => old + 1);
  }

  function getBadgeStatus(status) {
    switch (status) {
      case "accepted":
        return "success";
      case "pending":
      case "viewed":
      case "sent":
        return "processing";
      case "denied":
        return "error";
      case "expired":
      default:
        return "default";
    }
  }

  async function enableUser(inviteId) {
    try {
      await sendPostRequest(`/invite/${inviteId}/enable`);
      message.success(t("notif.EnabledUserAccount"));
      refresh();
    } catch (error) {
      // message.error("Error occured while enabling user account");
    }
  }

  async function deleteInvite(inviteId) {
    try {
      await sendDeleteRequest(`/invite/${inviteId}/`);
      message.success(t("notif.InviteDeleted"));
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorDeletingInvite"));
    }
  }

  async function masterClientStaticInvite() {
    if (!userProfile.organizations || userProfile.organizations.length == 0)
      return;
    const inviteData = {
      role: "client",
      organization: userProfile.organizations[0].id,
    };
    try {
      const response = await sendPostRequest(`/invite`, inviteData);
      message.info(t("notif.StaticInviteCreated"));
      refresh();
    } catch (error) {
      message.error(t("notif.ErrorCreatingInvite"));
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        const response = await sendGetRequest("/invite");
        setStatus("success");
        console.log("filterId", filterId);
        let filteredData = response.data.data;
        console.log("filteredData", filteredData);
        if (filterId) {
          filteredData = filteredData.filter((invite) => invite.id == filterId);
        }

        setData(filteredData);
      } catch (error) {
        setStatus("error");
        message.error(t("notif.AnErrorOccured"));
      }
    }
    loadData();
  }, [props.refresh, _refresh, filterId]);

  let actions = [];
  if (userProfile.role === "staff") {
    actions = [
      <Button
        animation={true}
        size="sm"
        color="primary"
        className="text-right"
        pill={false}
        onClick={() => {
          setCreateVisible(true);
        }}
      >
        <PlusCircleIcon className="mr-2 h-5 w-5" />
        {t("Invite Users")}
      </Button>,
    ];
  } else {
    actions = [
      <Button
        animation={true}
        size="sm"
        color="primary"
        className="text-right"
        pill={false}
        onClick={() => {
          setCreateVisible(true);
        }}
      >
        <PlusCircleIcon className="mr-2 h-5 w-5" />
        {t("Invite Users")}
      </Button>,
      <Button
        animation={true}
        size="sm"
        color="primary"
        variant="outline"
        className="text-right"
        pill={false}
        onClick={masterClientStaticInvite}
      >
        <LinkIcon className="mr-2 h-5 w-5" />
        {t("Create Static Invite")}
      </Button>,
    ];
  }
  return (
    <>
      <div className="flex flex-row place-content-end">
        <Row gutter={[16, 16]}>
          <ButtonGroup>{actions}</ButtonGroup>
        </Row>
      </div>
      <br />
      <InvitesTable
        status={status}
        data={data}
        deleteInvite={deleteInvite}
        enableUser={enableUser}
      />
      <InviteCreationModal
        visible={createVisible}
        setVisible={(val) => {
          setCreateVisible(val);
          refresh();
        }}
      />
    </>
  );
}

export default function InvitesPage(props) {
  return (
    <>
      <InvitesList />
    </>
  );
}
