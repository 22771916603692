import { message } from "antd";

class popAlert {
  pop(type, msg) {
    let content = <Alert type={type} message={msg} />;
    message.config({
      content: content,
      icon: <span />,
      top: 70,
      maxCount: 3,
      duration: 1,
    });
    message.info({ content: content, icon: <span /> });
  }
  warning(msg) {
    this.pop("warning", msg);
  }
  error(msg) {
    this.pop("error", msg);
  }
  info(msg) {
    this.pop("info", msg);
  }
  success(msg) {
    this.pop("success", msg);
  }
}

const popMessage = new popAlert();

export default popMessage;

export function Alert(props) {
  const content = (
    <>
      <span className="sr-only">{props.type}</span>
      <div>{props.message}</div>
    </>
  );
  let style = "";
  switch (props.type) {
    case "warning":
      style =
        "flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800";
      break;
    case "error":
      style =
        "flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800";
      break;
    case "info":
      style =
        "flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800";
      break;
    case "success":
      style =
        "flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800";
      break;
    default:
      style =
        "flex p-4 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300";
      break;
  }
  return (
    <div className={style} role="alert">
      <svg
        ariaHidden="true"
        className="flex-shrink-0 inline w-5 h-5 mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      {content}
    </div>
  );
}
