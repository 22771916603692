const FR_LANG = {
  salam: "test",
  Dashboard: "Tableau de Bord",
  BackOffice: "Administration",
  Organizations: "Organisation",
  Invites: "Invitations",
  Scans: "Scans",
  Assets: "Actifs",
  "Awareness Training": " Sensibilisation",
  Quiz: "Quiz",
  "User Roles": "Roles D'utilisateurs",
  "Scan History": "Historique des analyses",
  "Training Stats": "Présentations de sensibilisation",
  "One Month": "mois dernier",
  Users: "Utilisateurs",
  "Users.download.csv": "Export CSV",
  "HIBP Search": "Verifier les fuites",
  "HIBP Email": "Saisissez une adresse e-mail",
  "HIBP Title": "Vérifiez si votre email figure dans une fuite de données",
  "No Data": "Pas de données",
  "HIBP History": "Historique des recherches",
  "HIBP Submit Success": "Requette envoyee avec succes",
  "HIBP Ready": "Prêt",
  "HIBP Queued": "En cours de traitement",
  HIBP: "Scan Emails",
  "HIBP Loading Str 4": "Préparation des résultats",
  "HIBP Loading Str 1": "Recherche de bases de données divulguées",
  "HIBP Loading Str 3": "Analyse des listes divulgués",
  "HIBP Loading Str 2": "Recherche d'informations sensibles",
  "HIBP No Breaches":
    "Votre email ne figure en aucun cas sur notre base de données",
  "HIBP Breached n times":
    "Votre email figure dans {{count}} fuites de données",
  PhishingGame: "Phishing Game",
  finished: "achevé",
  failed: "raté",
  "Invite Users": "Inviter des utilisateurs",
  "Create Static Invite": "Creer une invitation statique",
  User: "Utilisateur",
  Status: "Status",
  Actions: "Actions",
  Organization: "Organisation",
  OriginalInvitId: "Invitation d'origine",
  UsageCount: "Nombre d'utilisations",
  "Static Invite": "Invitation Statique",
  "Send Invitation": "Envoyer des invitations",
  pending: "en attente",
  accepted: "accepté",
  disabled: "désactivé",
  "Add New Asset": "Ajouter un actif",
  "Start New Scan": "Lancer un nouveau scan",
  Phish: "Hameçonnage",
  Genuine: "Authentique",
  "Your Answered": "Votre réponse",
  Correct: "Correcte",
  Wrong: "Fausse",
  Score: "Score",
  "Accept All": "Accepter tout",
  "Bulk actions": "Actions en vrac",
  enabled: "activé",
  "Create Invitation": "Créer une invitation",
  expired: "expiré",
  "legal notice": "Mentions légales",
  "Pass the Quizzes": "Accéder aux quiz",
  "Test your knowledge ?": "Testez vos connaissances",
  QuizStart: "Commencer",
  Questions: "Questions",
  Difficulty: "Difficulté",
  "Quiz.Nottaken": "N/A",
  "Quiz Stats": "Scores des questionnaires",
  "Quiz.SelectCount": "Selectionnez {{count}} option(s)",
  "Quiz.YourScoreIs": "Votre score est",
  "Quiz.SelectAllValid": "Selectionnez toutes les options valides",
  "Invitation.WillExpireAt": "L'invitation expirera",
  "Invitation.YouInvitedBy": "Vous avez été invité par",
  "Invitation.ToJoin":
    "pour rejoindre la plateforme de sensibilisation aux risques numériques SERVICES CYBER",
  "Invitation.CreateAccount": "Créez votre compte",
  "HIBP Breached At Dates": "aux dates suivantes",
  "Quiz Answered": "Achèvement des questionnaires",
  QuizNotAnswered: "Echec",
  QuizAnswered: "Succès",
  "Breach History": "Historique des recherches de fuites emails",
  "Game Stats": "Scores des jeux de phishing",
  "passPol-length": "au moins 8 caractères.",
  "passPol-uppercase": "lettres majuscules.",
  "passPol-lowercase": "lettres minuscules.",
  "passPol-digits": "chiffres.",
  "passPol-special": "caractères spéciaux.",
  invite_actions_delete: "Supprimer l'invitation",
  invite_actions_accept: "Activer le compte",
  invite_actions_copy: "Copier le lien d'invitation",
  Reference: "Reference",
  "invitation.waitForEnable":
    "Votre compte n’est pas encore actif. Il le sera dès validation de votre accès par nos équipes, sous 2 jours ouvrés au plus tard.",
  logout: "Déconnexion",
  "quiz.YourResultIs": "Votre est :",
  "notif.copiedInviteLink": "Lien d'invitation copié !",
  "notif.QuizSubmitError": "Erreur lors de l'envoi du quiz !",
  "notif.QuizResults": "Résultats du quiz !",
  "notif.ErrorSavingChanges": "Erreur lors de la sauvegarde des modifications",
  "notif.ErrorDeletingQuestion":
    "Erreur lors de la suppression de la question !",
  "notif.ErrorSavingQuestion": "Erreur lors de la sauvegarde de la question !",
  "notif.ErrorSavingQuiz": "Erreur lors de la sauvegarde du quiz !",
  "notif.ErrorCreatingOption": "Impossible de créer une nouvelle option",
  "notif.ErrorCreatingQuestion":
    "Erreur lors de la création de la nouvelle question !",
  "notif.SuccessCreatingQuestion": "Nouvelle question créée !",
  "notif.DeletedOption": "Option supprimée !",
  "notif.ErrorDeletingOption": "Erreur lors de la suppression de l'option !",
  "notif.PhisingGameDeleted": "Jeu de phishing supprimé",
  "notif.ErrorDeletingPhisingGame":
    "Impossible de supprimer le jeu de phishing",
  "notif.ErrorCreatingPhisingGame":
    "Impossible de créer un nouveau jeu de phishing",
  "notif.ErrorGettingQuizResults":
    "Impossible de récupérer les résultats du quiz",
  "notif.SuccessCreatingAccount": "Votre compte a été créé !",
  "notif.ErrorCreatingAccount": "Une erreur s'est produite",
  "notif.SuccessCreatingInvite": "Invitation créée avec succès",
  "notif.ErrorCreatingInvite": "Erreur lors de la création de l'invitation",
  "notif.ErrorGettingOrganizations":
    "Erreur lors de la récupération des organisations",
  "notif.EnabledUserAccount": "Compte utilisateur activé !",
  "notif.InviteDeleted": "Invitation supprimée !",
  "notif.ErrorDeletingInvite": "Erreur lors de la suppression de l'invitation",
  "notif.StaticInviteCreated": "Invitation statique créée",
  "notif.AnErrorOccured": "Une erreur s'est produite",
  "notif.ResetPasswordSent":
    "Vous recevrez un e-mail pour réinitialiser votre mot de passe",
  "notif.ResetPasswordChanged": "Votre mot de passe a été changé avec succès",
  "error.UserEmailAlreadyExists": "Un utilisateur avec cet email existe déjà",
  "error.Unknown": "Une erreur est survenue",
  "error.InvalidInvitation": "Cette invitation n'est pas valide",
  "error.SlideNotFound": "Cette presentation n'existe pas",
  "error.ReturnToTraining": "Retourner à la page de Sensibilisation",
  "backoffice.table.Username": "Utilisateur",
  "backoffice.table.Company": "Société",
  "backoffice.table.LastVisit": "Dernière cnx",
  "backoffice.table.CreatedAt": "Créé",
  "backoffice.table.InvitationId": "Invitation",
  "backoffice.table.NoInvitationId": "Pas d'invitation",
  "backoffice.table.LoginCount": "connexions",
  "backoffice.table.QuizCount": "quiz",
  "backoffice.table.MailScans": "scans email",
  "backoffice.table.TrainingCount": "Formations",
  "backoffice.table.PhishingCount": "simulations",
  "backoffice.table.NoDate": "Jamais",
  "backoffice.modal.Read": "Lu",
  "backoffice.modal.Presentations": "Présentations :",
  "backoffice.modal.Quiz": "Quiz :",
  "backoffice.modal.PhishingGames": "Jeux de phishing :",
  "backoffice.modal.EmailScans": "Scans de courriel :",
  "backoffice.modal.EmailScanCount": "courriels scannés :",
  "backoffice.modal.QuizScore": "Score ",
  "backoffice.table.SearchText": "Rechercher...",
  "backoffice.table.SearchButton": "Rechercher",
  "backoffice.table.MoreDetails": "Plus de détails ",
  "backoffice.filter.filter": "filtre",
  "backoffice.filter.all": "Tous les utilisateurs",
  "backoffice.filter.sso": "Utilisateurs SSO",
  "backoffice.filter.native": "Utilisateurs Invités",
  "BackOffice.table.deleteUser": "Supprimer l'utilisateur",
  "BackOffice.table.enableUser": "Activer l'utilisateur",
  "BackOffice.table.disableUser": "Désactiver l'utilisateur",
  "BackOffice.table.moreDetails": "Plus de détails sur l'utilisation",
  "BackOffice.table.confirmDeleteUser":
    "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
  "BackOffice.table.alert.enabled": "Utilisateur activé",
  "BackOffice.table.alert.disabled": "Utilisateur désactivé",
  "BackOffice.table.alert.deleted": "Utilisateur supprimé",
  "BackOffice.table.quiz.Name": "Titre",
  "BackOffice.table.quiz.Score": "Score",
  "BackOffice.table.quiz.LastPass": "Dernier test",
  cancel: "Annuler",
  confirm: "Confirmer",
  "BackOffice.stats.scanCount": "Nombre de scans mails effectués",
  "BackOffice.stats.scanCountMonth":
    "Nombre de scans effectués les 30 derniers jours",
  "BackOffice.tabs.users": "Utilisateurs",
  "BackOffice.tabs.quiz": "Quiz",
  "BackOffice.tabs.phishing": "Jeux de phishing",
  "BackOffice.tabs.charts": "Usage",
  "BackOffice.stats.emailLeakPercent":
    "Taux des scans avec au moins une fuite trouvée",
  "BackOffice.stats.presentations": "Suivi de Sensibilisation",
  "BackOffice.stats.PresentationViews":
    "Utilisateur ayant suivi la sensibilisation",
  "BackOffice.stats.quiz": "Suivi de Quiz",
  "BackOffice.stats.quizViews": "Utilisateur ayant effectué le quiz",
  "BackOffice.stats.quizAverage": "Score moyen",
  "BackOffice.stats.phishing": "Suivi de Simulations phishing",
  "BackOffice.stats.phishingViews": "Utilisateur ayant effectué le quiz",
  "BackOffice.stats.phishingAverage": "Score moyen",
  "BackOffice.stats.emailScan": "Suivi des scans mails",
  "BackOffice.stats.scanMailCount": "Nombre total de scans mails effectués",
  "BackOffice.stats.quizAnswerChartTitle": "Etat de réussite des Quiz",
  UserSSO: "Utilisateur SSO",
  "training.guides": "Guides",
  "training.level1": "Pour démarrer",
  "training.level2": "Pour se perfectionner",
  "training.level3": "Pour aller plus loin",
};

export default FR_LANG;
